<template>
  <div>
    <v-card>
      <v-data-table :headers="headers" :items="documentKpis">
        <template v-slot:item.reviewID="{ item }">
          <span class="supervisor-review-table">{{ item["reviewID"] }}</span>
        </template>
        <template v-slot:item.stage="{ item }">
          <span class="supervisor-review-table">{{
            item["currentStage"]
          }}</span>
        </template>
        <template v-slot:item.selfRatingScore="{ item }">
          <span class="supervisor-review-table">{{ MyScore(item) }}%</span>
        </template>
        <template v-slot:item.reviewerRatingScore="{ item }">
          <span class="supervisor-review-table"
            >{{ SupervisorScore(item) }}%</span
          >
        </template>
        <template v-slot:item.overalScore="{ item }">
          <span class="supervisor-review-table">{{ OverallScore(item) }}%</span>
        </template>
        <template v-slot:item.documentStatus="{ item }">
          <v-chip
            v-show="item.documentStatus"
            small
            :color="getColor(item.documentStatus).color"
            dark
          >
            <span class="supervisor-review-table">{{
              item["documentStatus"]
            }}</span>
          </v-chip>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn-toggle>
            <!-- <v-btn
                         small
                         color="teal darken-1"
                         class="mx-2 white--text"
                         tile
                         link
                         v-if="item.documentStatus === 'Reviewed'"
                         :to="{ name: 'AppraisalReport', params: { code: item.code }}" >
                         report
                       </v-btn> -->
            <v-btn
              small
              color="primary"
              class="mx-2 white--text"
              tile
              link
              v-if="item.documentStatus === 'Reviewed'"
              @click="downloadEmployeeReport(item.code)"
            >
              <v-icon left color="white"> mdi-download </v-icon>
              report
            </v-btn>
            <v-btn
              small
              color="primary"
              class="white--text"
              tile
              @click="viewItem(item)"
            >
              Add Score
              <v-icon right color="white">mdi-arrow-right</v-icon>
            </v-btn>
          </v-btn-toggle>
        </template>
      </v-data-table>

      <v-card-actions>
        <v-btn
          small
          color="primary"
          class="white--text"
          tile
          @click="submitReport(item)"
        >
          Submit Report
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import AppraisalMixin from "@/packages/cassandra-appraisal/modules/appraisalv1/AppraisalMixin";
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
export default {
  name: "CommitteeDocumentReview",
  mixins: [AppraisalMixin],

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Result/getDocumentKpis", {
        reviewID: v.$route.params.code,
      });
    });
  },

  data() {
    return {
      headers: [
        { text: "Code", align: "", sortable: true, value: "reviewID" },
        {
          text: "Employee Number",
          align: "",
          sortable: true,
          value: "employeeNo",
        },
        { text: "Stage", align: "", sortable: true, value: "stage" },
        {
          text: "Employee Score",
          align: "center",
          sortable: true,
          value: "selfRatingScore",
        },
        {
          text: "Supervisor Score",
          align: "center",
          sortable: true,
          value: "reviewerRatingScore",
        },
        {
          text: "Actual Score",
          align: "center",
          sortable: true,
          value: "actualScore",
        },
        { text: "Weight", align: "", sortable: true, value: "weight" },
        { text: "Actions", value: "action" },
      ],
    };
  },

  computed: {
    documentKpis() {
      return this.$store.getters["Result/appraisalResultGetter"](
        "appraisalDocumentKpis"
      );
    },

    userData() {
      return AuthService.user;
    },
  },

  methods: {
    async downloadFile() {
      this.$store.dispatch("appraisal/getSupervisorReport", {
        reviewerID: this.userData.employee,
      });
    },
    async downloadEmployeeReport(item) {
      await this.$store.dispatch("appraisal/getAppraisalReport", {
        reviewNo: item,
      });
    },

    viewItem(val) {
      this.$router.push({
        name: "CommitteeAddScore",
        params: { code: val.reviewID, kpi: val.kpiID },
      });
    },

    submitReport() {
      const data = {
        employeeNo: this.userData?.profile?.number,
        reviewNo: this.$route?.params?.code,
        params: {
          provider: "GALAXY_CU",
          entity: "appraisalManagement_MarkAsCommitteeReviewed",
        },
      };
      this.$store.dispatch("Result/submitReport", data);
    },
  },
};
</script>

<style lang="scss" scoped></style>
