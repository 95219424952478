import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VDialog,{attrs:{"width":"400px","persistent":""},model:{value:(_vm.addCommitteeMemberDialog),callback:function ($$v) {_vm.addCommitteeMemberDialog=$$v},expression:"addCommitteeMemberDialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v("Add Committee Member")])]),_c(VCardText,[_c(VRow,[_c(VCol,[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VAutocomplete,{attrs:{"items":_vm.leaveEmployees,"item-value":"number","item-text":function (item) { return ((item.first_name) + " " + (item.last_name)); },"return-object":"","label":"Employee Number","required":""},model:{value:(_vm.FormData.employeeNo),callback:function ($$v) {_vm.$set(_vm.FormData, "employeeNo", $$v)},expression:"FormData.employeeNo"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.addCommitteeMemberDialog = false}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.addCommitteeMember}},[_vm._v("Add")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }