import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{staticClass:"py-2 px-2"},[_c(VToolbar,{staticClass:"mb-6",attrs:{"elevation":"1"}},[_c(VToolbarTitle,[_c(VRow,[_c(VCol,[_c('span',[_vm._v(" Committee: ")])]),(_vm.selectCommitteeActive)?_c(VCol,[_c(VSelect,{attrs:{"single-line":"","items":_vm.getCommitteeAssigned,"label":"Select a Committee"},on:{"change":_vm.setCommitteeSelected},model:{value:(_vm.selectedCommittee),callback:function ($$v) {_vm.selectedCommittee=$$v},expression:"selectedCommittee"}})],1):_c(VCol,[(_vm.appraisalCommitteDocuments)?_c('span',{staticClass:"font-weight-bold",attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.getCommitteeAssigned))]):_vm._e()]),_c(VCol,[_c(VBtn,{staticClass:"ml-3",attrs:{"icon":""},on:{"click":_vm.setSelectCommittee}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-filter-variant")])],1)],1)],1)],1),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"search","label":"Search by employee name, number or job title","single-line":"","hide-details":""},on:{"input":_vm.searchList},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c(VRow,{attrs:{"flex":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.documentListShowing},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.reviewStageStatus !== 'Open')?_c(VBtn,{staticClass:"mr-3 my-3",attrs:{"title":"View report for this appraisal document","icon":"","color":"info","fab":"","x-large":"","dark":""},on:{"click":function($event){return _vm.downloadEmployeeReport(item.code)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-poll")])],1):_vm._e(),(_vm.checkIfDocumentShouldBeReviewed(item))?_c(VBtn,{attrs:{"title":"Review appraisal document","icon":"","to":{
                name: 'Appraisal Card',
                params: { code: item.code },
              },"color":"primary","fab":"","x-large":"","dark":""}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-view-dashboard-outline")])],1):_vm._e(),(_vm.checkIfDocumentShouldBeRecommended(item))?_c(VBtn,{attrs:{"icon":"","title":"Recommend to CEO","to":{
                name: 'ResultCard',
                params: { code: item.code, period: item.period },
              },"color":"error","fab":"","x-large":"","dark":""}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-scale-unbalanced")])],1):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }