<template>
  <v-container>
    <v-card tile elevation="0">
      <v-card-title class="overline">
        <v-btn text @click="$router.back()">
          <v-icon left>mdi-arrow-left</v-icon>
          Back
        </v-btn>

        <v-spacer />

        <span> Imprest number: {{ imprestNo }}</span>

        <v-spacer />

        <v-btn
          @click="submitAccount"
          color="primary"
          class="mr-2 capitalize-text"
        >
          Submit
        </v-btn>
      </v-card-title>

      <v-divider class="mb-md-3" />

      <!--BEGIN table card--->
      <v-card-text>
        <v-data-table :headers="headers" :items="imprest">
          <!--eslint-disable-next-line -->
          <template v-slot:item.amountToPay="{ item }">
            <span>{{ item.amountToPay | currencyFormat }}</span>
          </template>
          <!--eslint-disable-next-line -->
          <template v-slot:item.balance="{ item }">
            <span>{{ item.balance | currencyFormat }}</span>
          </template>
          <!--eslint-disable-next-line -->
          <template v-slot:item.status="{ item }">
            <v-icon small left :color="statusColorCode(item)">
              mdi-radiobox-marked
            </v-icon>
            <template>
              {{
                item.detailedImprestLines.length > 0
                  ? "accounted"
                  : "not accounted"
              }}
            </template>
          </template>
          <!--END status slot-->
          <!--BEGIN action slot-->
          <!--eslint-disable-next-line -->
          <template v-slot:item.action="{ item }">
            <div class="">
              <!--BEGIN view button-->
              <v-btn
                color="success"
                small
                :disabled="item.detailedImprestLines.length === 0"
                :to="{
                  name: 'ReceiptAccounting',
                  params: { code: item.lineNo },
                  query: { document: item.documentNo },
                }"
                class="mx-1 white--text text-lowercase"
                elevation="0"
              >
                <v-icon small class="mr-2 white--text"> mdi-eye</v-icon>
                view
              </v-btn>
              <!--END view button-->

              <!--BEGIN account button-->
              <v-btn
                color="primary"
                small
                @click="attach(item.lineNo)"
                class="mx-1 white--text text-lowercase"
                elevation="0"
                :disabled="item.Purchase"
              >
                <v-icon small class="mr-2">mdi-cash</v-icon> account
              </v-btn>
              <!--END account button-->
            </div>
          </template>
          <!--END action slot-->
        </v-data-table>
      </v-card-text>
      <!--BEGIN calculate imprest-->
      <calc-imprest
        :imprestLine="imprestLine"
        :Currency="Currency"
        :dialog="dialog"
        v-on:close-dialog="closeDialog"
      />
      <!--END calculate imprest-->
      <!--BEGIN accounted list-->
      <accounted-list
        :imprestLines="accountedImprest"
        :dialog="accountsDialog"
        :imprestNo="imprestNo"
        :Currency="Currency"
        v-on:close-dialog="closeAccountedList"
      />
      <!--END accounted list-->
      <!--END table card--->
    </v-card>
  </v-container>
</template>

<script>
import CalcImprest from "./CalcImprest.vue";
import AccountedList from "./accountedList.vue";
import ImprestMixin from "../ImprestMixin.js";

export default {
  name: "ImprestAccounting",
  mixins: [ImprestMixin],
  components: {
    CalcImprest,
    AccountedList,
  },
  data: function () {
    return {
      path: this.$router.currentRoute.meta.breadcrumb,
      imprestLine: {},
      detailedImprestLines: [],
      dialog: false,
      accountsDialog: false,
      accountedImprest: [],
      seen: false,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("imprest/setBreadCrumbs", to);
      if (to.params.code) {
        v.$store.dispatch("imprest/getImprestsDetails", to.params.code);
      }
    });
  },

  watch: {
    $route(to, from) {
      this.path = to.meta.breadcrumb;
    },
  },

  computed: {
    headers() {
      return [
        { text: "#no", sortable: true, value: "expenseCode", align: "center" },
        {
          text: "Description",
          sortable: true,
          value: "description",
          align: "center",
        },
        {
          text: "Quantity",
          sortable: true,
          value: "Quantity",
          align: "center",
        },
        {
          text: `Original Amount ${this.Currency}`,
          sortable: true,
          value: "amountToPay",
          align: "center",
        },
        {
          text: `Balance ${this.Currency}`,
          sortable: true,
          value: "balance",
          align: "center",
        },
        { text: "Status", sortable: false, value: "status", align: "start" },
        { text: "Actions", sortable: false, value: "action", align: "start" },
      ];
    },

    imprest() {
      const imprests = this.$store.getters["imprest/imprestsDetails"];
      return imprests ? imprests.imprestLinesAPI : [];
    },
    imprestNo() {
      return this.$store.getters["imprest/imprestsDetails"].no;
    },
    Currency() {
      const data = this.$store.getters["imprest/imprestsDetails"];
      return data ? data.currencyCode : "KES";
    },
  },
  methods: {
    submitAccount() {
      this.$confirm.show({
        title: "Confirm Accounting",
        text: "Confirm Submitting Accounted imprest lines",
        onConfirm: () => {
          const data = {
            docNo: this.imprestNo,
          };
          // data.imprestStatus = 'Accounted'
          this.$store.dispatch("imprest/settleImprest", data);
        },
      });
    },
    saveAttachments() {
      this.accountedImprest = this.imprest;
      this.accountsDialog = true;
    },
    attach: function (code) {
      this.dialog = true;
      this.imprestLine = this.imprest.filter((i) => i.lineNo === code).shift();
    },
    closeDialog: function (val) {
      this.dialog = val;
    },
    closeAccountedList: function (val) {
      this.accountsDialog = val;
    },
    /**
     * @description get the imprest lines that are (not) accounted
     * @param item
     * @returns {string}
     */
    /**
     * Status color codes
     * @param item
     * @returns {string}
     */
    statusColorCode: (item) => {
      if (item.detailedImprestLines.length > 0) {
        if (item.balance === 0 || item.balance < 0) {
          return "success";
        } else if (item.balance !== 0 && item.balance < item.amountToPay) {
          return "warning";
        } else {
          return "error";
        }
      }
    },
    /**
     * hide button depending on status
     * @param item
     * @returns {boolean}
     */
    hideButton: (item) => {
      if (item.detailedImprestLines.length > 0) {
        if (item.balance === 0 || item.balance < 0) {
          return true;
        } else return !(item.balance !== 0 && item.balance < item.amountToPay);
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: Poppins, sans-serif !important;
}
</style>
