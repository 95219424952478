<template>
  <div>
    <div class="label mx-4">Select Leave Type</div>
    <v-autocomplete
      :items="leaveTypes"
      placeholder="Leave Type"
      clearable
      item-text="description"
      item-value="code"
      filled
      class="mt-1 mx-4 leaveInputs"
      v-model="leaveTypelocal"
      :rules="rules"
    >
      <template v-slot:prepend-inner>
        <v-icon color="primary"> toc </v-icon>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
export default {
  name: "LeaveType",
  props: {
    leaveTypes: Array,
    rules: Array,
    leave_type: String,
    displayLeaveType: String,
  },
  computed: {
    leaveTypelocal: {
      get() {
        return this.displayLeaveType;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
