<script>
import helpers from "@/packages/cassandra-base/utilities/helpers/helper.js";
import PageHeader from "../../components/planner/PageHeader.vue";
import ApprovalEntries from "../ApprovalEntries.vue";

export default {
  name: "ViewApplication",
  components: { ApprovalEntries, PageHeader },
  data() {
    return {
      rejectDialog: false,
      rejectReason: "",
    };
  },

  mounted() {
    if (this.$route.params.no) {
      this.$store.dispatch("leavePlanner/getPlanner", this.$route.params.no);
    }
  },

  computed: {
    planner: function () {
      return this.$store.getters["leavePlanner/leavePlannerGetter"]("planner");
    },

    approvalEntries: function () {
      return (
        this.planner?.approvalEntries?.map((entry) => {
          entry.approver = this.leaveEmployeeAll.filter(
            (approver) => approver.number === entry.approverID
          )[0];

          return entry;
        }) ?? []
      );
    },

    userData: function () {
      return this.$store.getters["auth/user"];
    },

    leaveEmployeeAll: function () {
      return this.$store.getters["Setup/setupGetter"]("leaveEmployees");
    },
  },

  methods: {
    goBack: function () {
      this.$router.back();
    },

    sendForApproval: function () {
      this.$confirm.show({
        title: "Send for approval",
        text: `Planner ${this.planner.no} will be sent for approval. Do you want to continue?`,
        onConfirm: () => {
          this.$store.dispatch("leavePlanner/sendForApproval", {
            no: this.planner.no,
          });
        },
      });
    },

    cancelApproval: function () {
      this.$confirm.show({
        title: "Cancel approval",
        text: `Planner ${this.planner.no}'s approval request will be cancelled. Do you want to continue?`,
        onConfirm: () => {
          this.$store.dispatch("leavePlanner/cancelApproval", {
            no: this.planner.no,
          });
        },
      });
    },

    editApplication: function () {
      this.$router.push({
        name: "MakeLeavePlannerApplication",
        query: {
          no: this.planner.no,
        },
      });
    },

    deleteApplication: function (item) {
      this.$confirm.show({
        title: "Delete application",
        text: `Planner ${this.planner.no} will be deleted. Do you want to continue?`,
        onConfirm: () => {
          this.$store.dispatch("leavePlanner/deletePlanner", {
            no: this.planner.no,
          });
        },
      });
    },
    getColor: function (status) {
      return helpers.getColor(status);
    },

    getTotalDuration: function (lines) {
      return lines.reduce((total, line) => {
        return total + line.duration;
      }, 0);
    },
  },
};
</script>

<template>
  <v-container>
    <page-header
      title="View Application"
      :path="$router.currentRoute.meta.breadcrumb"
    />

    <v-card elevation="0" v-if="planner">
      <v-card class="d-flex" flat>
        <v-card class="mr-auto my-2 mx-2" flat>
          <div class="d-flex justify-start">
            <span class="text-lg-h6">Application Code: </span>
            <div class="mx-2 pt-1">
              <v-chip color="primary" label>
                {{ planner.no }}
              </v-chip>
            </div>
            <div class="mx-2 pt-1">
              <v-chip label :color="getColor(planner.status).color" small>
                {{ planner.status }}
              </v-chip>
            </div>
          </div>
        </v-card>

        <v-card class="pa-2 d-flex justify-end" flat>
          <div class="mx-2">
            <v-btn class="btnActions" @click="goBack()">
              <v-icon class="mx-3">arrow_back</v-icon>
              go back
            </v-btn>
          </div>
          <template
            v-if="
              userData &&
              userData.employee === planner.employeeNo
            "
          >
            <template v-if="planner.status === 'Open'">
              <div class="mx-2">
                <v-btn
                  color="primary"
                  class="btnActions"
                  @click.prevent="sendForApproval"
                >
                  <v-icon class="mx-3">cloud_upload</v-icon>
                  Send For Approval
                </v-btn>
              </div>
              <div class="mx-2">
                <v-btn
                  color="primary"
                  class="btnActions"
                  @click.prevent="editApplication"
                >
                  <v-icon class="mx-3">mode_edit</v-icon>
                  Edit Application
                </v-btn>
              </div>
            </template>
            <template v-else-if="planner.status === 'Pending'">
              <div class="mx-2">
                <v-btn
                  color="primary"
                  class="btnActions"
                  @click.prevent="cancelApproval"
                >
                  <v-icon class="mx-3">cancel_schedule_send</v-icon>
                  Cancel Approval
                </v-btn>
              </div>
            </template>
          </template>
        </v-card>
      </v-card>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-card flat>
              <v-card-title class=""> Employee </v-card-title>
              <v-card-text>
                <div class="d-flex justify-start">
                  <div>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title> Employee </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ planner.employeeName }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                  <div class="mx-3">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title> Employee Number </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ planner.employeeNo }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card flat>
              <v-card-title>
                Leave Period
                <v-chip color="primary" light small class="ml-2">
                  {{
                    getTotalDuration(planner.leavePlannerLines)
                      | pluralize("day", "days")
                  }}
                </v-chip>
              </v-card-title>
              <v-card-text>
                <div class="d-flex justify-start">
                  <div>
                    <v-list-item>
                      <v-list-item-avatar
                        color="light-blue lighten-4"
                        size="50"
                      >
                        <v-icon color="light-blue darken-4">date_range</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title> Start Date </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ planner.fromDate | formatDate }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                  <div class="mx-3">
                    <v-list-item>
                      <v-list-item-avatar color="cyan lighten-4" size="50">
                        <v-icon color="cyan darken-4">event_busy</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title> End Date </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ planner.toDate | formatDate }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-card flat>
              <v-card-title class=""> Leave Entries </v-card-title>
              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Leave Type</th>
                        <th class="text-left">Start Date</th>
                        <th class="text-left">End Date</th>
                        <th class="text-center">Duration</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in planner.leavePlannerLines"
                        :key="item.lineNo"
                      >
                        <td>{{ item.leaveType }}</td>
                        <td>{{ item.fromDate | formatDate }}</td>
                        <td>{{ item.toDate | formatDate }}</td>
                        <td class="text-center">
                          {{ item.duration | pluralize("day", "days") }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <template v-if="approvalEntries.length">
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <ApprovalEntries :approvalEntries="approvalEntries" class="mt-3" />
          </v-row>
        </v-card-text>
      </template>
    </v-card>
  </v-container>
</template>

<style scoped></style>
