import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-lg-h5 mb-2 font-weight-medium"},[_vm._v("Days Left")]),_c('StatsView',{attrs:{"data":_vm.balances},scopedSlots:_vm._u([{key:"stats",fn:function(){return [_c(VLayout,{attrs:{"row":"","wrap":""}},_vm._l((_vm.balances),function(item,i){return _c(VFlex,{key:i,attrs:{"xs6":"","sm4":"","xl2":""}},[_c(VHover,{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{class:{ 'on-hover': hover },staticStyle:{"background-color":"#ffffff"},attrs:{"elevation":hover ? 16 : 0,"flat":""}},[_c(VList,[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-lg-h3"},[_vm._v(_vm._s(item.balance))]),_c(VListItemSubtitle,[_vm._v(_vm._s(item.leaveType))])],1),_c(VListItemAction,[_c(VAvatar,{attrs:{"color":"light-blue lighten-4","size":"70"}},[_c(VIcon,{attrs:{"large":"","color":"light-blue darken-4"}},[_vm._v("ti-calendar")])],1)],1)],1)],1)],1)]}}],null,true)})],1)}),1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }