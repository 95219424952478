<template>
  <v-container>
    <v-card class="my-5 elevation-0" height="100%">
      <v-card-title>
        <v-btn text small @click="$router.back()">
          <v-icon left> mdi-arrow-left </v-icon>
          Back
        </v-btn>
        <v-spacer />
        <v-btn small color="primary" @click="downloadLink(document)">
          <v-icon left color="white"> mdi-download </v-icon>
          Download
        </v-btn>
      </v-card-title>
      <v-divider />

      <v-row>
        <v-col cols="8" offset="2">
          <v-card-text class="elevation-1 my-1">
            <pdf
              v-for="i in getPages(document)"
              :key="i"
              :src="document"
              :page="i"
            />
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import pdf from "vue-pdf";
import FileMixin from "@kinetics254/cassandra-base/mixins/FileMixin";

export default {
  name: "AppraisalReport",
  mixins: [FileMixin],
  components: { pdf },
  data: function () {
    return {
      numPages: undefined,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("appraisal/getAppraisalReport", {
        reviewNo: to.params.code,
      });
    });
  },
  computed: {
    document() {
      return this.$store.getters["appraisal/appraisalGetter"](
        "appraisalReport"
      );
    },
  },
  methods: {
    getPages: function (document) {
      if (document.length !== 0) {
        document = pdf.createLoadingTask(document);
        document.promise.then((pdf) => {
          this.numPages = pdf._pdfInfo.numPages;
        });
        return this.numPages;
      }
    },
  },
};
</script>
