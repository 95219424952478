<template>
  <v-container class="fade-transition">
    <AppraisalHeader class="mt-n1" />
    <v-card flat class="mt-3">
      <v-card-text>
        <span class="headline text-h6">My Appraisals </span>
        <v-row>
          <v-col cols="12" md="4" offset="8">
            <v-select
              v-model="selectedPeriod"
              prepend-inner-icon="filter_alt"
              color="primary"
              dense
              :items="periods"
              :item-text="(item) => item.code"
              :item-value="(item) => item.code"
              label="Filter by period"
              @change="filterAppraisalList"
            ></v-select>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="reviews">
              <template v-slot:item.id="{ item }">
                <span class="supervisor-review-table">{{ item["code"] }}</span>
              </template>
              <template v-slot:item.currentStage="{ item }">
                <span class="supervisor-review-table">{{
                  item["currentStage"]
                }}</span>
                <span class="mx-2">
                  <v-chip
                    v-show="item.status === 'Active'"
                    small
                    color="green"
                    dark
                  >
                    <span class="supervisor-review-table">{{
                      item.status
                    }}</span>
                  </v-chip>
                </span>
              </template>
              <template v-slot:item.employeeScore="{ item }">
                <span class="supervisor-review-table"
                  >{{ MyScore(item) }}%</span
                >
              </template>
              <template v-slot:item.supervisorScore="{ item }">
                <span class="supervisor-review-table"
                  >{{ SupervisorScore(item) }}%</span
                >
              </template>
              <template v-slot:item.overallScore="{ item }">
                <span class="supervisor-review-table"
                  >{{ OverallScore(item) }}%</span
                >
              </template>
              <template v-slot:item.committeeScore="{ item }">
                <span class="supervisor-review-table"
                  >{{ CommiteeScore(item) }}%</span
                >
              </template>
              <template v-slot:item.documentStatus="{ item }">
                <v-chip
                  v-show="item.documentStatus"
                  small
                  :color="getColor(item.documentStatus).color"
                  dark
                >
                  <span class="supervisor-review-table">{{
                    item["documentStatus"]
                  }}</span>
                </v-chip>
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn-toggle>
                  <!-- <v-btn
                  small
                  color="teal darken-1"
                  class="mx-2 white--text"
                  tile
                  link
                  v-if="item.documentStatus === 'Reviewed'"
                  :to="{ name: 'AppraisalReport', params: { code: item.code }}" >
                  report
                </v-btn> -->
                  <v-btn
                    small
                    color="primary"
                    class="mx-2 white--text"
                    tile
                    link
                    v-if="item.documentStatus === 'Reviewed'"
                    @click="downloadFile(item.code)"
                  >
                    <v-icon left color="white"> mdi-download </v-icon>
                    report
                  </v-btn>
                  <v-btn
                    small
                    color="primary"
                    :disabled="
                      item.documentStatus !== 'Open' ||
                      currentReview.periodAPI.closed ||
                      item.status !== 'Active'
                    "
                    tile
                    :to="{
                      name: 'Appraisal Card',
                      params: { code: item.code },
                    }"
                  >
                    Appraise
                    <v-icon right color="white">mdi-arrow-right</v-icon>
                  </v-btn>
                  <template
                    v-if="
                      item.ownerResponse === 'Pending' &&
                      item.documentStatus === 'Reviewed'
                    "
                  >
                    <v-btn
                      small
                      color="danger"
                      class="white--text orange ml-3"
                      :disabled="item.documentStatus !== 'Reviewed'"
                      @click="rejectReview(item.code)"
                    >
                      <v-icon left color="white"> mdi-close </v-icon>
                      Appeal to CEO
                    </v-btn>
                    <v-btn
                      small
                      color="primary"
                      :disabled="item.documentStatus !== 'Reviewed'"
                      class="white--text green ml-3"
                      @click="acceptReview(item.code)"
                    >
                      <v-icon left color="white"> mdi-check </v-icon>
                      Agree
                    </v-btn>
                  </template>
                  <v-btn
                    small
                    color="primary"
                    class="mx-2 white--text"
                    tile
                    link
                    v-if="item.reviewDecision === 'Rewarded'"
                    @click="viewDecision(item.code)"
                  >
                    <v-icon left color="white"> mdi-download </v-icon>
                    view rewards
                  </v-btn>
                  <v-btn
                    v-if="item.reviewStageStatus === 'Reviewed'"
                    small
                    color="primary"
                    tile
                    class="ml-3"
                    :to="{
                      name: 'FinalReport',
                      params: { code: item.code },
                    }"
                  >
                    Final Report
                    <v-icon right color="white">mdi-arrow-right</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <!--END My reviews-->
    </v-card>
    <v-card v-if="showApplications" flat class="mt-3">
      <v-card-text>
        <span class="headline text-h6">My PIP Applications</span>
        <v-row no-gutters>
          <v-col cols="12">
            <v-data-table
              :headers="applicationHeaders"
              :items="employeeApplications"
            >
              <template v-slot:item.id="{ item }">
                <span class="supervisor-review-table">{{
                  item["employeeNo"]
                }}</span>
              </template>
              <template v-slot:item.stage="{ item }">
                <span class="supervisor-review-table">{{ item["stage"] }}</span>
                <span class="mx-2">
                  <v-chip
                    v-show="item.status === 'Active'"
                    small
                    color="green"
                    dark
                  >
                    <span class="supervisor-review-table">{{
                      item.status
                    }}</span>
                  </v-chip>
                </span>
              </template>
              <template v-slot:item.employeeScore="{ item }">
                <span class="supervisor-review-table"
                  >{{ MyScore(item) }}%</span
                >
              </template>
              <template v-slot:item.supervisorScore="{ item }">
                <span class="supervisor-review-table"
                  >{{ SupervisorScore(item) }}%</span
                >
              </template>
              <template v-slot:item.overallScore="{ item }">
                <span class="supervisor-review-table"
                  >{{ OverallScore(item) }}%</span
                >
              </template>
              <template v-slot:item.documentStatus="{ item }">
                <v-chip
                  v-show="item.documentStatus"
                  small
                  :color="getColor(item.documentStatus).color"
                  dark
                >
                  <span class="supervisor-review-table">{{
                    item["documentStatus"]
                  }}</span>
                </v-chip>
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn-toggle>
                  <!-- <v-btn
                  small
                  color="teal darken-1"
                  class="mx-2 white--text"
                  tile
                  link
                  v-if="item.documentStatus === 'Reviewed'"
                  :to="{ name: 'AppraisalReport', params: { code: item.code }}" >
                  report
                </v-btn> -->
                  <v-btn
                    small
                    color="primary"
                    class="mx-2 white--text"
                    tile
                    link
                    v-if="item.documentStatus === 'Reviewed'"
                    @click="downloadFile(item.no)"
                  >
                    <v-icon left color="white"> mdi-download </v-icon>
                    report
                  </v-btn>
                  <v-btn
                    small
                    color="primary"
                    style="color: white"
                    tile
                    :to="{
                      name: 'EmployeeApplication',
                      params: { code: item.code },
                    }"
                  >
                    View
                    <v-icon right color="white">mdi-arrow-right</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <!--END My reviews-->
    </v-card>

    <v-dialog v-model="rewardDialog" max-width="670px">
      <v-card tile>
        <v-card-title>Review Reward</v-card-title>

        <template v-if="rewards">
          <v-card-text
            v-for="reward in rewards"
            :key="reward.rewardId"
            class="mt-3"
          >
            <h4 class="">{{ reward.rewardId }}</h4>
            <p>{{ reward.rewardDesc }}</p>
            <h5>{{ reward.rewardValue }}</h5>
          </v-card-text>
        </template>

        <v-card-actions>
          <v-spacer />
          <v-btn color="red" @click="rewardDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import RouterMixin from "@kinetics254/cassandra-base/mixins/RouterMixin";
import AppraisalMixin from "../AppraisalMixin";
import AppraisalHeader from "./AppraisalHeader.vue";
import FileMixin from "@kinetics254/cassandra-base/mixins/FileMixin";
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";

export default {
  name: "AppraisalList",
  mixins: [RouterMixin, AppraisalMixin, FileMixin],
  components: {
    AppraisalHeader,
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("appraisal/getPeriods");
      v.$store.dispatch("appraisal/getReviews");
      v.$store.dispatch("appraisal/getKpi");
      v.$store.dispatch("appraisal/getEmployeeApplications");
    });
  },
  data: function () {
    return {
      headers: [
        { text: "Code", align: "", sortable: true, value: "id" },
        { text: "Stage", align: "", sortable: true, value: "currentStage" },
        {
          text: "My Score",
          align: "center",
          sortable: true,
          value: "employeeScore",
        },
        {
          text: "Supervisor Score",
          align: "center",
          sortable: true,
          value: "supervisorScore",
        },
        {
          text: "Committee Score",
          align: "center",
          sortable: true,
          value: "committeeScore",
        },
        {
          text: "Final Score",
          align: "center",
          sortable: true,
          value: "overallScore",
        },
        {
          text: "Review Decision",
          align: "",
          sortable: true,
          value: "reviewDecision",
        },
        {
          text: "Owner Response",
          align: "",
          sortable: true,
          value: "ownerResponse",
        },
        { text: "Status", align: "", sortable: true, value: "documentStatus" },
        { text: "Actions", value: "action" },
      ],
      applicationHeaders: [
        { text: "Code", align: "", sortable: true, value: "no" },
        { text: "Stage", align: "", sortable: true, value: "stage" },
        {
          text: "period",
          align: "center",
          sortable: true,
          value: "period",
        },
        { text: "Status", align: "", sortable: true, value: "status" },
        { text: "Actions", value: "action" },
      ],
      selectedPeriod: "",
      period: "",
      rewardDialog: false,
    };
  },
  mounted() {
    Event.$on("goToAppraisal", () => {
      this.$router.push({
        name: "Appraisal",
      });
    });
    Event.$on("openRewardModal", () => (this.rewardDialog = true));
  },
  computed: {
    document() {
      return this.$store.getters["appraisal/appraisalGetter"](
        "appraisalReport"
      );
    },
    reviews() {
      if (this.period !== "") {
        return this.$store.getters["appraisal/reviews"].filter(
          (a) => a.period === this.period
        );
      } else {
        return this.$store.getters["appraisal/reviews"];
      }
    },
    employeeApplications() {
      if (this.period !== "") {
        return this.$store.getters["appraisal/employeeApplications"].filter(
          (a) => a.period === this.period
        );
      } else {
        return this.$store.getters["appraisal/employeeApplications"];
      }
    },

    showApplications() {
      return this.employeeApplications.map((item) => {
        if (item.generatedLines) {
          item.generatedLines.filter((a) => a.isPIP === "Yes");
        }
      })[0]?.length;
    },

    rewards() {
      return this.$store.getters["appraisal/appraisalGetter"](
        "appraisalReward"
      );
    },
  },
  methods: {
    async downloadFile(item) {
      await this.$store.dispatch("appraisal/getAppraisalReport", {
        reviewNo: item,
      });
    },
    filterAppraisalList() {
      this.period = this.selectedPeriod;
    },
    rejectReview(val) {
      this.$confirm.show({
        title: "Disagree With Appraisal Review",
        text: "Are you sure you want to disagree with this appraisal review?",
        input: true,
        label: "Reject With A reason",
        onConfirm: (comment) => {
          this.$store.dispatch("appraisal/rejectReview", {
            reviewNo: val,
            rejectReason: comment,
            params: {
              provider: "GALAXY_CU",
              entity: "appraisalManagement_ContestResults",
            },
          });
          this.dialog = false;
        },
      });
    },
    acceptReview(val) {
      this.$confirm.show({
        title: "Agree With Appraisal Review",
        text: "Are you sure you want to agree with this appraisal review?",
        onConfirm: () => {
          this.$store.dispatch("appraisal/acceptReview", {
            reviewNo: val,
          });
        },
      });
    },
    viewDecision(val) {
      this.$store.dispatch("appraisal/getReward", {
        reviewId: val,
      });
    },
  },
  watch: {
    document() {
      this.downloadLink(this.document);
    },
  },
};
</script>

<style scoped>
@import "../styles/appraisal.css";
</style>
