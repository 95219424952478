<template>
  <v-container fluid class="mt-10">
    <v-card elevation="0" tile class="main-card">
      <v-card-title class="overline header-title">
        <div class="caption">
          <span class="description"
            >PERIOD: {{ periodApi ? periodApi.code : "" }}
          </span>
        </div>

        <v-chip x-small dark class="ml-2" color="success">
          {{ currentReview.documentStatus }}
        </v-chip>
        <v-spacer />
        <div class="caption">
          <span class="description">
            Title:
            {{ currentReview ? currentReview.employeeJobTitle : "" }}</span
          >
        </div>
        <v-spacer />
        <!-- <div class="caption"><span class="description">Department:  {{ currentReview  ? currentReview.employeeJobGrade : ''}}</span> </div> -->
        <v-spacer />
        <v-btn
          small
          color="accent"
          class="mr-2"
          v-if="
            currentReview.documentStatus === 'Reviewed' &&
            currentReview.secondReviewerComment === ''
          "
          @click="commentDialog = true"
        >
          second supervisor Comment
        </v-btn>
        <v-btn
          @click="submit"
          v-if="currentReview.documentStatus === 'Submitted'"
          small
          color="primary"
          class="mr-2"
        >
          Submit review
          <v-icon right>mdi-send</v-icon>
        </v-btn>
        <v-btn
          @click="submit"
          v-if="currentReview.documentStatus === 'Open'"
          small
          color="primary"
          class="mr-2"
        >
          Submit Review
          <v-icon right>mdi-send</v-icon>
        </v-btn>
        <v-btn
          small
          v-if="
            currentReview.reviewStageStatus === 'Open' &&
            currentReview.documentStatus === 'Reviewed' &&
            currentReview.ownerResponse === 'Rejected'
          "
          color="primary"
          @click="markAsCommitteeReviewed"
        >
          Mark as Committee Reviewed
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <!-- Objectives groupings-->
          <v-col cols="12" md="3">
            <v-list subheader>
              <v-subheader>
                <span class="subheader-title">Objectives</span>
                <v-spacer />
                <v-chip x-small dark class="blue">
                  {{ objectives.length }}
                </v-chip>
              </v-subheader>
              <v-list-item-group>
                <v-list-item
                  @click.native="gotoKpiListing"
                  link
                  v-for="(obj, g) in objectives"
                  :to="`/appraisal/card/${obj.reviewID}/${obj.objectiveID}`"
                  :key="g"
                  class="list-group-item ml-n10"
                  active-class="font-weight-bold primary--text"
                  style="box-shadow: none"
                >
                  <v-list-item-avatar>
                    <!-- &nbsp; -->
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-list-item-title class="list-group-item">{{
                            obj.description
                          }}</v-list-item-title>
                          <!-- <v-list-item-subtitle class="list-group-item">Weight: {{ obj.weight }}</v-list-item-subtitle>
                      <v-list-item-subtitle class="list-group-item">Target: {{ obj.target }}%</v-list-item-subtitle> -->
                        </div>
                      </template>
                      <span>{{ obj.description }}</span>
                    </v-tooltip>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon color="error" v-if="allKPIsRated(obj.reviewKPI)"
                      >error</v-icon
                    >
                    <v-icon color="success" v-else
                      >mdi-checkbox-marked-circle</v-icon
                    >
                    <v-icon>mdi-menu-right</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>

          <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
            <v-divider />
          </v-col>
          <v-divider v-else vertical />
          <!-- KPI Listing -->
          <v-col cols="12" md="3" ref="listedIndicators">
            <v-list subheader two-line dense>
              <v-subheader>
                <span class="subheader-title">Key Performance Indicators</span>
                <v-spacer />
                <v-chip x-small dark class="deep-orange">
                  {{ kpis.length }}
                </v-chip>
              </v-subheader>
              <v-list-item-group>
                <v-list-item
                  v-for="kpi in kpis"
                  :key="kpi.title"
                  @click="setKpi(kpi)"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ kpi.kpiID }}
                      <span v-if="$vuetify.breakpoint.smAndDown">
                        <v-icon
                          color="green lighten-2"
                          v-if="
                            (selfEdit && kpi.selfRatingScore) ||
                            (reviewerEdit && kpi.reviewerRatingScore)
                          "
                          >mdi-check-circle</v-icon
                        >
                      </span>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="list-group-item"
                      v-text="kpi.description"
                    ></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action v-if="$vuetify.breakpoint.mdAndUp">
                    <!-- display only when kpi is rated -->
                    <v-btn icon>
                      <v-icon
                        color="green lighten-2"
                        v-if="
                          (selfEdit && kpi.selfRatingScore) ||
                          (reviewerEdit && kpi.reviewerRatingScore) ||
                          (committeeEdit && kpi.committeeRatingScore)
                        "
                        >mdi-check-circle</v-icon
                      >
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
            <v-divider />
          </v-col>
          <v-divider v-else vertical />
          <!-- KPI Scoring  -->
          <v-col cols="12" md="5" style="min-height: 70vh" ref="ratingForm">
            <AppraisalRatingForms
              :reviewerEdit="reviewerEdit"
              :selectedKpi="selectedKpi"
              :selectedKpiCtrl="selectedKpiCtrl"
              :selfEdit="selfEdit"
              :unsaved="unsaved"
              :reviewerAttachment="reviewerAttachment"
              :selfAttachment="selfAttachment"
              :intendedSelection="intendedSelection"
              :committeeAttachment="committeeAttachment"
              :discard="discard"
              @updateSelectedCtrl="updateSelectedCtrl"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="$vuetify.breakpoint.smAndDown">
        <v-spacer />
        <v-btn text small @click="gotoKpiListing" color="primary">
          <v-icon left> mdi-arrow-left </v-icon>
          Back to All Kpis
        </v-btn>
      </v-card-actions>
    </v-card>
    <appraisal-summary
      :prevRoute="prevRoute"
      :selfEdit="selfEdit"
      :reviewObjectives="objectives"
      :status="status"
      :dialog="summaryDialog"
      :currReview="currentReview"
      :kpiLength="kpiLength"
      v-on:close-dialog="closeSummaryDialog"
    />
    <v-dialog v-model="commentDialog" max-width="600" height="auto" persistent>
      <v-card>
        <v-card-title>
          Second Supervisor Comment
          <v-spacer></v-spacer>
          <v-icon color="error" @click="commentDialog = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="mt-5">
          Please provide feedback on the employee's strengths, areas of
          improvement, and any other comments you feel would be helpful,
          appropriate and relevant to the employee's performance. For example,
          "The employee consistently meets or exceeds expectations in their job
          duties, but could benefit from additional training in communication
          skills" or "The employee has demonstrated a strong work ethic and has
          shown significant improvement in their time management skills over the
          past year."
        </v-card-text>

        <v-card-text>
          <v-textarea
            outlined
            v-model="currentReview.secondReviewerComment"
            placeholder="Add your Review Comment here ..."
          ></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" depressed @click="submitComment()">
            submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import RouterMixin from "@kinetics254/cassandra-base/mixins/RouterMixin";
import FileMixin from "@kinetics254/cassandra-base/mixins/FileMixin";
import AppraisalSummary from "./AppraisalSummary.vue";
import AppraisalRatingForms from "../../appraisalv1/components/AppraisalRatingForms.vue";

export default {
  name: "AppraisalCard",
  mixins: [RouterMixin, FileMixin],
  components: { AppraisalSummary, AppraisalRatingForms },
  data: () => ({
    selectedKpi: null,
    selectedKpiCtrl: null,
    intendedSelection: null,
    unsaved: false,
    summaryDialog: false,
    status: "",
    selfAttachment: null,
    reviewerAttachment: null,
    prevRoute: null,
    commentDialog: false,
    committeeAttachment: null,
  }),

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.prevRoute = from;
      v.$store.dispatch("appraisal/getByDocumentNo", to.params.code);
      v.$store.dispatch("appraisal/getPeriods");
      v.$store.dispatch("appraisal/getRatingScale");
    });
  },
  mounted() {
    Event.$on("closeDialog", () => {
      this.commentDialog = false;
    });
  },

  computed: {
    currentReview() {
      return this.$store.getters["appraisal/appraisalGetter"]("currentReview");
    },

    periods() {
      return this.$store.getters["appraisal/periods"];
    },

    periodApi() {
      return this.currentReview ? this.currentReview.periodAPI : {};
    },

    currPeriod() {
      return this.periods.filter((p) => p.current).shift();
    },

    objectives() {
      if (this.currentReview) {
        let objectives = this.currentReview.reviewObjectives;
        return objectives
          ? objectives.sort((a, b) => (a.sequenceID > b.sequenceID ? 1 : -1))
          : [];
      } else {
        return [];
      }
    },

    kpis() {
      const objective = this.objectives
        .filter((ob) => ob.objectiveID === this.$route.params.objective)
        .shift();
      return objective
        ? objective.reviewKPI.sort((a, b) => (a.kpiID > b.kpiID ? 1 : -1))
        : [];
    },

    kpiLength() {
      return this.objectives
        ? this.objectives.reduce((a, b) => a + b.reviewKPI.length, 0)
        : 0;
    },

    reviewerEdit() {
      return (
        ["Submitted"].includes(this.currentReview.documentStatus) &&
        this.currentReview.reviewerID ===
          this.$store.getters["auth/user"].employee
      );
    },

    selfEdit() {
      return ["Open"].includes(this.currentReview.documentStatus);
    },

    committeeEdit() {
      return (
        ["Open"].includes(this.currentReview.reviewStageStatus) &&
        this.currentReview.ownerResponse === ""
      );
    },

    userData: function () {
      return this.$store.getters["auth/user"];
    },
  },
  methods: {
    gotoKpiListing() {
      if (this.$vuetify.breakpoint.smAndDown) {
        const el = this.$refs.listedIndicators;
        el.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    },
    setKpi: function (kpi) {
      if (this.$vuetify.breakpoint.smAndDown) {
        const el = this.$refs.ratingForm;
        el.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      this.intendedSelection = { ...kpi };
      if (
        JSON.stringify({ ...this.selectedKpi }) !==
        JSON.stringify({ ...this.selectedKpiCtrl })
      ) {
        this.unsaved = true;
        return;
      }

      let selfComment = null;
      let reviewerComment = null;
      let selfAttachment = null;
      let reviewerAttachment = null;
      let committeeComment = null;
      let committeeAttachment = null;

      kpi.kpiComments.forEach((c) => {
        if (c.author === "Reviewer" && c.type === "Comment") {
          reviewerComment = c;
        }

        if (c.author === "Own" && c.type === "Comment") {
          selfComment = c;
        }

        if (c.author === "Committee" && c.type === "Comment") {
          committeeComment = c;
        }

        if (c.author === "Own" && c.type === "Attachment") {
          selfAttachment = c;
        }

        if (c.author === "Reviewer" && c.type === "Attachment") {
          reviewerAttachment = c;
        }

        if (c.author === "Committee" && c.type === "Attachment") {
          committeeAttachment = c;
        }
      });

      this.selectedKpi = {
        ...kpi,
        ...{
          reviewerComment: reviewerComment ? reviewerComment.Comment : "",
          selfComment: selfComment ? selfComment.Comment : "",

          committeeComment: committeeComment ? committeeComment.Comment : "",
          selfAttachment: selfAttachment
            ? process.env.VUE_APP_FILE_API + selfAttachment.base64Attachment
            : "",
          reviewerAttachment: reviewerAttachment
            ? process.env.VUE_APP_FILE_API + reviewerAttachment.base64Attachment
            : "",
          committeeAttachment: committeeAttachment
            ? process.env.VUE_APP_FILE_API +
              committeeAttachment.base64Attachment
            : "",
        },
      };

      this.selectedKpiCtrl = { ...this.selectedKpi };
      this.$nextTick(() => {
        Event.$emit("parentAction", "employeeForm");
        Event.$emit("parentAction", "supervisorForm");
        Event.$emit("parentAction", "committeeForm");
      });
    },
    discard: function () {
      this.selectedKpi = { ...this.selectedKpiCtrl };
      this.unsaved = false;

      this.setKpi(this.intendedSelection);
    },
    submit: function () {
      this.summaryDialog = true;
    },
    closeSummaryDialog: function (val) {
      this.summaryDialog = val;
    },
    updateSelectedCtrl(val) {
      this.selectedKpiCtrl = val;
    },
    // check if all objectives are rated
    allObjectivesRated(item) {
      let count = 0;
      if (this.selfEdit) {
        item.forEach((objective) => {
          count = objective.reviewKPI.filter((obj) => {
            if (obj.selfRatingScore === "") {
              return true;
            }
            return false;
          }).length;
        });
        if (count > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        item.forEach((objective) => {
          count = objective.reviewKPI.filter((obj) => {
            if (obj.reviewerRatingScore === "") {
              return true;
            }
            return false;
          }).length;
        });
        if (count > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    // check if all kpis under an objective are rated
    allKPIsRated(item) {
      if (this.selfEdit) {
        const count = item.filter((obj) => {
          if (obj.selfRatingScore === "") {
            return true;
          }
          return false;
        }).length;
        if (count > 0) {
          return true;
        } else {
          return false;
        }
      } else if (this.reviewerEdit) {
        const count = item.filter((obj) => {
          if (obj.reviewerRatingScore === "") {
            return true;
          }
          return false;
        }).length;
        if (count > 0) {
          return true;
        } else {
          return false;
        }
        // eslint-disable-next-line no-empty
      } else if (this.committeeEdit) {
        const count = item.filter((obj) => {
          if (obj.committeeRatingScore === "") {
            return true;
          }
          return false;
        }).length;
        if (count > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    submitComment() {
      const data = {
        reviewDocNo: this.currentReview.code,
        employeeNo: this.$store.getters["auth/user"].employee,
        comment: this.currentReview.secondReviewerComment,
      };
      this.$store.dispatch("appraisal/submitComment", data);
    },

    markAsCommitteeReviewed() {
      this.$confirm.show({
        title: "Mark appraisal as Committee Reviewed",
        text: "Are you sure you want to Mark this appraisal as Committee Reviewed",
        onConfirm: () => {
          this.$store.dispatch("appraisal/markAsCommitteeReviewed", {
            employeeNo: this.userData.employee,
            reviewNo: this.currentReview.code,
          });
        },
      });
    },
  },
};
</script>

<style scoped>
@import "../../appraisalv1/styles/appraisal.css";
</style>
