import Auth from "@kinetics254/cassandra-base/modules/auth/middleware/Auth";
import AppraisalList from "./components/AppraisalList";
// import AppraisalCard from "./components/AppraisalCard";
import AppraisalCard from "../appraisalv2/components/AppraisalCard";
import AppraisalDashboard from "./components/AppraisalDashboard";
import AppraisalReview from "./components/AppraisalReview";
import AppraisalSupervisorReviews from "./components/AppraisalSupervisorReviews";
import SecondSupervisorReviews from "./components/SecondSupervisorReviews";
import Appraisal from "./views/Appraisal";
import AppraisalReport from "./components/AppraisalReport";
import SupervisorAppraisalReport from "./components/SupervisorAppraisalReport";
import Templates from "./components/Templates";
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
import EmployeeApplication from "./components/EmployeeApplication.vue";
import FinalReport from "./components/FinalReport.vue";
const AppDashboard = () =>
  import("@kinetics254/cassandra-base/app/layout/AppDashboard"); // lazy load

const user = () => {
  return AuthService.user;
};
export default [
  {
    path: "/appraisal",
    component: AppDashboard,
    children: [
      {
        path: "",
        components: { view: Appraisal },
        children: [
          {
            path: "/",
            redirect: "/appraisal/dashboard",
          },
          {
            path: "list",
            name: "Appraisal",
            component: AppraisalList,
            meta: { middleware: [Auth] },
          },
          {
            path: "supervisor-reviews/:code",
            name: "Appraisal",
            component: AppraisalSupervisorReviews,
            meta: { middleware: [Auth] },
          },
          {
            path: "second-supervisor-reviews/:code",
            name: "Appraisal",
            component: SecondSupervisorReviews,
            meta: { middleware: [Auth] },
          },
          {
            path: "dashboard",
            name: "AppraisalDashboard",
            component: AppraisalDashboard,
            meta: { middleware: [Auth] },
          },
          {
            path: "review/:code?/:objective?/:kpi?",
            name: "Appraisal Review",
            component: AppraisalReview,
            meta: { middleware: [Auth] },
          },
          {
            path: "template/:template?/:group?/:objective?",
            component: Templates,
            meta: { middleware: [Auth] },
          },
          {
            path: "card/:code/:objective?/:review?",
            name: "Appraisal Card",
            component: AppraisalCard,
            meta: { middleware: [Auth] },
          },
          {
            path: "report/:code",
            name: "AppraisalReport",
            component: AppraisalReport,
            meta: { middleware: [Auth] },
          },
          {
            path: "supervisor-report",
            name: "supervisorReport",
            component: SupervisorAppraisalReport,
            meta: { middleware: [Auth] },
          },
          {
            path: "/list/:code",
            name: "EmployeeApplication",
            component: EmployeeApplication,
            meta: { middleware: [Auth] },
          },
          {
            path: "/list/:code/report",
            name: "FinalReport",
            component: FinalReport,
            meta: { middleware: [Auth] },
          },
        ],
      },
    ],
  },
];
