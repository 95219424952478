import { Auth } from "@kinetics254/cassandra-base/modules/auth";
import handOverRequests from "../handover/components/HandOverRequests";
import LeaveForm from "./components/LeaveForm";
import AppDashboard from "@kinetics254/cassandra-base/app/layout/AppDashboard";

export default [
  {
    path: "/handover",
    component: AppDashboard,
    children: [
      {
        path: "handOverRequests",
        components: {
          view: handOverRequests,
        },
        meta: {
          middleware: Auth,
          breadcrumb: [
            { text: "handover", disabled: true, href: "/" },
            {
              text: "handOverRequests",
              disabled: false,
              href: "/handover/handOverRequests",
            },
          ],
        },
      },
      {
        path: "LeaveForm/:application_code",
        name: "LeaveForm",
        components: {
          view: LeaveForm,
        },
        meta: {
          middleware: Auth,
          breadcrumb: [
            { text: "Handover", disabled: true, href: "/" },
            {
              text: "application",
              disabled: false,
              href: "/Handover/application",
            },
            {
              text: "LeaveForm",
              disabled: false,
              href: "LeaveForm/:application_code",
            },
          ],
        },
      },
    ],
  },
];
