import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VDataTable,{ref:"leave_planner_list",staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.planners,"sort-desc":[false, true],"search":_vm.listFilter,"multi-sort":"","id":"scollStyle"},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"label":"","color":_vm.getColor(item.status).color,"small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.employeeName",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex-inline justify-start"},[_c(VAvatar,_vm._g(_vm._b({staticClass:"primary lighten-4 primary--text text--darken-4",staticStyle:{"cursor":"pointer"},attrs:{"size":"28"}},'v-avatar',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getInitials(item.employeeName).slice(0, 2))+" ")]),_vm._v(" "+_vm._s(item.employeeName)+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.employeeName))])])]}},{key:"item.leaveTypes",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.leavePlannerLines.map(function (line) { return line.leaveType; }).join(", "))+" ")])]}},{key:"item.fromDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateFormat(item.fromDate))+" ")]}},{key:"item.toDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateFormat(item.toDate))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-start"},[[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"primary lighten-4 mx-1",attrs:{"label":""},on:{"click":function($event){return _vm.reviewApplication(item)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"primary darken-4"}},[_vm._v("mdi-page-next")])],1)]}}],null,true)},[_c('span',[_vm._v("View Application")])])],(item.approvalEntry.status === 'Open')?[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"green lighten-4 mx-1",attrs:{"label":""},on:{"click":function($event){return _vm.approve(item.approvalEntry)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"primary darken-4"}},[_vm._v("schedule_send")])],1)]}}],null,true)},[_c('span',[_vm._v("Approve")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"red lighten-4 mx-1",attrs:{"label":""},on:{"click":function($event){return _vm.openRejectDialog(item.approvalEntry)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"primary darken-4"}},[_vm._v("event_busy")])],1)]}}],null,true)},[_c('span',[_vm._v("Reject")])])]:_vm._e()],2)]}}])}),_c(VDialog,{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.rejectDialog),callback:function ($$v) {_vm.rejectDialog=$$v},expression:"rejectDialog"}},[_c(VCard,{staticClass:"mx-auto",attrs:{"width":"500"}},[_c(VCardTitle,[_c('div',{staticClass:"text-body-1"},[_vm._v("Add reject reason")])]),_c(VCardText,[_c(VTextarea,{attrs:{"label":"Reject Reason","outlined":"","rows":"3","maxlength":"250","dense":"","clearable":"","hint":"250 characters max"},model:{value:(_vm.rejectReason),callback:function ($$v) {_vm.rejectReason=$$v},expression:"rejectReason"}})],1),_c(VCardActions,[_c(VBtn,{staticClass:"text-capitalize",attrs:{"text":"","small":""},on:{"click":function($event){_vm.rejectDialog = false}}},[_c(VIcon,[_vm._v("mdi-close")]),_vm._v(" close ")],1),_c(VSpacer),_c(VBtn,{staticClass:"red white--text",on:{"click":_vm.reject}},[_c(VIcon,{staticClass:"mx-2"},[_vm._v("thumb_down")]),_vm._v(" Reject ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }