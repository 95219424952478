<template>
  <div class="ml-4">
    <ReqStats />
    <v-card class="mx-auto mt-3" flat>
      <v-row class="md-justify-space-around mt-sm-2">
        <v-col cols="12" md="4">
          <v-toolbar dense flat>
            <v-toolbar-title class="header-title">{{
              documentType
            }}</v-toolbar-title>
          </v-toolbar>
        </v-col>
        <v-col class="d-flex" cols="12" md="4">
          <v-toolbar dense flat>
            <v-btn-toggle
              v-model="tabs"
              active-class="toggle--active"
              borderless
              dense
              mandatory
              rounded
            >
              <v-btn
                v-for="(item, i) in status"
                :key="i"
                :color="item === tabs ? 'primary' : 'default'"
                :elevation="item === tabs ? 8 : 0"
                :rounded="item === tabs"
                :value="item"
                small
              >
                <span
                  :style="{ color: item === tabs ? '#ffffff' : '' }"
                  class="text-capitalize"
                  >{{
                    item === "Released"
                      ? "Approved"
                      : item && item === "Pending Approval"
                      ? "Pending"
                      : item
                  }}</span
                >
              </v-btn>
            </v-btn-toggle>
          </v-toolbar>
        </v-col>
        <v-col class="d-md-flex justify-end" cols="12" md="3" offset="1">
          <v-toolbar dense flat>
            <v-btn
              :to="{
                name: 'RequisitionCard',
                params: { code: $route.params.documentType },
              }"
              class="text-capitalize pt-1 d-sm-block d-md-table-cell"
              color="primary"
            >
              <v-icon class="mx-2">add</v-icon>
              <span class="header-title--sub">New Request</span>
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ml-md-4" cols="12" md="4" sm="12">
          <v-menu
            v-model="filterDatePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            min-width="auto"
            offset-y
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                color="primary"
                dense
                label="Filter by Order Date"
                prepend-inner-icon="filter_alt"
                v-bind="attrs"
                v-on="on"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filterDate"
              range
              @click="(status = 'Open'), (imprestStatus = 'Issued')"
              @input="
                (filterDatePicker = false),
                  (startDate = filterDate[0]),
                  (endDate = filterDate[1])
              "
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-spacer />
        <v-col class="mr-md-4" cols="12" md="4" sm="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            class="mt-n3"
            clearable
            hide-details
            label="Search"
            single-line
          />
        </v-col>
      </v-row>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="requisitions"
          :items-per-page="5"
          :search="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          multi-sort
        >
          <!--BEGIN Add no column -->
          <!--eslint-disable-next-line -->
          <template v-slot:item.no="{ item }">
            <span class="table-font">{{ item.no }}</span>
          </template>
          <!--END Add no column -->
          <!--eslint-disable-next-line -->
          <template v-slot:item.requestedFor="{ item }">
            <span class="table-font">{{ getEmployee(item.requestedFor) }}</span>
          </template>
          <!--eslint-disable-next-line -->
          <template v-slot:item.dueDate="{ item }">
            <span class="table-font">{{ getDateFormat(item.dueDate) }}</span>
          </template>
          <!--eslint-disable-next-line -->
          <template v-slot:item.orderDate="{ item }">
            <span class="table-font">{{ getDateFormat(item.orderDate) }}</span>
          </template>
          <!--eslint-disable-next-line -->
          <template v-if="documentType !== 'Store Requisition'" v-slot:item.amount="{ item }">
            <span class="table-font">
              <!-- {{
                isMultiCurrencyEnabled
                  ? `(${item.currencyCode})`
                  : `(${defaultCurrencyCaption})`
              }} -->
              {{ `(${defaultCurrencyCaption})` }}
              {{ item.amount | currencyFormat }}</span
            >
          </template>
          <!--eslint-disable-next-line -->
          <template v-slot:item.quantity="{ item }"
            v-if="documentType === 'Store Requisition'"
          >
            <span class="table-font">{{
              getQuantity(item.requisitionLines)
            }}</span>
          </template>
          <!--END requested for column -->
          <!--eslint-disable-next-line -->
          <template v-slot:item.status="{ item }" class="table-font">
            <v-icon
              :color="getStatusColor(item.status).color"
              class="mx-1 mt-n1"
              label
              small
            >
              mdi-radiobox-marked
            </v-icon>
            <span class="table-font">{{
              item.status === "Released" ? "Approved" : item.status
            }}</span>
          </template>
          <!--BEGIN actions column-->
          <!--eslint-disable-next-line -->
          <template v-slot:item.actions="{ item }">
            <div class="d-flex justify-start">
              <!--BEGIN the view action button-->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    :to="{ name: 'RequisitionCard', query: { code: item.no } }"
                    class="mx-1"
                    color="teal lighten-4"
                    label
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="teal darken-4" small>mdi-eye</v-icon>
                  </v-chip>
                </template>
                <span>view</span>
              </v-tooltip>
              <!--END the view action button-->
              <div class="d-flex justify-start">
                <!--BEGIN the send action button-->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-show="item.status === 'Open'"
                      class="mx-1"
                      color="blue lighten-4"
                      label
                      small
                      v-bind="attrs"
                      @click="sendListApprovalRequest(item.no)"
                      v-on="on"
                    >
                      <v-icon color="blue darken-4" small>mdi-send</v-icon>
                    </v-chip>
                  </template>
                  <span>send</span>
                </v-tooltip>
                <!--END the send action button-->
                <!--BEGIN the delete action button-->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-show="item.status === 'Open'"
                      class="mx-1"
                      color="red lighten-4"
                      label
                      small
                      v-bind="attrs"
                      @click="deleteRequisition(item)"
                      v-on="on"
                    >
                      <v-icon color="red darken-4" small>mdi-delete</v-icon>
                    </v-chip>
                  </template>
                  <span>remove</span>
                </v-tooltip>
                <!--END the delete action button-->
                <!--BEGIN the cancel action button-->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-show="item.status === 'Pending Approval'"
                      class="mx-1"
                      color="orange lighten-4"
                      label
                      ripple
                      small
                      v-bind="attrs"
                      @click="cancelRequisition(item)"
                      v-on="on"
                    >
                      <v-icon color="orange darken-4" small
                        >cancel_schedule_send</v-icon
                      >
                    </v-chip>
                  </template>
                  <span>cancel request</span>
                </v-tooltip>
                <!--END the cancel action button-->
              </div>
            </div>
          </template>
          <!--END actions column-->
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import ReqStats from "./ReqStats.vue";
import ReqMixin from "../ReqMixin";

export default {
  name: "RequisitionList",
  components: { ReqStats },
  mixins: [ReqMixin],
  data: function () {
    return {
      tabs: "All",
      status: ["All", "Open", "Pending Approval", "Released"],
      sortBy: "no",
      sortDesc: true,
      search: "",
      filterDate: [],
      filterDatePicker: "",
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Requisition/getRequisitions");
    });
  },

  computed: {
    employees() {
      return this.$store.getters["Setup/setupGetter"]("leaveEmployees");
    },
    settings() {
      let settings = this.$store.getters["Admin/adminGetters"]("settings");
      return Object.keys(settings).length > 0
        ? JSON.parse(settings.cashmgt_settings)
        : null;
    },
    isMultiCurrencyEnabled() {
      return this.settings ? this.settings.multiple_currency : false;
    },
    requisitions: function () {
      if (this.filterDate.length !== 0) {
        return this.tabs === "All"
          ? this.$store.getters["Requisition/reqGetters"](
              "requisitions"
            ).filter(
              (a) =>
                new Date(a.orderDate) >= this.startDate &&
                new Date(a.orderDate) <= this.endDate
            )
          : this.$store.getters["Requisition/reqGetters"](
              "requisitions"
            ).filter(
              (a) =>
                new Date(a.orderDate) >= this.startDate &&
                new Date(a.orderDate) <= this.endDate &&
                a.documentType === this.documentType &&
                a.status === this.tabs
            );
      }
      return this.tabs === "All"
        ? this.$store.getters["Requisition/reqGetters"]("requisitions").filter(
            (v) => v.documentType === this.documentType
          )
        : this.$store.getters["Requisition/reqGetters"]("requisitions").filter(
            (v) =>
              v.documentType === this.documentType && v.status === this.tabs
          );
    },
    documentType() {
      return atob(this.$route.params.documentType);
    },
    headers: function () {
      return [
        {
          text: "#no",
          align: "start",
          sortable: true,
          value: "no",
        },
        { text: "Requested For", sortable: true, value: "requestedFor" },
        {
          text:
            this.documentType !== "Store Requisition" ? "Amount" : "Quantity",
          sortable: true,
          value:
            this.documentType !== "Store Requisition" ? "amount" : "quantity",
        },
        { text: "Order Date", sortable: true, value: "orderDate" },
        { text: "Due Date", sortable: true, value: "dueDate" },
        { text: "Status", sortable: true, value: "status", filterable: false },
        {
          text: "Actions",
          sortable: false,
          value: "actions",
          filterable: false,
        },
      ];
    },
    routeParams() {
      return this.$route.params.code;
    },
    dateRangeText() {
      return this.filterDate.join(" To ");
    },
    defaultCurrencyCaption() {
      return this.settings.default_currency_caption ?? "KES ";
    },
  },

  methods: {
    getEmployee(no) {
      const employee = this.employees.filter((el) => el.number === no);
      return employee.length !== 0
        ? employee[0]?.firstName + " " + employee[0]?.lastName
        : "";
    },
    /**
     * Split username
     * @description
     * @returns {void}
     * @param username
     */
    splitUsername: function (username) {
      return username.split("\\")[1];
    },
    /**
     * Get quantity
     * @description
     * @param {Object} item
     * @returns {void}
     */
    getQuantity(item) {
      return item.reduce((acc, a) => {
        return acc + a.quantity;
      }, 0);
    },
  },
  watch: {
    filterDate() {
      if (this.filterDate.length === 1) {
        this.startDate = new Date(this.filterDate[0]);
        this.endDate = new Date(this.filterDate[0]);
      }
      if (this.filterDate.length === 2) {
        this.startDate = new Date(this.filterDate[0]);
        this.endDate = new Date(this.filterDate[1]);
      }
    },
  },
};
</script>
<style scoped>
@import "../styles/style.css";
</style>
