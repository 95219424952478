import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,[_vm._v(" Programmes "),_c(VSpacer),(
        !_vm.isNew &&
        (_vm.formData.submittedBy === _vm.user.number || _vm.formData.submittedBy === '')
      )?_c(VBtn,{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.addNewLine}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" add programme ")],1):_vm._e()],1),_c(VDataTable,{attrs:{"headers":_vm.trainingLinesHeaders,"items":_vm.trainingLinesList,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.programmeCode",fn:function(ref){
      var item = ref.item;
return [(_vm.displayConditionally(item))?_c(VAutocomplete,{staticClass:"mb-n3",attrs:{"rules":_vm.rules.required,"items":_vm.mycourses,"item-text":function (item) { return ((item.no) + " - " + (item.description)); },"item-value":function (item) { return item.no; },"hide-details":false,"label":"Select Programme","dense":"","single-line":""},on:{"focusout":_vm.autosaveTrainingLine},model:{value:(_vm.line.programmeCode),callback:function ($$v) {_vm.$set(_vm.line, "programmeCode", $$v)},expression:"line.programmeCode"}}):_c('span',[_vm._v(_vm._s(item.programmeCode))])]}},{key:"item.trainingDetails",fn:function(ref){
      var item = ref.item;
return [(_vm.displayConditionally(item))?_c(VTextField,{staticClass:"mb-n3",attrs:{"label":"Description","dense":"","single-line":""},on:{"focusout":_vm.autosaveTrainingLine},model:{value:(_vm.line.trainingDetails),callback:function ($$v) {_vm.$set(_vm.line, "trainingDetails", $$v)},expression:"line.trainingDetails"}}):_c('span',[_vm._v(_vm._s(item.trainingDetails))])]}},{key:"item.trainers",fn:function(ref){
      var item = ref.item;
return [(item.trainers)?_c('span',[_vm._v(" "+_vm._s(item.trainers.name)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('div',[(_vm.displayConditionally(item))?_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"red"},on:{"click":_vm.close}},'v-icon',attrs,false),on),[_vm._v(" mdi-window-close ")])]}}],null,true)},[_c('span',[_vm._v("Close Editing")])]),_c(VIcon,{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])],1):_c('div',[_c(VIcon,{staticClass:"mr-3",attrs:{"color":"green"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c(VIcon,{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }