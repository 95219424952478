<template>
  <v-card class="mx-auto mt-10" flat>
    <v-card-title
      >Billing Items
      <v-spacer />
      <v-btn
        color="primary"
        class="ml-2 white--text"
        @click="addNew"
        v-if="hideButtons"
      >
        <v-icon dark>mdi-plus</v-icon>Add Billing Item
      </v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="billingItemsList"
      :search="search"
      class="elevation-0"
      fixed-header
    >
      <v-divider inset></v-divider>
      <!--eslint-disable-next-line -->
      <template v-slot:item.code="{ item }">
        <v-autocomplete
          @focusout="autosaveBillingItem"
          v-model="billingItem.billingItemCode"
          :items="billingItems"
          :item-text="(item) => `${item.code} - ${item.description}`"
          :item-value="(item) => item.code"
          :hide-details="false"
          @change="selectBillingItemCode"
          label="Select Billing Item"
          dense
          single-line
          v-if="displayConditionally(item)"
          class="mb-n3"
        ></v-autocomplete>
        <span v-else>{{ item.billingItemCode }}</span>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.description="{ item }">
        <v-text-field
          @focusout="autosaveBillingItem"
          v-model.number="billingItem.description"
          :hide-details="true"
          dense
          single-line
          v-if="displayConditionally(item)"
        ></v-text-field>
        <span v-else>{{ item.description }}</span>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.quantity="{ item, index }">
        <v-text-field
          @focusout="autosaveBillingItem"
          @input="assignQuantity($event, index)"
          type="number"
          onkeyup="if(this.value<0){this.value= this.value * -1}"
          min="1"
          v-model.number="billingItem.quantity"
          :hide-details="true"
          dense
          single-line
          v-if="displayConditionally(item)"
        ></v-text-field>
        <span v-else>{{ item.quantity }}</span>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.unitOfMeasure="{ item }">
        <v-select
          @focusout="autosaveBillingItem"
          v-model="billingItem.unitOfMeasure"
          :items="unitsOfMeasure"
          :item-text="(item) => item.displayName"
          :item-value="(item) => item.code"
          :hide-details="false"
          label="Unit Of Measure"
          dense
          single-line
          v-if="displayConditionally(item)"
          class="mb-n3"
        ></v-select>
        <span v-else>{{ item.unitOfMeasure }}</span>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.locationCode="{ item }"
        v-if="
          documentType === 'Store Requisition' ||
          requisition.documentType === 'Store Requisition'
        "
      >
        <v-select
          @focusout="autosaveBillingItem"
          v-if="displayConditionally(item)"
          label="Select Location"
          :items="locations"
          :item-text="(item) => item.name"
          :item-value="(item) => item.code"
          v-model="billingItem.locationCode"
          :hide-details="true"
          dense
          single-line
        ></v-select>
        <span v-else>{{ item.locationCode }}</span>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.unitCost="{ item, index }">
        <v-text-field
          @focusout="autosaveBillingItem"
          @input="assignUnitCost($event, index)"
          v-model.number="billingItem.unitCost"
          :hide-details="true"
          dense
          single-line
          min="1"
          type="number"
          v-if="displayConditionally(item)"
        ></v-text-field>
        <span v-else>{{ item.unitCost | currencyFormat }}</span>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.amount="{ item }" type="number" min="1"
        v-if="
          documentType !== 'Store Requisition' ||
          requisition.documentType !== 'Store Requisition'
        "
      >
        <span>{{
          Number(item.quantity * item.unitCost) | currencyFormat
        }}</span>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.dimensions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              color="primary"
              small
              class="ml-2 white--text text-caption font-weight-thin"
              @click="addDimensions(item)"
              v-bind="attrs"
              v-on="on"
            >
              Dimensions
            </v-chip>
          </template>
          <span>click to edit</span>
        </v-tooltip>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <div v-if="displayConditionally(item)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                color="red"
                class="mr-3"
                @click="close"
              >
                mdi-window-close
              </v-icon>
              <v-icon color="red" @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
            <span>Close Editing</span>
          </v-tooltip>
        </div>
        <div v-else>
          <v-icon color="green" class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
        </div>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:body.append>
        <tr>
          <th class="text-center">
            <h3>
              TOTAL
              {{ documentType !== "Store Requisition" ? "AMOUNT" : "QUANTITY" }}
            </h3>
          </th>
          <th><h3></h3></th>
          <th><h3></h3></th>
          <th><h3></h3></th>
          <th><h3></h3></th>
          <th class="text-center" v-if="documentType !== 'Store Requisition'">
            <h3>{{ requisitionTotal | currencyFormat }}</h3>
          </th>
          <th class="text-center" v-else>
            <h3>{{ totalQuantity }}</h3>
          </th>
        </tr>
      </template>
    </v-data-table>
    <DimensionsDialog
      :itemDialog="itemDialog"
      :selectedBillingItem="billingItem"
      :formData="formData"
      v-on:close-dialog="closeDialog"
      :isEdit="isEdit"
      :documentType="documentType"
      :locations="locations"
      :requisition="requisition"
      :dimensionValues="dimensionValues"
      :readOnlyCheck="readOnlyCheck"
      @updateDimensions="updateDimensions"
    />
  </v-card>
</template>
<script>
import DimensionsDialog from "./ReqDimensionsDialog.vue";
export default {
  name: "BillingItems",
  components: {
    DimensionsDialog,
  },
  props: {
    selectedBillingItem: Object,
    formData: Object,
    isEdit: Boolean,
    documentType: String,
    locations: Array,
    requisition: Object,
    isView: Boolean,
    readOnlyCheck: Boolean,
    settings: Object,
    billingItems: {
      type: Array,
      default: () => [],
    },
    dimensionValues: {
      type: Array,
      default: () => [],
    },
    currencyCode: {
      type: String,
      default: "KES",
    },
  },
  data() {
    return {
      search: "",
      amount: 0,
      rules: {
        required: (value) => !!value || "Required.",
        minValue: (value) => value > 0 || "Field cannot be less than 0",
      },
      itemDialog: false,
      billingItemIndex: -1,
      billingItem: {
        id: 0,
        code: "",
        quantity: "",
        unitCost: "",
        unitOfMeasure: "",
        description: "",
        documentNo: "",
        fundNo: "",
        dimensionSpeedkeyCode: "",
        globalDimension1Code: "",
        globalDimension2Code: "",
        globalDimension3Code: "",
        globalDimension4Code: "",
        globalDimension5Code: "",
        globalDimension6Code: "",
        globalDimension7Code: "",
        globalDimension8Code: "",
        locationCode: "",
        amount: "",
      },
      defaultBillingItem: {
        id: 0,
        code: "",
        quantity: 1,
        unitCost: 1,
        description: "",
        unitOfMeasure: "",
        locationCode: "",
        fundNo: "",
        dimensionSpeedkeyCode: "",
        globalDimension1Code: "",
        globalDimension2Code: "",
        globalDimension3Code: "",
        globalDimension4Code: "",
        globalDimension5Code: "",
        globalDimension6Code: "",
        globalDimension7Code: "",
        globalDimension8Code: "",
      },
    };
  },
  computed: {
    /**
     * Total amount of the requisition
     */
    requisitionTotal() {
      return this.formData.requisitionLines.reduce((acc, a) => {
        return parseFloat(acc) + parseFloat(Number(a.quantity * a.unitCost));
      }, 0);
    },
    totalQuantity() {
      return this.formData.requisitionLines.reduce((a, b) => {
        return Number(a) + Number(b.quantity);
      }, 0);
    },
    billingItemsList: {
      get() {
        return this.formData.requisitionLines;
      },
      set(val) {
        return val;
      },
    },
    setDialog: {
      get() {
        return this.itemDialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
    unitsOfMeasure() {
      return this.$store.getters["Requisition/reqGetters"]("unitsOfMeasure");
    },
    routeParams() {
      return this.$route.query.code;
    },
    headers: function () {
      return [
        {
          text: "Code",
          value: "code",
          sortable: false,
          align: "center",
        },
        {
          text: "Description",
          value: "description",
          sortable: false,
          align: "center",
        },
        {
          text: "Quantity",
          value: "quantity",
          sortable: false,
          align: "center",
        },
        {
          text: "Unit of Measure",
          value: "unitOfMeasure",
          sortable: false,
          align: "center",
        },
        {
          text:
            this.documentType === "Store Requisition" ||
            this.requisition.documentType === "Store Requisition"
              ? "Location"
              : `Unit Cost (${this.defaultCurrencyCaption})`,
          sortable: true,
          value:
            this.documentType === "Store Requisition" ||
            this.requisition.documentType === "Store Requisition"
              ? "locationCode"
              : "unitCost",
          align: "center",
        },
        {
          text:
            this.documentType === "Store Requisition" ||
            this.requisition.documentType === "Store Requisition"
              ? ""
              : `Amount (${this.defaultCurrencyCaption})`,
          sortable: true,
          value:
            this.documentType === "Store Requisition" ||
            this.requisition.documentType === "Store Requisition"
              ? ""
              : "amount",
          align: "center",
        },
        {
          text:
            this.displayBudget === true
              ? `budgetMTD (${this.currencyCode})`
              : "",
          sortable: true,
          value: this.displayBudget === true ? "budgetMTD" : "",
          align: "center",
        },
        {
          text:
            this.displayBudget === true
              ? `budgetYTD (${this.currencyCode})`
              : "",
          sortable: true,
          value: this.displayBudget === true ? "budgetYTD" : "",
          align: "center",
        },

        {
          text: "Dimensions",
          value: "dimensions",
          sortable: false,
          align: "center",
        },
        {
          text: this.readOnlyCheck ? null : "Actions",
          value: this.readOnlyCheck ? null : "actions",
          sortable: false,
          width: "100px",
          align: "center",
        },
      ];
    },
    hideButtons: function () {
      if (
        this.isView &&
        (this.requisition.status === "Released" ||
          this.requisition.status === "Pending")
      ) {
        return false;
      } else {
        return true;
      }
    },
    displayBudget() {
      return this.settings ? this.settings.show_budget_balances : null;
    },
    defaultCurrencyCaption() {
      return this.settings
        ? this.settings.default_currency_caption
        : this.currencyCode;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Requisition/getBillingItems");
    });
  },
  methods: {
    autosaveBillingItem() {
      if (this.billingItemIndex > -1) {
        if (this.documentType === "Store Requisition") {
          this.billingItem.unitCost = 1;
        }
        this.billingItem.code = this.billingItem.billingItemCode;
        this.billingItem.documentType = this.formData.documentType;
        if (this.isView) {
          this.billingItem.documentType = this.formData.documentType;
          this.billingItem.documentNo = this.formData.no;
        } else {
          delete this.billingItem.documentType;
          delete this.billingItem.documentNo;
        }
        Object.assign(
          this.billingItemsList[this.billingItemIndex],
          this.billingItem
        );
      }
    },
    displayConditionally(item) {
      if (item !== null) {
        if ("lineNo" in item) {
          return item.lineNo === this.billingItem.lineNo;
        } else if ("id" in item) {
          return item.id === this.billingItem.id;
        }
      }
      return false;
    },
    editItem(item) {
      delete item.amount;
      delete item.documentDate;
      delete item.unitCostLCY;
      this.billingItemIndex = this.billingItemsList.indexOf(item);
      this.billingItem = Object.assign({}, { ...item });
    },
    deleteItem: function (item) {
      if ("lineNo" in item) {
        this.$confirm.show({
          text: "Are you sure you want to remove this Item?",
          onConfirm: () => {
            this.$store.dispatch("Requisition/deleteBillingItem", item);
            for (const i in this.formData.requisitionLines) {
              if (this.formData.requisitionLines[i].lineNo === item.lineNo) {
                this.formData.requisitionLines.splice(i, 1);
                break;
              }
            }
          },
        });
      } else {
        for (const i in this.formData.requisitionLines) {
          if (
            this.formData.requisitionLines[i].billingItemCode ===
            item.billingItemCode
          ) {
            this.formData.requisitionLines.splice(i, 1);
            break;
          }
        }
      }
    },
    closeDialog: function (val) {
      this.itemDialog = val;
    },
    close() {
      setTimeout(() => {
        this.billingItem = Object.assign({}, this.defaultBillingItem);
        this.billingItemIndex = -1;
      }, 300);
    },
    addNew() {
      const addObj = Object.assign({}, this.defaultBillingItem);
      addObj.id = this.billingItemsList.length + 1;
      this.billingItemsList.unshift(addObj);
      this.editItem(addObj);
      this.billingItem.locationCode = this.formData.locationCode;
      this.billingItem.globalDimension1Code =
        this.formData.globalDimension1Code;
      this.billingItem.globalDimension2Code =
        this.formData.globalDimension2Code;
      this.billingItem.globalDimension3Code =
        this.formData.globalDimension3Code;
      this.billingItem.globalDimension4Code =
        this.formData.globalDimension4Code;
      this.billingItem.globalDimension5Code =
        this.formData.globalDimension5Code;
      this.billingItem.globalDimension6Code =
        this.formData.globalDimension6Code;
      this.billingItem.globalDimension7Code =
        this.formData.globalDimension7Code;
      this.billingItem.globalDimension8Code =
        this.formData.globalDimension8Code;
      this.billingItem.fundNo = this.formData.fundNo;
      this.billingItem.dimensionSpeedkeyCode =
        this.formData.dimensionSpeedkeyCode;
    },
    selectBillingItemCode: function () {
      var values = this.billingItems.map(function (o) {
        return o.code;
      });
      var index = values.indexOf(this.billingItem.billingItemCode);
      this.billingItem.description = this.billingItems[index].description;
      this.billingItem.unitOfMeasure = this.billingItems[index].unitOfMeasure;
    },
    addDimensions(item) {
      this.itemDialog = true;
      delete item.amount;
      delete item.documentDate;
      delete item.unitCostLCY;
      this.billingItemIndex = this.billingItemsList.indexOf(item);
      if (!this.isEdit) {
        this.billingItem = Object.assign({}, { ...item });
      }
    },
    assignUnitCost(val, index) {
      this.billingItemsList[index].unitCost = val;
    },
    assignQuantity(val, index) {
      this.billingItemsList[index].quantity = val;
    },
    updateDimensions(val) {
      if ("lineNo" in val) {
        Object.assign(
          this.billingItemsList[
            this.billingItemsList.findIndex((el) => el.lineNo === val.lineNo)
          ],
          this.billingItem
        );
      } else if ("id" in val) {
        Object.assign(
          this.billingItemsList[
            this.billingItemsList.findIndex((el) => el.id === val.id)
          ],
          this.billingItem
        );
      }
    },
  },
};
</script>

<style>
.theme--light.v-data-table.v-data-table--fixed-header thead th {
  /* background: #555; */
  color: #fff;
}
.w-100 {
  width: 100%;
}
</style>
