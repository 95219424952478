import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('page-header',{attrs:{"title":"Leave Planner Application Reviews","path":_vm.$router.currentRoute.meta.breadcrumb}}),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('div',[_c(VTextField,{attrs:{"prepend-inner-icon":"filter_alt","solo":"","dense":"","label":"filter"},model:{value:(_vm.listFilter),callback:function ($$v) {_vm.listFilter=$$v},expression:"listFilter"}})],1)]),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('div',{staticStyle:{"margin-left":"17%"}},[_c(VBtn,{staticClass:"mr-4",attrs:{"outlined":"","disabled":!_vm.isCalender,"color":"primary"},on:{"click":function($event){_vm.isCalender = false}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("view_day")]),_vm._v(" list ")],1),_c(VBtn,{staticClass:"mr-4",attrs:{"outlined":"","disabled":_vm.isCalender,"color":"primary"},on:{"click":function($event){_vm.isCalender = true}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("date_range")]),_vm._v(" Calendar ")],1)],1)])],1),_c('div',{staticStyle:{"height":"100%"}},[(_vm.isCalender)?_c('LeavePlanReviewerCalendar',{attrs:{"leavePlannerApprovals":_vm.leavePlannerApprovals}}):_c('LeavePlanReviewerList',{attrs:{"leavePlannerApprovals":_vm.leavePlannerApprovals}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }