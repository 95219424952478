<template>
  <v-dialog v-model="dialog" persistent :width="isRespond ? '75%' : '62%'">
    <v-card class="mx-auto" flat>
      <v-card-title class="disc--title">
        {{
          isRespond
            ? `Respond to Disciplinary case raised by ${
                disciplinary.raisedByName ? disciplinary.raisedByName : ""
              }`
            : "Add Accusations"
        }}
        <v-spacer />
        <v-chip
          v-if="$route.params.no"
          :class="`${getColor(disciplinary.status)}`"
        >
          Status: {{ disciplinary.status }}
        </v-chip>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-form v-model="isValid" ref="discForm">
          <v-autocomplete
            :items="employees"
            placeholder="Select Accused Employee"
            persistent-placeholder
            :rules="[rules.required]"
            label="Accused Employee"
            clearable
            outlined
            item-text="first_name"
            item-value="number"
            :disabled="!isEdit || isRespond"
            v-model="formData.accused"
            class="mt-3"
          >
            <template v-slot:prepend-inner>
              <v-icon color="primary"> how_to_reg </v-icon>
            </template>
            <template v-slot:item="{ item, on, attrs }">
              <v-list-item ripple v-on="on" v-bind="attrs">
                <v-avatar
                  color="primary lighten-4"
                  size="30"
                  class="primary--text text--darken-4 mr-3"
                >
                  {{ `${item.first_name[0] || ""}${item.last_name[0] || ""}` }}
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <span>
                      {{ item.first_name + " " + item.last_name }}
                      <v-chip
                        left
                        x-small
                        color="primary lighten-4"
                        class="primary--text text--darken-4 mx-2"
                      >
                        {{ item.number }}
                      </v-chip>
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip
                    style="position: absolute; right: 1em; top: 1em"
                    small
                    label
                    color="primary lighten-4"
                  >
                    {{ item.job_title }}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-form>
        <DisciplinaryLines
          :disciplinaryLine="formData.disciplinaryLine"
          :isEdit="isEdit"
          :isRespond="isRespond"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions class="disc--actions">
        <v-btn
          class="deep-orange text-capitalize lighten-4 deep-orange--text text--darken-4"
          elevation="0"
          @click="$router.back()"
        >
          <v-icon class="mx-2">mdi-close</v-icon>
          Close
        </v-btn>
        <v-spacer />
        <v-btn
          class="primary text-capitalize"
          elevation="0"
          v-if="isEdit && !isRespond"
          @click="saveDisciplinary()"
        >
          <v-icon left>mdi-check</v-icon>
          Save
        </v-btn>
        <v-btn class="primary text-capitalize" elevation="0" @click="respond()">
          <v-icon left>waving_hand</v-icon>
          Respond
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import DisciplinaryLines from "./DisciplinaryLines.vue";
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
import { helper } from "@kinetics254/cassandra-base/utilities";
export default {
  name: "DisciplinaryCard",
  components: {
    DisciplinaryLines,
  },
  data() {
    return {
      formData: {
        accused: "",
        disciplinaryLine: [],
      },
      dialog: true,
      isValid: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Disc/getDisciplinaryCategories");
      if (to.params.no) {
        v.$store.dispatch("Disc/getDisciplinary", { no: atob(to.params.no) });
      }
    });
  },
  computed: {
    user() {
      return AuthService.user;
    },
    employees() {
      return this.$store.getters["Setup/setupGetter"]("leaveEmployees").filter(
        (e) => (this.isEdit ? e.number !== this.user.employee : true)
      );
    },
    rules() {
      return {
        required: (v) => !!v || "This field is required",
      };
    },
    disciplinary() {
      return this.$store.getters["Disc/discGetters"]("disciplinary");
    },
    isEdit() {
      return this.$route.params.no
        ? this.disciplinary
          ? this.disciplinary.status === "Open"
          : false
        : true;
    },
    isRespond() {
      return this.$route.params.no && this.$route.query.stage ? true : false;
    },
  },
  methods: {
    saveDisciplinary() {
      if (!this.isValid) {
        this.$refs.discForm.validate();
      } else {
        const data = { ...this.formData };
        data.raisedBy = this.user.employee;
        if (this.$route.params.no) {
          this.$store.dispatch("Disc/updateDisciplinary", data);
        } else {
          this.$store.dispatch("Disc/addDisciplinary", data);
        }
      }
    },
    getColor(status) {
      return helper.getColor(status).color;
    },
    deleteDisciplinary() {
      this.$confirm.show({
        title: "Delete Disciplinary",
        text: "Are you sure you want to delete this disciplinary?",
        onConfirm: () => {
          this.$store.dispatch("Disc/deleteDisciplinary", {
            ...this.disciplinary,
          });
        },
      });
    },
    respond() {
      this.$confirm.show({
        title: "Respond to Disciplinary",
        text: "Are you sure you want to respond to this disciplinary?",
        onConfirm: () => {
          this.$store.dispatch("Disc/respondDisciplinary", {
            ...this.formData,
          });
        },
      });
    },
  },
  watch: {
    disciplinary: {
      handler(val) {
        if (val) {
          this.formData = { ...val };
        }
      },
    },
  },
};
</script>
<style>
@import url("../styles.css");
</style>
