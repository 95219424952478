<template>
  <v-container fluid>
    <v-card class="mx-auto" flat elevation="0">
      <v-card-title>
        Appraisal scores <v-spacer />

        <span class="ml-0 text-body-1">
          Application:
          <span class="font-weight-bold">{{ $route.params.code }}</span></span
        >
        <v-spacer />
        <span class="text-body-1"> Status:</span>
        <v-chip
          class="mr-5"
          small
          dark
          :color="statusColor"
          v-if="appraisalResultDoc"
        >
          {{ appraisalResultDoc.ownerResponse }}
        </v-chip>
        <v-btn
          class="mx-2 text-capitalize deep-orange lighten-4 deep-orange--text text--darken-4"
          elevation="0"
          small
          @click="GoBack()"
        >
          close
          <v-icon class="mx-2">mdi-close</v-icon>
        </v-btn>
        <v-btn
          small
          color="primary"
          class="mx-2 white--text"
          tile
          link
          @click="openPdf = true"
        >
          <!--          @click="downloadFile(appraisalResultDoc.code)"-->
          <v-icon left color="white"> mdi-download </v-icon>
          report
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-title>
        <v-btn
          color="teal"
          class="ml-2 white--text"
          @click="
            $router.push({
              name: 'committeeReport',
              params: {
                code: appraisalResultDoc.code,
              },
            })
          "
          v-if="
            reviewdocumentStage &&
            !reviewdocumentStage.assignCommittee &&
            reviewdocumentStage.actionRecommendations &&
            !reviewdocumentStage.assignFinalScore &&
            !reviewdocumentStage.isCommitteeStage &&
            reviewdocumentStage.recommend
          "
          >committee report
        </v-btn>
        <template
          v-if="
            reviewdocumentStage &&
            reviewdocumentStage.actionRecommendations &&
            !reviewdocumentStage.recommend &&
            !reviewdocumentStage.assignFinalScore &&
            !reviewdocumentStage.assignCommittee &&
            !reviewdocumentStage.isCommitteeStage
          "
        >
          <h4 style="color: '#00448B' !important">
            Review decision:
            <v-chip :color="reviewDecisionColors">{{
              appraisalResultDoc.reviewDecision
            }}</v-chip>
          </h4>
        </template>
        <v-spacer />
        <template
          v-if="
            reviewdocumentStage &&
            reviewdocumentStage.recommend &&
            !reviewdocumentStage.isCommitteeStage &&
            !reviewdocumentStage.assignCommittee &&
            !reviewdocumentStage.assignFinalScore &&
            !reviewdocumentStage.actionRecommendations
          "
        >
          <v-btn
            color="indigo"
            class="ml-2 white--text"
            @click="ceoSetReviewed"
          >
            <v-icon dark class="mr-2">mdi-printer-search</v-icon>mark as
            reviewed
          </v-btn>
          <v-btn color="orange" class="ml-2 white--text" @click="putOnPiP">
            <v-icon dark class="mr-2">mdi-hammer-wrench</v-icon>put on pip
          </v-btn>
          <v-btn
            color="primary"
            class="ml-2 white--text"
            @click="setrecommendationDialog"
          >
            <v-icon dark class="mr-2">mdi-creation</v-icon>reward
          </v-btn>
        </template>
        <template
          v-if="
            reviewdocumentStage &&
            !reviewdocumentStage.recommend &&
            !reviewdocumentStage.isCommitteeStage &&
            !reviewdocumentStage.assignCommittee &&
            !reviewdocumentStage.assignFinalScore &&
            reviewdocumentStage.actionRecommendations
          "
        >
          <v-btn
            color="secondary"
            class="ml-3 white--text"
            @click="rejectRecommendation"
            v-if="appraisalResultDoc.recommendationStatus !== 'Approved'"
          >
            <v-icon dark class="mr-2">mdi-cancel</v-icon> reject recommendation
          </v-btn>
          <v-btn
            color="primary"
            class="ml-3 white--text"
            @click="approveRecommendations"
            v-if="appraisalResultDoc.recommendationStatus !== 'Approved'"
          >
            <v-icon dark class="mr-2">mdi-snowflake-check</v-icon>approve
            recommendation
          </v-btn>
        </template>
        <v-btn
          color="teal"
          class="ml-2 white--text"
          @click="createCommittee"
          v-if="
            reviewdocumentStage &&
            reviewdocumentStage.assignCommittee &&
            !reviewdocumentStage.actionRecommendations &&
            !reviewdocumentStage.assignFinalScore &&
            !reviewdocumentStage.isCommitteeStage &&
            !reviewdocumentStage.recommend
          "
        >
          <v-icon dark class="mr-2">mdi-account-group</v-icon>assign committee
        </v-btn>
      </v-card-title>
      <v-row>
        <v-col cols="4" sm="12" md="6" lg="4">
          <div class="pl-4">
            <v-progress-circular
              :value="score"
              :rotate="360"
              :width="20"
              :size="150"
              :color="color"
            >
              <div>
                <span style="display: block; font-size: 1.5rem">
                  {{ score }}%
                </span>
              </div>
            </v-progress-circular>

            <span
              style="
                display: block;
                font-size: 1rem;
                color: green;
                margin-top: 1rem;
              "
            >
              {{ appraisalResultDoc.scoreClassification }}
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card elevation="0">
            <v-row v-if="appraisalResultDoc">
              <v-col cols="4" sm="12" md="6" lg="4">
                <v-card-title class="font-weight-bold"
                  >Employee Information
                </v-card-title>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon color="success" size="30">mdi-account</v-icon>
                        <span class="font-weight-regular">{{
                          appraisalResultDoc.employeeName
                        }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon color="success" size="30"
                          >mdi-badge-account-alert</v-icon
                        >
                        <span class="font-weight-regular">
                          {{ appraisalResultDoc.employeeJobTitle }}
                        </span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon color="success" size="20"
                          >mdi-email-multiple</v-icon
                        >
                        <span class="font-weight-regular">
                          {{ appraisalResultDoc.employeeEmail }}
                        </span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <div class="">
                  <v-card-title class="font-weight-bold"
                    >Employee&apos;s remarks</v-card-title
                  >
                  <v-card-text class="mb-4">
                    {{ appraisalResultDoc.empRemarks }}</v-card-text
                  >
                </div>
                <div class="">
                  <v-card-title class="font-weight-bold"
                    >Employee&apos;s disagreement reason</v-card-title
                  >
                  <v-card-text class="mb-4">
                    {{ appraisalResultDoc.disagreementReason }}</v-card-text
                  >
                </div>
              </v-col>
              <v-col cols="4" sm="12" md="6" lg="4">
                <v-card-title class="font-weight-bold"
                  >First Reviewer Information
                </v-card-title>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon color="success" size="30">mdi-account</v-icon>
                        <span class="ml-3 mr-3">{{
                          appraisalResultDoc.reviewerNames
                        }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon color="success" size="30"
                          >mdi-badge-account-alert</v-icon
                        >
                        <span class="ml-3 mr-3">{{
                          appraisalResultDoc.reviewerJobTitle
                        }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon color="success" size="30"
                          >mdi-email-multiple</v-icon
                        >
                        <span class="ml-3 mr-3">{{
                          appraisalResultDoc.reviewerEmail
                        }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <div class="">
                  <v-card-title class="font-weight-bold"
                    >First Reviewer Comment</v-card-title
                  >
                  <v-card-text class="mb-4">
                    {{ appraisalResultDoc.appraiserRemarks }}</v-card-text
                  >
                </div>
              </v-col>
              <v-col v-if="!hasSecondReview" cols="4" sm="12" md="6" lg="4">
                <v-card-title class="font-weight-bold"
                  >Second Reviewer Information
                </v-card-title>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon color="success" size="30">mdi-account</v-icon>
                        <span class="ml-3 mr-3">{{
                          appraisalResultDoc.reviewerNames2
                        }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon color="success" size="30"
                          >mdi-badge-account-alert</v-icon
                        >
                        <span class="ml-3 mr-3">{{
                          appraisalResultDoc.reviewerJobTitle2
                        }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon color="success" size="30"
                          >mdi-email-multiple</v-icon
                        >
                        <span class="ml-3 mr-3">{{
                          appraisalResultDoc.reviewerEmail2
                        }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <div class="">
                  <v-card-title class="font-weight-bold"
                    >Second Reviewer Comment</v-card-title
                  >
                  <v-card-text class="mb-4">
                    {{ appraisalResultDoc.secondReviewerComment }}
                  </v-card-text>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-divider />
      <v-row v-if="appraisalResultDoc"> </v-row>
      <v-row flex>
        <v-col
          cols="6"
          sm="12"
          md="6"
          lg="6"
          v-if="appraisalDocumentRewards.length"
        >
          <v-card elevation="0">
            <v-card-title>
              <v-toolbar elevation="1">
                <v-toolbar-title>
                  <v-icon color="success" size="30" class="mr-4"
                    >mdi-creation</v-icon
                  >
                  <span color="primary" class="font-weight-bold"
                    >Employee rewards</span
                  >
                </v-toolbar-title>
              </v-toolbar>
            </v-card-title>
            <v-card-text class="py4">
              <v-data-table
                :headers="rewardsHeaders"
                :items="appraisalDocumentRewards"
              >
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="6"
          sm="12"
          md="6"
          lg="6"
          v-if="documentCommitteeMembers.length"
        >
          <v-card elevation="0">
            <v-card-title>
              <v-toolbar elevation="1">
                <v-toolbar-title>
                  <v-icon color="success" size="30" class="mr-3"
                    >mdi-account-group</v-icon
                  >
                  <span color="primary" class="font-weight-bold">{{
                    getCommitteeCode
                  }}</span>
                  Committee Members
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="!isCommitteeAssigned"
                  class="blue-darken-4"
                  color="info"
                  @click.native="addCommitteeMember"
                >
                  <v-icon>mdi-plus</v-icon>
                  <span>Add Committee Member</span>
                </v-btn>
              </v-toolbar>
            </v-card-title>
            <v-card-text class="py4">
              <v-data-table
                :headers="headers"
                :items="documentCommitteeMembers"
              >
                <template
                  v-slot:item.actions="{ item }"
                  v-if="
                    reviewdocumentStage &&
                    reviewdocumentStage.assignCommittee &&
                    !reviewdocumentStage.isCommitteeStage &&
                    !reviewdocumentStage.assignFinalScore &&
                    !reviewdocumentStage.recommend &&
                    !reviewdocumentStage.actionRecommendations
                  "
                >
                  <v-btn
                    color="secondary"
                    :disabled="ceoReviewed"
                    @click.native="deleteCommitteMember(item)"
                  >
                    <v-icon class="mr-1">mdi-delete-forever</v-icon>
                    <span>Delete</span>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="currentReview" no-gutters>
        <v-col cols="12">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">self Score</th>
                  <th class="text-left">reviewer Score</th>
                  <th
                    v-if="currentReview.scores.committeeScoreAvg"
                    class="text-left"
                  >
                    committee Score
                  </th>
                  <th class="text-left">overall Score</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ getScore(currentReview.scores.selfScoreAvg) }}%</td>
                  <td>
                    {{ getScore(currentReview.scores.reviewerScoreAvg) }}%
                  </td>
                  <td v-if="currentReview.scores.committeeScoreAvg">
                    {{ getScore(currentReview.scores.committeeScoreAvg) }}%
                  </td>
                  <td>{{ getScore(currentReview.scores.overallScore) }}%</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card>
    <PerfReward />
    <AppraisalCommittee />
    <AddToCommitteDialog :committeeCode="getCommitteeCode" />

    <v-dialog v-model="openPdf" persistent>
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="openPdf = false"
            >Cancel</v-btn
          >
        </v-card-actions>
        <v-card-text>
          <pdf
            :src="`data:application/pdf;base64,${reviewReport}`"
            :page="page"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
import PerfReward from "./PerfReward.vue";
import AppraisalCommittee from "./AppraisalCommittee.vue";
import AddToCommitteDialog from "./AddToCommitteDialog.vue";
import AppraisalMixin from "../AppraisalMixin";
import pdf from "vue-pdf";

export default {
  name: "ResultCard",
  components: {
    pdf,
    PerfReward,
    AppraisalCommittee,
    AddToCommitteDialog,
  },
  mixins: [AppraisalMixin],
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Result/getReviewDocumentStages");
      v.$store.dispatch("Result/getAppraisalDocument", {
        docNo: v.$route.params.code,
        period: v.$route.params.period,
      });
      v.$store.dispatch("Result/getAppraisalRewards");
      v.$store.dispatch("Result/getAppraisalCommittees");
      v.$store.dispatch("Result/getAppraisalDocumentCommitteeMembers", {
        code: v.$route.params.code,
      });
      v.$store.dispatch("Result/getAppraisalDocumentRewards", {
        reviewId: v.$route.params.code,
      });
      v.$store.dispatch("Result/getLeaveEmployees");
      v.$store.dispatch("Result/getDocumentKpis", {
        reviewID: v.$route.params.code,
      });
      v.$store.dispatch("appraisal/getPeriods");
      v.$store.dispatch("appraisal/getReviews");
      v.$store.dispatch("appraisal/getCeoAppraisalReport", {
        reviewNo: v.$route.params.code,
      });
    });
  },
  data: function () {
    return {
      search: "",
      values: [],
      applicationLines: [],
      expanded: [],
      defaultObjectiveItem: {
        ObjectiveCode: "",
        kpiCode: "",
        weight: 0,
        target: 0,
      },
      formData: {
        objectiveCode: "",
        kpiCode: "",
        kpiDescription: "",
        weight: 0,
        target: 0,
        id: 0,
      },

      kpiHeaders: [
        { text: "Code", align: "", sortable: true, value: "reviewID" },
        {
          text: "Employee Number",
          align: "",
          sortable: true,
          value: "employeeNo",
        },
        { text: "Stage", align: "", sortable: true, value: "stage" },
        {
          text: "Employee Score",
          align: "center",
          sortable: true,
          value: "selfRatingScore",
        },
        {
          text: "Supervisor Score",
          align: "center",
          sortable: true,
          value: "reviewerRatingScore",
        },
        {
          text: "Actual Score",
          align: "center",
          sortable: true,
          value: "actualScore",
        },
        { text: "Weight", align: "", sortable: true, value: "weight" },
        { text: "Actions", value: "action" },
      ],

      scoreHeaders: [
        {
          text: "committeeScoreAvg",
          align: "",
          sortable: true,
          value: "committeeScoreAvg",
        },
        {
          text: "overallScore",
          align: "",
          sortable: true,
          value: "overallScore",
        },
        {
          text: "reviewerScoreAvg",
          align: "center",
          sortable: true,
          value: "reviewerScoreAvg",
        },
        {
          text: "selfScoreAvg",
          align: "",
          sortable: true,
          value: "selfScoreAvg",
        },
      ],
      reviewReport: null,
      openPdf: false,
      page: 1,
    };
  },
  mounted() {
    Event.$on("report-doc", (report) => {
      this.reviewReport = report;
    });
  },
  computed: {
    appraisalDocumentRewards: {
      get() {
        return this.$store.getters["Result/appraisalResultGetter"](
          "appraialDocumentRewards"
        );
      },
    },
    documentCommitteeMembers: {
      get() {
        return this.$store.getters["Result/appraisalResultGetter"](
          "appraisalDocumentCommitteeMembers"
        );
      },
    },
    getCommitteeCode: {
      get() {
        return this.documentCommitteeMembers[0]?.committeeCode;
      },
    },
    headers: {
      get() {
        return [
          {
            text: "Committee member Name",
            align: "start",
            sortable: false,
            value: "employeeName",
          },
          {
            text: "Employee Number",
            align: "start",
            sortable: false,
            value: "employeeNo",
          },

          { text: "Actions", value: "actions", sortable: false },
        ];
      },
    },
    documentKpis() {
      return this.$store.getters["Result/appraisalResultGetter"](
        "appraisalDocumentKpis"
      );
    },
    rewardsHeaders: {
      get() {
        return [
          {
            text: "Reward Description",
            align: "start",
            sortable: false,
            value: "rewardDesc",
          },
          {
            text: "Reward Value",
            align: "start",
            sortable: false,
            value: "rewardValue",
          },
          { text: "Actions", value: "actions", sortable: false },
        ];
      },
    },
    appraisalResultDoc() {
      return this.$store.getters["Result/appraisalResultGetter"](
        "appraisalResultDoc"
      );
    },
    hasSecondReview() {
      return this.appraisalResultDoc?.reviewerNames2 === "";
    },
    statusColor: {
      get() {
        return this.appraisalResultDoc?.ownerDisagrees ? "error" : "success";
      },
    },
    color: {
      get() {
        const score = this.appraisalResultDoc?.overallScore;
        if (score >= 75) {
          return "success";
        }
        if (score >= 50) {
          return "orange";
        }
        return "error";
      },
    },
    score: {
      get() {
        return this.appraisalResultDoc?.overallScore;
      },
    },

    userData() {
      return AuthService.user;
    },
    appraisalCommittees: {
      get() {
        return this.$store.getters["Result/appraisalResultGetter"](
          "appraisalCommittees"
        );
      },
    },
    ceoReviewed() {
      return ["Approved", "Marked for PIP", "Rewarded"].includes(
        this.appraisalResultDoc.reviewDecision
      );
    },

    periods() {
      return this.$store.getters["appraisal/periods"];
    },
    currPeriod() {
      return this.periods.filter((p) => p.current).shift();
    },

    currentReview() {
      const reviews = this.$store.getters["appraisal/reviews"];
      return this.currPeriod
        ? reviews.filter((f) => f.period === this.currPeriod.code).shift()
        : null;
    },

    isCommitteeAssigned() {
      return this.appraisalResultDoc.assignedCommittee !== "";
    },
    reviewDocumentStages() {
      return this.$store.getters["Result/appraisalResultGetter"](
        "reviewDocumentStages"
      );
    },
    reviewdocumentStage: {
      get() {
        return this.reviewDocumentStages
          .filter((f) => f.code === this.appraisalResultDoc.currentReviewStage)
          .shift();
      },
    },
    reviewDecisionColors: {
      get() {
        return this.appraisalResultDoc.reviewDecision
          ? this.appraisalResultDoc.reviewDecision === "Rewarded"
            ? "primary"
            : this.appraisalResultDoc.reviewDecision === "Marked for PIP"
            ? "orange"
            : this.appraisalResultDoc.reviewDecision === "Approved"
            ? "indigo"
            : "secondary"
          : "";
      },
    },
    rejectionConfirmationPhrase: {
      get() {
        return this.appraisalResultDoc.reviewDecision
          ? this.appraisalResultDoc.reviewDecision === "Rewarded"
            ? `reward ${this.appraisalResultDoc.employeeName}`
            : this.appraisalResultDoc.reviewDecision === "Marked for PIP"
            ? `put ${this.appraisalResultDoc.employeeName} on Performance Improvement Plan`
            : this.appraisalResultDoc.reviewDecision === "Approved"
            ? `approve review document for ${this.appraisalResultDoc.employeeName} as reviewed`
            : " recommend"
          : "";
      },
    },
  },
  methods: {
    rejectRecommendation() {
      this.$confirm.show({
        title: "Reject Appraisal Management Committee recommendation",
        text: `Are you sure you want to reject the recommendation to ${this.rejectionConfirmationPhrase} ?`,
        input: true,
        label: "Add Comment",
        onConfirm: (comment) => {
          this.$store.dispatch("Result/rejectAppraisalMgmntRecommendation", {
            reviewNo: this.appraisalResultDoc.code,
            employeeNo: this.userData?.profile?.number,
            rejectReason: comment,
            params: {
              provider: "GALAXY_CU",
              entity: "appraisalManagement_RejectRecommendation",
            },
          });
        },
      });
    },
    approveRecommendations() {
      this.$confirm.show({
        title: "Approve Appraisal Management Committee recommendation",
        text: `Are you sure you want to approve the recommendation to ${this.rejectionConfirmationPhrase} ?`,
        input: false,
        label: "Add Comment",
        onConfirm: () => {
          this.$store.dispatch("Result/approveAppraisalMgmntRecommendation", {
            reviewNo: this.appraisalResultDoc.code,
            employeeNo: this.userData?.profile?.number,
            params: {
              provider: "GALAXY_CU",
              entity: "appraisalManagement_ApproveRecommmendation",
            },
          });
        },
      });
    },
    ceoSetReviewed() {
      this.$confirm.show({
        title: "Set Appraisal as Reviewed",
        text: "Are you sure you want to set Appraisal as Reviewed?",
        input: true,
        label: "Add Comment",
        onConfirm: (comment) => {
          this.$store.dispatch("Result/ceoSetReviewed", {
            reviewNo: this.$route.params.code,
            employeeNo: this.userData?.profile?.number,
            perfomanceFeedback: comment,
            params: {
              provider: "GALAXY_CU",
              entity: "appraisalManagement_SetAsReviewed",
            },
          });
          this.dialog = false;
        },
      });
    },
    addCommitteeMember() {
      this.$store.commit("Result/MUTATE", {
        state: "addCommitteeMemberDialog",
        data: true,
      });
    },
    setrecommendationDialog() {
      this.$store.commit("Result/MUTATE", {
        state: "perfRewardDialog",
        data: true,
      });
    },
    putOnPiP() {
      const data = {
        employeeNo: this.userData?.profile?.number,
        reviewNo: this.$route?.params?.code,
        perfomanceFeedback: "",
        params: {
          provider: "GALAXY_CU",
          entity: "appraisalManagement_MarkForPIP",
        },
      };

      const params = {
        title: ` Document: ${this.$route?.params?.code}`,
        text: `Are you sure you want to put ${this.appraisalResultDoc.employeeName} on Perfomance Improvement Plan?`,
        input: true,
        label: "Add Comment",
        onConfirm: (comment) => {
          data.perfomanceFeedback = comment;
          this.$store.dispatch("Result/pupOnPIP", data);
        },
      };
      this.$confirm.show(params);
    },
    createCommittee() {
      this.$store.commit("Result/MUTATE", {
        state: "appraisalCommitteeDialog",
        data: true,
      });
    },
    deleteCommitteMember(item) {
      const { employeeName, ...data } = item;

      const params = {
        title: ` Delete : ${employeeName}`,
        text: `Are you sure you want to delete ${employeeName} from this committee?`,
        onConfirm: () => {
          this.$store.dispatch("Result/deleteCommitteeMember", data);
        },
      };
      this.$confirm.show(params);
    },
    GoBack() {
      this.$router.push({ name: "ResultList" });
    },

    async downloadFile(item) {
      await this.$store.dispatch("appraisal/getAppraisalReport", {
        reviewNo: item,
      });
    },
  },
  watch: {
    ObjectiveLineswithParams: {
      handler: function () {
        this.applicationLines = [...this.ObjectiveLineswithParams];
      },
    },
    reviewdocumentStage: {
      handler: function (value) {
        if (value) {
          const canSeeDocumentInThissatge = value.stageReviewers.findIndex(
            (reviewer) => reviewer.employeeNo === this.userData.employee
          );
          if (canSeeDocumentInThissatge === -1) {
            this.$router.back();
          }
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.chat-bubble {
  background-color: rgba(128, 128, 128, 0.05);
  max-width: 600px;
  padding: 16px;
  border-radius: 10px 10px 10px 10px;
  position: relative;
  box-shadow: inset -5px 5px 10px rgba(0, 0, 0, 0.2);
}

.chat-bubble::before {
  content: "";
  position: absolute;
  top: 0;
  right: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 0 0;
  border-color: transparent transparent #fff #fff;
}
</style>
