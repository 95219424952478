export default {
  allocations: (params) => `hrmis/leave_allocation?${getParams(params)}`,
  leavePeriod: "hrmis/leave_period",
  leaveTypes: "hrmis/leave_types",
  approvers: "hrmis/leave_approver",
  ledgerEntries: (id) => `hrmis/leave_ledger_entries?employee_no=${id}`,
  handoverSetup: "hrmis/leave_application/handoversetup",
};

function getParams(params) {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
}
