<template>
  <v-container class="fade-transition">
    <AppraisalHeader class="mt-n1" />
    <v-card flat class="mt-3">
      <v-card-text>
        <span class="headline text-h6">My PIP Applications Lines</span>
        <v-row no-gutters>
          <v-col cols="12">
            <v-data-table
              :headers="applicationHeaders"
              :items="employeeApplications"
            >
              <template v-slot:item.id="{ item }">
                <span class="supervisor-review-table">{{
                  item["employeeNo"]
                }}</span>
              </template>
              <template v-slot:item.stage="{ item }">
                <span class="supervisor-review-table">{{ item["stage"] }}</span>
                <span class="mx-2">
                  <v-chip
                    v-show="item.status === 'Active'"
                    small
                    color="green"
                    dark
                  >
                    <span class="supervisor-review-table">{{
                      item.status
                    }}</span>
                  </v-chip>
                </span>
              </template>
              <template v-slot:item.employeeScore="{ item }">
                <span class="supervisor-review-table"
                  >{{ MyScore(item) }}%</span
                >
              </template>
              <template v-slot:item.supervisorScore="{ item }">
                <span class="supervisor-review-table"
                  >{{ SupervisorScore(item) }}%</span
                >
              </template>
              <template v-slot:item.overallScore="{ item }">
                <span class="supervisor-review-table"
                  >{{ OverallScore(item) }}%</span
                >
              </template>
              <template v-slot:item.documentStatus="{ item }">
                <v-chip
                  v-show="item.documentStatus"
                  small
                  :color="getColor(item.documentStatus).color"
                  dark
                >
                  <span class="supervisor-review-table">{{
                    item["documentStatus"]
                  }}</span>
                </v-chip>
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn-toggle>
                  <v-btn
                    small
                    color="primary"
                    class="mx-2 white--text"
                    tile
                    link
                    v-if="item.documentStatus === 'Reviewed'"
                    @click="downloadFile(item.code)"
                  >
                    <v-icon left color="white"> mdi-download </v-icon>
                    report
                  </v-btn>
                  <v-btn
                    small
                    color="primary"
                    tile
                    :to="{
                      name: 'Appraisal Card',
                      params: { code: item.code },
                    }"
                  >
                    View
                    <v-icon right color="white">mdi-arrow-right</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <!--END My reviews-->
    </v-card>
  </v-container>
</template>

<script>
import RouterMixin from "@kinetics254/cassandra-base/mixins/RouterMixin";
import AppraisalMixin from "../AppraisalMixin";
import AppraisalHeader from "./AppraisalHeader.vue";
import FileMixin from "@kinetics254/cassandra-base/mixins/FileMixin";

export default {
  name: "EmployeeApplication",
  mixins: [RouterMixin, AppraisalMixin, FileMixin],
  components: { AppraisalHeader },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("appraisal/getEmployeeApplications");
    });
  },
  data: function () {
    return {
      applicationHeaders: [
        { text: "Code", align: "", sortable: true, value: "documentNo" },
        { text: "is PIP", align: "", sortable: true, value: "isPIP" },
        {
          text: "group Code",
          align: "center",
          sortable: true,
          value: "groupCode",
        },
        {
          text: "Objective Code",
          align: "center",
          sortable: true,
          value: "objectiveCode",
        },
        { text: "target", align: "", sortable: true, value: "target" },
        { text: "weight", align: "", sortable: true, value: "weight" },
      ],
      selectedPeriod: "",
      period: "",
    };
  },
  computed: {
    document() {
      return this.$store.getters["appraisal/appraisalGetter"](
        "appraisalReport"
      );
    },
    employeeApplications() {
      if (this.period !== "") {
        return this.$store.getters["appraisal/employeeApplications"].filter(
          (a) => a.period === this.period
        )[0].generatedLines;
      } else {
        return this.$store.getters["appraisal/employeeApplications"][0]
          .generatedLines;
      }
    },
  },
  methods: {},
};
</script>

<style scoped>
@import "../styles/appraisal.css";
</style>
