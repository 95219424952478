export default {
  leaveTypes: (params) =>
    `hrmis/leave_planner/leave_types?${getParams(params)}`,
  leavePeriods: () => "hrmis/leave_planner/leave_periods",
  getPlanners: (params) => `hrmis/leave_planner?${getParams(params)}`,
  getPlannerLines: (params) =>
    `hrmis/leave_planner/get_planner_lines?${getParams(params)}`,
  getPlanner: (no) => `hrmis/leave_planner/get_planner/${no}`,
  savePlanner: "hrmis/leave_planner/save_planner",
  savePlannerLine: "hrmis/leave_planner/save_planner_line",
  deletePlannerLine: "hrmis/leave_planner/delete_planner_line",
  sendForApproval: "hrmis/leave_planner/send_for_approval",
  cancelApproval: "hrmis/leave_planner/cancel_approval",
  deletePlanner: "hrmis/leave_planner/delete_planner",

  leavePlannerApprovalEntries: (params) =>
    `hrmis/leave_planner/application-reviews?${getParams(params)}`,
  approve: "hrmis/leave_planner/approvals/approve ",
  reject: "hrmis/leave_planner/approvals/reject",
  getLeaveDaysBalances: (params) =>
    `hrmis/leave_planner/leave_days_balances?${getParams(params)}`,
};

function getParams(params) {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
}
