<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Today
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    class="primary lighten-4 mx-1"
                    v-bind="attrs"
                    v-on="on"
                    label
                    @click="
                      $router.push({
                        name: 'ViewLeavePlannerApplication',
                        params: {
                          no: selectedEvent.details.documentNo,
                        },
                      })
                    "
                  >
                    <v-icon color="primary darken-4"
                      >mdi-eye-arrow-right</v-icon
                    >
                  </v-chip>
                </template>
                <span>view details</span>
              </v-tooltip>
            </v-toolbar>
            <v-card-text>
              <!-- <span v-html="selectedEvent.details"></span> -->
            </v-card-text>
            <v-card-actions>
              <v-btn
                fab
                text
                color="red red-darken"
                @click="selectedOpen = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "LeavePlannerReviewersCalendar",
  props: {
    leavePlannerApprovals: { type: Array, default: () => [] },
  },
  data: () => ({
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    leaveColors: {
      ANNUAL: "green",
      SICK: "orange",
      MATERNITY: "blue",
      PATERNITY: "indigo",
      COMPASSION: "cyan",
      STUDY: "deep-purple",
    },
  }),
  mounted() {
    this.$refs.calendar.checkChange();
  },
  computed: {
    leavePlannerLines() {
      return this.leavePlannerApprovals?.flatMap(
        (obj) => obj.leavePlannerLines
      );
    },
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      const events = [];
      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const periodPlannerLines = this.leavePlannerLines.filter((planner) => {
        const plannerFromDate = new Date(planner.fromDate);
        const plannerToDate = new Date(planner.toDate);
        return (
          (plannerFromDate >= min && plannerFromDate <= max) ||
          (plannerToDate >= min && plannerToDate <= max)
        );
      });
      periodPlannerLines.forEach((line) => {
        events.push({
          name: `${line.employeeName} (${line.employeeNo}) - ${line.leaveType} Leave`,
          details: { ...line },
          start: line.fromDate,
          end: line.toDate,
          color: this.leaveColors[line.leaveType] ?? "grey darken-1",
        });
      });
      this.events = events;
    },
  },
};
</script>
