import call from "@kinetics254/cassandra-base/service/http";
import constants from "./constants";
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
import AppraisalConstants from "../appraisalv1/AppraisalConstants";
import router from "../../../../router/router";

export default {
  namespaced: true,
  state: {
    perfRewardDialog: false,
    appraisalResultDoc: {},
    appraisalRewards: [],
    appraisalCommittees: [],
    appraisalCommitteeDialog: false,
    assignedCommitteeAppraisalDocuments: [],
    appraisalDocumentCommitteeMembers: [],
    appraialDocumentRewards: [],
    addCommitteeMemberDialog: false,
    template: {},
    accepted_results: [],
    rejected_results: [],
    commitee_reviewed: [],
    appLines: [],
    approvalEntries: [],
    oneApplication: {},
    pendingApprovals: [],
    appraisalDocumentObjectives: [],
    appraisalDocumentKpis: [],
    employeeApplications: [],
    reviewDocumentStages: [],
    isCommitteeMember: undefined,
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    appraisalResultGetter: (state) => (val) => state[val],
  },
  actions: {
    getAcceptedAppraisalResults: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.results(payload))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "accepted_results", data: res.data.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getRejectedAppraisalResults: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.results(payload))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "rejected_results",
            data: res?.data?.data,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error?.response?.data?.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getCommitteeReviewedResults: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.results(payload))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "commitee_reviewed",
            data: res?.data?.data,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error?.response?.data?.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getAppraisalDocument({ commit }, params) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.getAppraisalDocument(params))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "appraisalResultDoc",
            data: Array.isArray(res.data.data)
              ? res.data.data[0]
              : res.data.data,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error?.response?.data?.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    pupOnPIP({ commit, dispatch }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      let { params, ...data } = payload;
      const item = {
        reviewNo: data.reviewNo,
        employeeNo: data.employeeNo,
      };

      call("post", constants.codeUnitActions(params), item)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Employ put on perfomance impovement plan");
          setTimeout(() => window.location.reload(), 5000);
          dispatch("ceoReviewComment", data);
        })
        .catch((error) => {
          Event.$emit("ApiError", error?.response?.data?.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    rewardEmployee({ commit, dispatch }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.rewardEmployee, payload)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Employee rewarded");
          dispatch("markEmployeeRewarded", {
            params: {
              provider: "GALAXY_CU",
              entity: "appraisalManagement_Reward",
            },
            employeeNo: AuthService?.user?.profile?.number,
            reviewNo: payload[0].reviewId,
          });
          dispatch("getAppraisalDocumentRewards", {
            reviewId: payload[0].reviewId,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error?.response?.data?.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    markEmployeeRewarded({ commit }, payload) {
      const { params, ...data } = payload;
      commit("loader/SET_LOADING", false, { root: true });
      call("post", constants.codeUnitActions(params), data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Employee rewarded");
          window.location.reload();
        })
        .catch((error) => {
          Event.$emit("ApiError", error?.response?.data?.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getAppraisalRewards({ commit }, params = {}) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.appraisalRewards)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "appraisalRewards",
            data: res.data.data,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error?.response?.data?.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getAppraisalCommittees({ commit }) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.getAppraisalCommittees)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "appraisalCommittees",
            data: res.data.data,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error?.response?.data?.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    assignAppraisalDocumentACommittee({ commit, dispatch }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.assigneAppraisalCommittee, payload)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("sendToCommitteeStage", {
            reviewNo: payload.code,
            employeeNo: payload.currEmployee,
            params: {
              provider: "GALAXY_CU",
              entity: "appraisalManagement_SendDocToCommittee",
            },
          });
          dispatch("getAppraisalDocument", {
            docNo: res[0]?.code,
            period: res[0]?.period,
          });
          dispatch("getAppraisalDocumentCommitteeMembers", {
            code: res[0]?.code,
          });
          router.push({ name: "ResultList" });
        })
        .catch((error) => {
          Event.$emit("ApiError", error?.response?.data?.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    sendToCommitteeStage({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      const { params, ...data } = payload;
      call("post", constants.codeUnitActions(params), data)
        .then(() => {
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error?.response?.data?.message);
        });
    },
    getAssignedCommitteeAppraisaldocuments({ commit }, params) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.getCommitteeAssignedDocuments(params))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "assignedCommitteeAppraisalDocuments",
            data: res.data?.data,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error?.response?.data?.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    employeeApplications({ commit }) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", AppraisalConstants.employeeApplications)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "employeeApplications",
            data: res.data?.data,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getAppraisalDocumentCommitteeMembers({ commit }, params) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.getAppraisalDocumentCommitteMembers(params))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "appraisalDocumentCommitteeMembers",
            data: res.data?.data,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error?.response?.data?.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getAppraisalDocumentRewards({ commit }, params) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.getAppraisalDocumentRewards(params))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "appraialDocumentRewards",
            data: res.data?.data,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error?.response?.data?.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    addCommitteeMember({ commit, dispatch }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.addCommitteeMember, payload)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Committee member added");
          dispatch("getAppraisalDocumentCommitteeMembers", {
            code: payload.reviewDocID,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error?.response?.data?.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    deleteCommitteeMember({ commit, dispatch }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.deleteCommitteeMember, payload)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Committee member deleted");
          dispatch("getAppraisalDocumentCommitteeMembers", {
            code: payload.reviewDocID,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error?.response?.data?.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getDocumentKpis({ commit }, params) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.getDocumentKpis(params))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "appraisalDocumentKpis",
            data: res.data?.data,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error?.response?.data?.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getDocumentObjectives({ commit }, params) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.getDocumentObjectives(params))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "appraisalDocumentObjectives",
            data: res.data?.data,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error?.response?.data?.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    documentKpiAddScore({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.documentKpiAddScore, payload)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error?.response?.data?.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    submitReport({ commit }, payload) {
      const { params, ...data } = payload;
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.codeUnitActions(params), data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error?.response?.data?.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    ceoSetReviewed({ commit, dispatch }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      let { params, ...data } = payload;
      const item = {
        reviewNo: data.reviewNo,
        employeeNo: data.employeeNo,
      };

      call("post", constants.codeUnitActions(params), item)
        .then(() => {
          commit("loader/SET_LOADING", false, { root: true });
          this.router.back();
          Event.$emit("ApiSuccess", "Document marked reviewed successfully");
          dispatch("ceoReviewComment", data);
        })
        .catch((error) => {
          console.log("Error: " + error);
          console.log(this);
          Event.$emit("ApiError", error?.response?.data?.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    ceoReviewComment({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      const data = {
        code: payload.reviewNo,
        perfomanceFeedback: payload.perfomanceFeedback,
      };
      call("post", constants.perfomanceFeedback, data)
        .then(() => {
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          console.log("Error: " + error);
          console.log(this);
          Event.$emit("ApiError", error?.response?.data?.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getReviewDocumentStages(context) {
      context.commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.getReviewDocumentStages)
        .then((res) => {
          context.commit("loader/SET_LOADING", false, { root: true });
          context.commit("MUTATE", {
            state: "reviewDocumentStages",
            data: res.data?.data,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error?.response?.data?.message);
          context.commit("loader/SET_LOADING", false, { root: true });
        });
    },
    rejectAppraisalMgmntRecommendation(context, payload) {
      context.commit("loader/SET_LOADING", true, { root: true });
      const { params, ...data } = payload;
      call("post", constants.codeUnitActions(params), data)
        .then((res) => {
          context.commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Recommendation rejected successfully");
          context.dispatch("getAppraisalDocument", {
            docNo: data.reviewNo,
            period: router.currentRoute.params.period,
          });
        })
        .catch((error) => {
          context.commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error?.response?.data?.message);
        });
    },
    approveAppraisalMgmntRecommendation(context, payload) {
      context.commit("loader/SET_LOADING", true, { root: true });
      const { params, ...data } = payload;
      call("post", constants.codeUnitActions(params), data)
        .then((res) => {
          context.commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Recommendation rejected successfully");
          context.dispatch("getAppraisalDocument", {
            docNo: data.reviewNo,
            period: router.currentRoute.params.period,
          });
        })
        .catch((error) => {
          context.commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error?.response?.data?.message);
        });
    },
    checkIfIsAcommitteeMember(context) {
      call("get", constants.isCommitteeMember)
        .then((res) => {
          context.commit("MUTATE", {
            state: "isCommitteeMember",
            data: res.data?.data,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error?.response?.data?.message);
        });
    },
  },
};
