import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{staticClass:"mx-auto",attrs:{"elevation":"0"}},[_c(VCardTitle,[_c('div',{staticClass:"headline text-capitalize"},[_vm._v("My KPI Application")]),_c(VSpacer),_c(VBtn,{staticClass:"primary",on:{"click":_vm.createKPIDocument}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" Add KPIs ")],1)],1),_c(VDataTable,{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.applications},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c(VAvatar,{attrs:{"color":"primary","size":"36"}},[_c('span',{staticClass:"white--text text-caption"},[_vm._v(_vm._s(_vm.getUserIntials(item.name)))])]),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"small":"","dark":"","color":_vm.getStatusColor(item.status).color}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"mr-2 white--text",attrs:{"small":"","color":"blue","dark":""},on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v(" view ")]),(item.status === 'Open')?_c(VBtn,{staticClass:"white--text",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" delete ")]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }