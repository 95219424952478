<template>
  <v-container>
    <v-card class="mx-auto">
      <v-card-title>
        Exit Management
        <v-spacer />
        <v-btn class="primary text--capitalize" @click="addExit">
          <v-icon class="mx-2">mdi-plus</v-icon>
          Add Exit Notice
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="exits"
          dense
          class="elevation-0"
        >
          <!--eslint-disable-next-line -->
        <template v-slot:item.reasonsDescription="{ item }">
            <span class="text--truncate" style="width: 70%">{{
              item.reasonsDescription
            }}</span>
          </template>
          <!--eslint-disable-next-line -->
        <template v-slot:item.exitDate="{ item }">
            <span>{{ getDateFormat(item.exitDate) }}</span>
          </template>
          <!--eslint-disable-next-line -->
        <template v-slot:item.expectedDateOfExit="{ item }">
            <span>{{ getDateFormat(item.expectedDateOfExit) }}</span>
          </template>
          <!--eslint-disable-next-line -->

          <template v-slot:item.status="{ item }">
            <v-chip small label :color="getColor(item.status).color">
              <div style="color: #fff">{{ item.status }}</div>
            </v-chip>
          </template>
          <!--eslint-disable-next-line -->
        <template v-slot:item.actions="{ item }">
            <div class="d-flex justify-start">
              <!--BEGIN the view action button-->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    @click="editExit(item)"
                    class="mx-1"
                    color="teal lighten-4"
                    label
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      v-if="item.status === 'Open'"
                      color="teal darken-4"
                      small
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon v-else>mdi-eye</v-icon>
                  </v-chip>
                </template>
                <span>{{ item.status === "Open" ? "edit" : "view" }}</span>
              </v-tooltip>
              <!--END the view action button-->
              <!--BEGIN the cancel approval action button-->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-show="item.status === 'Pending Approval'"
                    class="mx-1"
                    color="orange lighten-4"
                    label
                    small
                    v-bind="attrs"
                    @click="cancelExitApproval(item)"
                    v-on="on"
                  >
                    <v-icon color="orange darken-4" small>mdi-cancel</v-icon>
                  </v-chip>
                </template>
                <span>cancel approval request</span>
              </v-tooltip>
              <!--END the cancel approval action button-->
              <!--BEGIN the send action button-->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-show="item.status === 'Open'"
                    class="mx-1"
                    color="blue lighten-4"
                    label
                    small
                    v-bind="attrs"
                    @click="exitApprovalRequest(item)"
                    v-on="on"
                  >
                    <v-icon color="blue darken-4" small>mdi-send</v-icon>
                  </v-chip>
                </template>
                <span>send for approval</span>
              </v-tooltip>
              <!--END the send action button-->
              <!--BEGIN the delete action button-->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-show="item.status === 'Open'"
                    class="mx-1"
                    color="red lighten-4"
                    label
                    small
                    v-bind="attrs"
                    @click="deleteExit(item)"
                    v-on="on"
                  >
                    <v-icon color="red darken-4" small>mdi-delete</v-icon>
                  </v-chip>
                </template>
                <span>delete</span>
              </v-tooltip>
              <!--END the delete action button-->
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { helper } from "../../cassandra-base/utilities";
export default {
  name: "ExitList",
  data() {
    return {
      //
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Exit/getExits");
    });
  },
  computed: {
    exits() {
      return this.$store.getters["Exit/exitGetters"]("exits");
    },
    headers() {
      return [
        { text: "No.", value: "no", align: "start" },
        { text: "Reason", value: "reasonsDescription" },
        { text: "Exit Date", value: "exitDate" },
        { text: "Expected Date Of Exit", value: "expectedDateOfExit" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
  },
  methods: {
    getColor(status) {
      return helper.getColor(status);
    },
    getDateFormat: function (date) {
      return helper.shortDateFormat(date);
    },
    addExit() {
      this.$router.push({ name: "ExitCard" });
    },
    editExit(exit) {
      this.$router.push({ name: "ExitCard", params: { code: exit.no } });
    },
    deleteExit(exit) {
      this.$confirm.show({
        title: "Delete Exit",
        text: `Are you sure you want to delete this exit (${exit.no})?`,
        onConfirm: () => {
          this.$store.dispatch("Exit/deleteExit", { ...exit });
        },
      });
    },
    exitApprovalRequest(exit) {
      this.$store.dispatch("Exit/sendForApproval", exit);
    },
    cancelExitApproval(exit) {
      this.$store.dispatch("Exit/cancelApproval", exit);
    },
  },
};
</script>
