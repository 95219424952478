<script>
export default {
  name: "LeavePlannerCalendar",
  props: ["employeeNo", "plannerLines"],

  data: () => ({
    type: "month",
    mode: "stack",
    weekday: [1, 2, 3, 4, 5, 6, 0],
    value: "",
    events: [],
    leaveColors: {
      ANNUAL: "green",
      SICK: "orange",
      MATERNITY: "blue",
      PATERNITY: "indigo",
      COMPASSION: "cyan",
      STUDY: "deep-purple",
    },
  }),

  methods: {
    getEvents({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);

      const periodPlannerLines = this.plannerLines.filter((planner) => {
        const plannerFromDate = new Date(planner.fromDate);
        const plannerToDate = new Date(planner.toDate);

        return (
          (plannerFromDate >= min && plannerFromDate <= max) ||
          (plannerToDate >= min && plannerToDate <= max)
        );
      });

      periodPlannerLines.forEach((line) => {
        events.push({
          documentNo: line.documentNo,
          name: `${line.leaveType} Leave (${line.formattedFromDate} to ${line.formattedToDate})`,
          start: line.fromDate,
          end: line.toDate,
          color: this.leaveColors[line.leaveType] ?? "grey darken-1",
        });
      });

      this.events = events;
    },

    getEventColor(event) {
      return event.color;
    },

    viewApplication: function ({ event }) {
      this.$router.push({
        name: "ViewLeavePlannerApplication",
        params: {
          no: event.documentNo,
        },
      });
    },
  },
};
</script>

<template>
  <div>
    <v-sheet tile height="64" class="d-flex">
      <v-toolbar flat>
        <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon class="ma-2" @click="$refs.calendar.next()">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-toolbar>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :type="type"
        :events="events"
        @click:event="viewApplication"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @change="getEvents"
      ></v-calendar>
    </v-sheet>
  </div>
</template>

<style scoped></style>
