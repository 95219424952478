<template>
  <v-dialog v-model="dialog" max-width="75%" persistent>
    <v-card class="mx-auto" flat>
      <v-card-title>
        Add Score <v-spacer />

        <span class="ml-0 text-body-1" v-if="routeParams">
          Application:
          <span class="font-weight-bold">{{ routeParams }}</span></span
        >
        <v-spacer />
        <span class="text-body-1" v-if="routeParams"> Status:</span>
        <v-btn
          class="mx-2 text-capitalize deep-orange lighten-4 deep-orange--text text--darken-4"
          elevation="0"
          small
          @click="GoBack()"
        >
          close
          <v-icon class="mx-2">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-title>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model.number="formData.committeeRatingScore"
          placeholder="Add score"
        />
        <label class="form-check-label" for="check1">Add score</label>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          @click="
            addScore({
              isSendForApproval: false,
            })
          "
          class="mr-2"
          color="primary"
          depressed
        >
          Add Score
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "CommitteeAddScore",

  beforeRouteEnter(to, from, next) {
    next((v) => {
      // v.$store.dispatch("appraisal/getPeriods");
    });
  },
  data: function () {
    return {
      dialog: true,
      search: "",
      values: [],
      applicationLines: [],
      expanded: [],
      formData: {
        committeeRatingScore: 0,
        kpiID: this.$route.params.kpi,
        reviewID: this.$route.params.code,
        groupID: "",
      },
    };
  },
  computed: {
    routeParams() {
      return this.$route.params.no;
    },
  },
  methods: {
    addScore({ isSendForApproval }) {
      this.$store.dispatch("Result/documentKpiAddScore", this.formData);
    },
    GoBack() {
      this.$router.push({
        name: "CommitteeReview",
        params: { code: this.$route.params.code },
      });
    },
  },
};
</script>
<style scoped>
@import "../../appraisalv1/styles/appraisal.css";
</style>
