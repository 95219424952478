<template>
  <v-card v-if="selectedKpi" elevation="0">
    <v-alert
      border="left"
      type="warning"
      class="mb-2 list-group-item"
      v-if="NotSaved"
      dismissible
      dense
    >
      There are unsaved changes
      <template v-slot:close="">
        <v-btn @click="discard" small> Discard Changes </v-btn>
      </template>
    </v-alert>
    <v-card-title class="green--text list-group-item">
      {{ selectedKpi.kpiID }}
    </v-card-title>
    <v-card-subtitle class="text-justify my-2 list-group-item">
      {{ selectedKpi.description }}
    </v-card-subtitle>
    <v-divider />
    <v-card-text>
      <v-row dense>
        <v-col>
          <h1 class="deep-orange--text text-center text-h6 list-group-item">
            TARGET
            <span class="font-weight-bold">{{ selectedKpi.target }}%</span>
          </h1>
        </v-col>
        <v-col>
          <h1 class="list-group-item deep-orange--text text-center text-h6">
            WEIGHT
            <span class="font-weight-bold">{{ selectedKpi.weight }}/100</span>
          </h1>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider />

    <v-card-text>
      <v-row v-if="committeeEdit">
        <!--My Review -->
        <v-col cols="12">
          <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title> Self Review </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item style="padding: 0 !important">
              <v-list-item-content>
                <v-list-item-title>
                  <v-form ref="employeeForm">
                    <v-card-text>
                      <v-select
                        v-if="selectedKpi.ratingMethod === 'Rating Scale'"
                        :disabled="!selfEdit"
                        v-model="selectedKpi.selfRatingScore"
                        dense
                        outlined
                        :items="ratingScale"
                        item-value="code"
                        :item-text="
                          (item) =>
                            selectedKpi.ratingMethod === 'Rating Scale'
                              ? `${item.code} - ${item.description}`
                              : item.percent
                        "
                        label="Rating"
                        :rules="rules.required"
                      />
                      <v-text-field
                        :rules="rules.required"
                        :disabled="!selfEdit"
                        v-else
                        dense
                        type="number"
                        v-model="selectedKpi.selfRatingScore"
                        outlined
                        label="Score"
                      />

                      <v-textarea
                        :rules="rules.required"
                        :disabled="!selfEdit"
                        dense
                        rows="5"
                        v-model="selectedKpi.selfComment"
                        outlined
                        label="comments"
                      />

                      <v-file-input
                        v-if="!selectedKpi.selfAttachment"
                        :disabled="!selfEdit"
                        label="Attachment"
                        outlined
                        show-size
                        dense
                        v-model="selfAttachment"
                        ref="selfAttachment"
                        prepend-icon=""
                        :append-outer-icon="
                          selectedKpi.selfAttachment ? 'mdi-download' : ''
                        "
                      >
                        <template v-slot:selection="{ text }">
                          <v-chip small label color="secondary lighten-2">
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>

                      <v-chip
                        v-if="selectedKpi.selfAttachment"
                        close
                        @click:close="removeBase64"
                        label
                        block
                        color="secondary lighten-2"
                        class="text-truncate"
                        @click="downloadLink(selectedKpi.selfAttachment)"
                      >
                        <v-icon left>mdi-download</v-icon>
                        Attachment
                      </v-chip>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        v-if="selfEdit"
                        @click="saveEmployeeKpi"
                        color="primary"
                      >
                        <v-icon left>mdi-content-save</v-icon>
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-col>

        <v-col cols="12">
          <v-divider />
        </v-col>

        <!--Supervisor Review -->
        <v-col cols="12" v-if="$vuetify.breakpoint.mdAndUp || reviewerEdit">
          <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Supervisor Review</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item style="padding: 0 !important">
              <v-list-item-content class="p-0">
                <v-list-item-title>
                  <v-form v-model="validity" ref="supervisorForm">
                    <v-card-text>
                      <v-select
                        :rules="rules.required"
                        v-if="selectedKpi.ratingMethod === 'Rating Scale'"
                        :disabled="!reviewerEdit"
                        v-model="selectedKpi.reviewerRatingScore"
                        dense
                        outlined
                        :items="ratingScale"
                        item-value="code"
                        :item-text="
                          (item) =>
                            selectedKpi.ratingMethod === 'Rating Scale'
                              ? `${item.code} - ${item.description}`
                              : item.percent
                        "
                        label="Rating"
                      />

                      <v-text-field
                        :disabled="!reviewerEdit"
                        v-else
                        v-model="selectedKpi.reviewerRatingScore"
                        dense
                        outlined
                        type="number"
                        label="Score"
                      />
                      <v-textarea
                        :rules="rules.required"
                        :disabled="!reviewerEdit"
                        dense
                        rows="5"
                        v-model="selectedKpi.reviewerComment"
                        outlined
                        label="comments"
                      />

                      <v-file-input
                        v-if="!selectedKpi.reviewerAttachment"
                        label="Attachment"
                        outlined
                        dense
                        show-size
                        :disabled="!reviewerEdit"
                        v-model="reviewerAttachment"
                        ref="reviewerAttachment"
                        append-icon="mdi-paperclip"
                        prepend-icon=""
                        :append-outer-icon="
                          selectedKpi.reviewerAttachment ? 'mdi-download' : ''
                        "
                        @click:append-outer="
                          downloadLink(selectedKpi.reviewerAttachment)
                        "
                      >
                        <template v-slot:selection="{ text }">
                          <v-chip small label color="primary">
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>

                      <v-chip
                        v-if="selectedKpi.reviewerAttachment"
                        close
                        @click:close="removeBase64"
                        label
                        block
                        color="secondary lighten-2"
                        @click="downloadLink(selectedKpi.reviewerAttachment)"
                      >
                        <v-icon left>mdi-download</v-icon>
                        Attachment
                      </v-chip>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        v-if="reviewerEdit"
                        @click="saveSupervisorKpi"
                        color="primary"
                      >
                        <v-icon left>mdi-content-save</v-icon>
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-col>

        <v-col v-if="currentReview.reviewStageStatus !== ''" cols="12">
          <v-divider />
        </v-col>

        <!--Committee Review -->
        <v-col
          cols="12"
          v-if="
            currentReview.reviewStageStatus === 'Open' &&
            currentReview.documentStatus === 'Reviewed' &&
            currentReview.ownerResponse === 'Rejected' &&
            $vuetify.breakpoint.mdAndUp
          "
        >
          <v-form v-model="validity" ref="committeeForm">
            <v-card elevation="0">
              <v-card-title class="overline text-caption"
                >Committee Review</v-card-title
              >
            </v-card>

            <v-card-text>
              <v-select
                :rules="rules.required"
                v-if="selectedKpi.ratingMethod === 'Rating Scale'"
                v-model="selectedKpi.committeeRatingScore"
                :disabled="!committeeEdit"
                dense
                outlined
                :items="ratingScale"
                item-value="code"
                :item-text="
                  (item) =>
                    selectedKpi.ratingMethod === 'Rating Scale'
                      ? `${item.code} - ${item.description}`
                      : item.percent
                "
                label="Rating"
              />

              <v-text-field
                v-else
                v-model="selectedKpi.committeeRatingScore"
                :disabled="!committeeEdit"
                dense
                outlined
                type="number"
                label="Score"
              />
              <v-textarea
                :rules="rules.required"
                :disabled="!committeeEdit"
                dense
                rows="5"
                v-model="selectedKpi.committeeComment"
                outlined
                label="comments"
              />

              <v-file-input
                v-if="!selectedKpi.committeeAttachment"
                :disabled="!committeeEdit"
                label="Attachment"
                outlined
                dense
                show-size
                v-model="committeeAttachment"
                ref="committeeAttachment"
                append-icon="mdi-paperclip"
                prepend-icon=""
                :append-outer-icon="
                  selectedKpi.committeeAttachment ? 'mdi-download' : ''
                "
                @click:append-outer="
                  downloadLink(selectedKpi.committeeAttachment)
                "
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="primary">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>

              <v-chip
                v-if="selectedKpi.committeeAttachment"
                close
                @click:close="removeBase64"
                label
                block
                color="secondary lighten-2"
                @click="downloadLink(selectedKpi.committeeAttachment)"
              >
                <v-icon left>mdi-download</v-icon>
                Attachment
              </v-chip>
            </v-card-text>

            <v-card-actions v-if="committeeEdit">
              <v-spacer />
              <v-btn @click="saveCommitteeKpi" color="primary">
                <v-icon left>mdi-content-save</v-icon>
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-col>
      </v-row>

      <v-row v-else>
        <!--My Review -->
        <v-col cols="12" md="6">
          <v-form v-model="isValid" ref="employeeForm">
            <v-card elevation="0">
              <v-card-title class="overline text-caption"
                >Self Review</v-card-title
              >
            </v-card>

            <v-card-text>
              <v-select
                v-if="selectedKpi.ratingMethod === 'Rating Scale'"
                :disabled="!selfEdit"
                v-model="selectedKpi.selfRatingScore"
                dense
                outlined
                :items="ratingScale"
                item-value="code"
                :item-text="
                  (item) =>
                    selectedKpi.ratingMethod === 'Rating Scale'
                      ? `${item.code} - ${item.description}`
                      : item.percent
                "
                label="Rating"
                :rules="rules.required"
              />
              <v-text-field
                :rules="rules.required"
                :disabled="!selfEdit"
                v-else
                dense
                type="number"
                v-model="selectedKpi.selfRatingScore"
                outlined
                label="Score"
              />

              <v-textarea
                :rules="rules.required"
                :disabled="!selfEdit"
                dense
                rows="5"
                v-model="selectedKpi.selfComment"
                outlined
                label="comments"
              />

              <v-file-input
                v-if="!selectedKpi.selfAttachment"
                :disabled="!selfEdit"
                label="Attachment"
                outlined
                show-size
                dense
                v-model="selfAttachment"
                ref="selfAttachment"
                prepend-icon=""
                :append-outer-icon="
                  selectedKpi.selfAttachment ? 'mdi-download' : ''
                "
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="secondary lighten-2">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>

              <v-chip
                v-if="selectedKpi.selfAttachment"
                close
                @click:close="removeBase64"
                label
                block
                color="secondary lighten-2"
                class="text-truncate"
                @click="downloadLink(selectedKpi.selfAttachment)"
              >
                <v-icon left>mdi-download</v-icon>
                Attachment
              </v-chip>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn v-if="selfEdit" @click="saveEmployeeKpi" color="primary">
                <v-icon left>mdi-content-save</v-icon>
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-col>

        <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
          <v-divider />
        </v-col>
        <v-divider v-else vertical />

        <!--Supervisor Review -->
        <v-col
          cols="12"
          md="6"
          v-if="$vuetify.breakpoint.mdAndUp || reviewerEdit"
        >
          <v-form v-model="validity" ref="supervisorForm">
            <v-card elevation="0">
              <v-card-title class="overline text-caption"
                >Supervisor Review</v-card-title
              >
            </v-card>

            <v-card-text>
              <v-select
                :rules="rules.required"
                v-if="selectedKpi.ratingMethod === 'Rating Scale'"
                :disabled="!reviewerEdit"
                v-model="selectedKpi.reviewerRatingScore"
                dense
                outlined
                :items="ratingScale"
                item-value="code"
                :item-text="
                  (item) =>
                    selectedKpi.ratingMethod === 'Rating Scale'
                      ? `${item.code} - ${item.description}`
                      : item.percent
                "
                label="Rating"
              />

              <v-text-field
                :disabled="!reviewerEdit"
                v-else
                v-model="selectedKpi.reviewerRatingScore"
                dense
                outlined
                type="number"
                label="Score"
              />
              <v-textarea
                :rules="rules.required"
                :disabled="!reviewerEdit"
                dense
                rows="5"
                v-model="selectedKpi.reviewerComment"
                outlined
                label="comments"
              />

              <v-file-input
                v-if="!selectedKpi.reviewerAttachment"
                label="Attachment"
                outlined
                dense
                show-size
                :disabled="!reviewerEdit"
                v-model="reviewerAttachment"
                ref="reviewerAttachment"
                append-icon="mdi-paperclip"
                prepend-icon=""
                :append-outer-icon="
                  selectedKpi.reviewerAttachment ? 'mdi-download' : ''
                "
                @click:append-outer="
                  downloadLink(selectedKpi.reviewerAttachment)
                "
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="primary">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>

              <v-chip
                v-if="selectedKpi.reviewerAttachment"
                close
                @click:close="removeBase64"
                label
                block
                color="secondary lighten-2"
                @click="downloadLink(selectedKpi.reviewerAttachment)"
              >
                <v-icon left>mdi-download</v-icon>
                Attachment
              </v-chip>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                v-if="reviewerEdit"
                @click="saveSupervisorKpi"
                color="primary"
              >
                <v-icon left>mdi-content-save</v-icon>
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-card
    height="100%"
    v-else
    elevation="0"
    class="d-flex flex-row justify-center align-center"
  >
    <span class="text-h5 font-weight-light">No KPI Selected</span>
  </v-card>
</template>

<script>
import FileMixin from "@kinetics254/cassandra-base/mixins/FileMixin";

export default {
  mixins: [FileMixin],
  props: {
    selfEdit: Boolean,
    reviewerEdit: Boolean,
    selectedKpi: Object,
    unsaved: Boolean,
    selectedKpiCtrl: Object,
    intendedSelection: Object,
    discard: Function,
  },
  data: () => ({
    isValid: true,
    validity: true,
    selfAttachment: null,
    reviewerAttachment: null,
    committeeAttachment: null,
  }),
  computed: {
    ratingScale() {
      return this.$store.getters["appraisal/ratingScale"];
    },
    NotSaved: {
      get() {
        return this.unsaved;
      },
      set(val) {
        return val;
      },
    },

    rules() {
      return {
        attachment: [(value) => !!value || "Required field"],
        required: [(value) => !!value || "Required field"],
      };
    },

    committeeEdit() {
      return this.currentReview.reviewStageStatus === "Open" &&
        this.currentReview.ownerResponse !== " ";
    },

    currentReview() {
      return this.$store.getters["appraisal/appraisalGetter"]("currentReview");
    },
  },
  mounted() {
    Event.$on("parentAction", (ref) => {
      this.$refs[ref].resetValidation();
    });
  },
  methods: {
    saveEmployeeKpi: function () {
      if (!this.isValid) {
        this.$refs.employeeForm.validate();
      } else {
        const kpiComments = [];
        const selfComment = {
          reviewID: this.selectedKpi.reviewID,
          kpi: this.selectedKpi.kpiID,
          remarks: "Commented",
          type: "Comment",
          author: "own",
          base64Attachment: "",
          Comment: this.selectedKpi.selfComment,
        };
        if (
          !this.selectedKpi.kpiComments.find(
            (c) => c.author === "Own" && c.type === "Comment"
          )
        ) {
          selfComment.insert = true;
        }
        if (this.selectedKpi.selfComment) {
          kpiComments.push(selfComment);
        }

        this.selfAttachment = {
          reviewID: this.selectedKpi.reviewID,
          kpi: this.selectedKpi.kpiID,
          remarks: "Attachment",
          type: "Attachment",
          author: "Own",
          Comment: "",
          base64Attachment: this.selectedKpi.selfAttachment,
        };
        if (
          !this.selectedKpi.kpiComments.find(
            (c) => c.author === "Own" && c.type === "Attachment"
          )
        ) {
          this.selfAttachment.insert = true;
        }
        if (this.selectedKpi.selfAttachment) {
          kpiComments.push(this.selfAttachment);
        }

        this.selectedKpi.kpiComments = kpiComments;
        this.$emit("updateSelectedCtrl", this.selectedKpi);
        this.$store.dispatch("appraisal/updateKpi", { ...this.selectedKpi });
        this.NotSaved = false;
      }
    },

    saveSupervisorKpi: function () {
      if (!this.validity) {
        this.$refs.supervisorForm.validate();
      } else {
        const kpiComments = [];

        const reviewerComment = {
          reviewID: this.selectedKpi.reviewID,
          kpi: this.selectedKpi.kpiID,
          remarks: "Commented",
          type: "Comment",
          author: "Reviewer",
          base64Attachment: "",
          Comment: this.selectedKpi.reviewerComment,
        };
        if (
          !this.selectedKpi.kpiComments.find(
            (c) => c.author === "Reviewer" && c.type === "Comment"
          )
        ) {
          reviewerComment.insert = true;
        }
        if (this.selectedKpi.reviewerComment) {
          kpiComments.push(reviewerComment);
        }

        this.reviewerAttachment = {
          reviewID: this.selectedKpi.reviewID,
          kpi: this.selectedKpi.kpiID,
          remarks: "Attachment",
          type: "Attachment",
          author: "Reviewer",
          Comment: "",
          base64Attachment: this.selectedKpi.reviewerAttachment,
        };
        if (
          !this.selectedKpi.kpiComments.find(
            (c) => c.author === "Reviewer" && c.type === "Attachment"
          )
        ) {
          this.reviewerAttachment.insert = true;
        }
        if (this.selectedKpi.reviewerAttachment) {
          kpiComments.push(this.reviewerAttachment);
        }

        this.selectedKpi.kpiComments = kpiComments;
        this.$emit("updateSelectedCtrl", this.selectedKpi);
        this.$store.dispatch("appraisal/updateSupervisorKpi", {
          ...this.selectedKpi,
        });
        this.NotSaved = false;
      }
    },

    saveCommitteeKpi: function () {
      if (!this.validity) {
        this.$refs.committeeForm.validate();
      } else {
        const kpiComments = [];

        const committeeComment = {
          reviewID: this.selectedKpi.reviewID,
          kpi: this.selectedKpi.kpiID,
          remarks: "Commented",
          type: "Comment",
          author: "Committee",
          base64Attachment: "",
          Comment: this.selectedKpi.committeeComment,
        };
        if (
          !this.selectedKpi.kpiComments.find(
            (c) => c.author === "Committee" && c.type === "Comment"
          )
        ) {
          committeeComment.insert = true;
        }
        if (this.selectedKpi.committeeComment) {
          kpiComments.push(committeeComment);
        }

        this.committeeAttachment = {
          reviewID: this.selectedKpi.reviewID,
          kpi: this.selectedKpi.kpiID,
          remarks: "Attachment",
          type: "Attachment",
          author: "Committee",
          Comment: "",
          base64Attachment: this.selectedKpi.committeeAttachment,
        };
        if (
          !this.selectedKpi.kpiComments.find(
            (c) => c.author === "Committee" && c.type === "Attachment"
          )
        ) {
          this.committeeAttachment.insert = true;
        }
        if (this.selectedKpi.committeeAttachment) {
          kpiComments.push(this.committeeAttachment);
        }

        this.selectedKpi.kpiComments = kpiComments;
        this.$emit("updateSelectedCtrl", this.selectedKpi);
        this.$store.dispatch("appraisal/updateCommitteeKpi", {
          ...this.selectedKpi,
        });
        this.NotSaved = false;
      }
    },
    removeBase64() {
      if (this.selfEdit) {
        this.selectedKpi.selfAttachment = "";
      } else if (this.reviewerEdit) {
        this.selectedKpi.reviewerAttachment = "";
      } else {
        this.selectedKpi.committeeAttachment = "";
      }
    },
  },
  watch: {
    selfAttachment: {
      handler: function (n) {
        if (
          Object.prototype.toString
            .call(n)
            .replace(/^\[object (.+)\]$/, "$1")
            .toLowerCase() === "file"
        ) {
          if (n.size < 2000000) {
            this.selectedKpi.selfAttachment = this.fileToBase64(n).then(
              (res) => {
                this.selectedKpi.selfAttachment = res.split(";base64,")[1];
                return this.selectedKpi.selfAttachment;
              }
            );
          } else {
            this.$refs.selfAttachment.reset();
            Event.$emit("ApiError", "Error the file selected is too big");
          }
        }
      },
    },
    reviewerAttachment: {
      handler: function (n) {
        if (
          Object.prototype.toString
            .call(n)
            .replace(/^\[object (.+)\]$/, "$1")
            .toLowerCase() === "file"
        ) {
          if (n.size < 2000000) {
            this.selectedKpi.reviewerAttachment = this.fileToBase64(n).then(
              (res) => {
                this.selectedKpi.reviewerAttachment = res.split(";base64,")[1];
              }
            );
          } else {
            this.$refs.reviewerAttachment.reset();
            Event.$emit("ApiError", "Error the file selected is too big");
          }
        }
      },
    },
    committeeAttachment: {
      handler: function (n) {
        if (
          Object.prototype.toString
            .call(n)
            .replace(/^\[object (.+)\]$/, "$1")
            .toLowerCase() === "file"
        ) {
          if (n.size < 2000000) {
            this.selectedKpi.committeeAttachment = this.fileToBase64(n).then(
              (res) => {
                this.selectedKpi.committeeAttachment = res.split(";base64,")[1];
              }
            );
          } else {
            this.$refs.committeeAttachment.reset();
            Event.$emit("ApiError", "Error the file selected is too big");
          }
        }
      },
    },
  },
};
</script>

<style></style>
