import Auth from "@kinetics254/cassandra-base/modules/auth/middleware/Auth";
const AppDashboard = () =>
  import("@kinetics254/cassandra-base/app/layout/AppDashboard");

import TrainingList from "./components/TrainingList";
import TrainingCard from "./components/TrainingCard";

export default [
  {
    path: "/training",
    component: AppDashboard,
    children: [
      {
        path: "",
        redirect: "/training/list",
      },
      {
        path: "list",
        name: "TrainingList",
        components: {
          view: TrainingList,
        },
        meta: { middleware: [Auth] },
      },
      {
        path: "card/:code?",
        name: "TrainingCard",
        components: {
          view: TrainingList,
          action: TrainingCard,
        },
        meta: { middleware: [Auth] },
      },
    ],
  },
];
