<script>
/* eslint-disable camelcase */
import helpers from "@/packages/cassandra-base/utilities/helpers/helper.js";
export default {
  name: "LeavePlannerList",
  props: {
    employeeNo: String,
  },

  computed: {
    planners: function () {
      return this.$store.getters["leavePlanner/leavePlannerGetter"]("planners");
    },

    headers: function () {
      return [
        {
          text: "#Code",
          align: "start",
          sortable: true,
          value: "no",
        },
        { text: "Leave Types", sortable: true, value: "leaveTypes" },
        { text: "Period", sortable: true, value: "period" },
        { text: "Start Date", sortable: true, value: "fromDate" },
        { text: "End Date", sortable: true, value: "toDate" },
        { text: "Duration", sortable: true, value: "duration" },
        { text: "Status", sortable: true, value: "status" },
        { text: "Actions", sortable: false, value: "actions" },
      ];
    },
    userData: function () {
      return this.$store.getters["auth/user"];
    },
  },

  methods: {
    getColor: function (status) {
      return helpers.getColor(status);
    },

    getInitials: function (names) {
      return helpers.getInitials(names);
    },

    getDateFormat: function (date) {
      return helpers.getDateFormat(date);
    },

    viewApplication: function (item) {
      this.$router.push({
        name: "ViewLeavePlannerApplication",
        params: {
          no: item.no,
        },
      });
    },

    editApplication: function (item) {
      this.$router.push({
        name: "MakeLeavePlannerApplication",
        query: {
          no: item.no,
        },
      });
    },

    sendForApproval: function (item) {
      this.$confirm.show({
        title: "Send for approval",
        text: `Planner ${item.no} will be sent for approval. Do you want to continue?`,
        onConfirm: () => {
          this.$store.dispatch("leavePlanner/sendForApproval", {
            no: item.no,
            employeeNo: this.employeeNo,
          });
        },
      });
    },

    cancelApproval: function (item) {
      this.$confirm.show({
        title: "Cancel approval",
        text: `Planner ${item.no}'s approval request will be cancelled. Do you want to continue?`,
        onConfirm: () => {
          this.$store.dispatch("leavePlanner/cancelApproval", {
            no: item.no,
            employeeNo: this.employeeNo,
          });
        },
      });
    },

    deleteApplication: function (item) {
      this.$confirm.show({
        title: "Send for approval",
        text: `Planner ${item.no} will be deleted. Do you want to continue?`,
        onConfirm: () => {
          this.$store.dispatch("leavePlanner/deletePlanner", {
            no: item.no,
            employeeNo: this.employeeNo,
          });
        },
      });
    },
  },
};
</script>

<template>
  <v-data-table
    :headers="headers"
    :items="planners"
    :sort-desc="[false, true]"
    multi-sort
    class="elevation-1"
    ref="leave_planner_list"
    id="scollStyle"
  >
    <!--eslint-disable-next-line -->
    <template v-slot:item.status="{ item }">
      <v-chip label :color="getColor(item.status).color" small>
        {{ item.status }}
      </v-chip>
    </template>
    <!--eslint-disable-next-line -->
    <template v-slot:item.leaveTypes="{ item }">
      <div>
        {{ item.leavePlannerLines.map((line) => line.leaveType).join(", ") }}
      </div>
    </template>
    <!--eslint-disable-next-line -->
    <template v-slot:item.fromDate="{ item }">
      {{ getDateFormat(item.fromDate) }}
    </template>
    <!--eslint-disable-next-line -->
    <template v-slot:item.toDate="{ item }">
      {{ getDateFormat(item.toDate) }}
    </template>
    <!--eslint-disable-next-line -->
    <!--eslint-disable-next-line -->
    <template v-slot:item.duration="{ item }">
      {{ item.duration | pluralize("day", "days") }}
    </template>
    <!--eslint-disable-next-line -->
    <template v-slot:item.actions="{ item }">
      <div class="d-flex justify-start">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              class="primary lighten-4 mx-1"
              v-bind="attrs"
              v-on="on"
              label
              @click="viewApplication(item)"
            >
              <v-icon color="primary darken-4">pageview</v-icon>
            </v-chip>
          </template>
          <span>view application</span>
        </v-tooltip>

        <template
          v-if="item.status === 'Open'"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="primary lighten-4 mx-1"
                label
                v-bind="attrs"
                v-on="on"
                @click="editApplication(item)"
              >
                <v-icon color="primary darken-4">mode_edit</v-icon>
              </v-chip>
            </template>
            <span>Edit Application</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="primary lighten-4 mx-1"
                label
                v-bind="attrs"
                v-on="on"
                @click="sendForApproval(item)"
              >
                <v-icon color="primary darken-4">schedule_send</v-icon>
              </v-chip>
            </template>
            <span>Send for approval</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="primary lighten-4 mx-1"
                label
                v-bind="attrs"
                v-on="on"
                @click="deleteApplication(item)"
              >
                <v-icon color="primary darken-4">event_busy</v-icon>
              </v-chip>
            </template>
            <span>Delete Application</span>
          </v-tooltip>
        </template>
        <template
          v-else-if="item.status === 'Pending'"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="primary lighten-4 mx-1"
                label
                v-bind="attrs"
                v-on="on"
                ripple
                @click="cancelApproval(item)"
              >
                <v-icon color="primary darken-4">cancel_schedule_send</v-icon>
              </v-chip>
            </template>
            <span>cancel approval</span>
          </v-tooltip>
        </template>
      </div>
    </template>
  </v-data-table>
</template>

<style scoped></style>
