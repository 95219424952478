import routes from "./routes.js";
import store from "./store.js";
import links from "./links.js";

export default {
  install(Vue, options) {
    /* register store and routes */
    if (options.router) options.router.addRoute(...routes);
    if (options.store) options.store.registerModule("pettycash", store);

    options.store.commit("Dashboard/ADD_LINK", links);
  },
};
