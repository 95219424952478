<template>
  <v-container fluid>
    <v-dialog
      v-model="rewardsDialog"
      persistent
      min-width="400"
      max-width="500"
    >
      <v-card width="100%">
        <v-toolbar>
          <v-toolbar-title>
            <span class="headline">
              {{ appraisalResultDoc.employeeName }}&apos;s recommendation</span
            >
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-autocomplete
              label="Employee Rewards"
              v-model="FormData.rewardId"
              item-text="code"
              :items="rewards"
              multiple
              :search-input.sync="search"
              return-object
              full-width
              @input="CheckIfOther"
            ></v-autocomplete>
            <template v-if="FormData.rewardId">
              <v-text-field
                v-if="CheckIfOther"
                label="Reward Value"
                v-model="FormData.rewardValue"
              ></v-text-field>
            </template>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            variant="contained"
            @click.native="rewardsDialog = false"
            ><v-icon class="mr-1" size="30">mdi-alpha-x</v-icon> Close</v-btn
          >
          <v-btn
            color="primary"
            variant="contained"
            @click.native="rewardEmployee"
          >
            <v-icon class="mr-2">mdi-gift-open</v-icon> reward</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
export default {
  name: "PerfRewards",
  data() {
    return {
      search: "",
      FormData: {
        reviewId: this.$route?.params?.code,
        rewardId: null,
        rewardDesc: "",
        rewardValue: null,
      },
    };
  },
  mounted() {
    this.FormData = {};
  },
  computed: {
    userData: {
      get() {
        return AuthService.user;
      },
    },
    appraisalResultDoc() {
      return this.$store.getters["Result/appraisalResultGetter"](
        "appraisalResultDoc"
      );
    },
    rewards: {
      get() {
        return this.$store.getters["Result/appraisalResultGetter"](
          "appraisalRewards"
        );
      },
    },
    rewardsDialog: {
      get() {
        return this.$store.getters["Result/appraisalResultGetter"](
          "perfRewardDialog"
        );
      },
      set(value) {
        this.$store.commit("Result/MUTATE", {
          state: "perfRewardDialog",
          data: value,
        });
      },
    },
  },
  watch: {
    FormData: {
      deep: true,
      handler(val) {
        this.FormData.rewardDesc = val.rewardId.description;
      },
    },
  },
  methods: {
    rewardEmployee() {
      if (this.CheckIfOther() && !this.FormData.rewardValue) {
        Event.$emit(
          "ApiError",
          "You must add reward value for the 'OTHER' reward"
        );
        return;
      }
      this.$confirm.show({
        title: "Reward Employee",
        text: "This Appraisal will be Approved to final Review Report",
        onConfirm: () => {
          this.FormData.reviewId = this.$route?.params?.code;
          this.FormData.currEmployee = this.userData?.profile?.number;
          const { rewardDesc, ...data } = this.FormData;
          const payload = data.rewardId.map((item) => {
            const { rewardId, rewardValue, ...rest } = data;
            if (item.code === "OTHER") {
              return {
                rewardId: item.code,
                rewardValue: this.FormData.rewardValue,
                ...rest,
              };
            }
            return {
              rewardId: item.code,
              ...rest,
            };
          });
          this.$store.dispatch("Result/rewardEmployee", payload);
          this.FormData = {};
          this.$store.commit("Result/MUTATE", {
            state: "perfRewardDialog",
            data: false,
          });
        },
      });
    },
    CheckIfOther() {
      const index = this.FormData.rewardId.findIndex((reward) => {
        return reward.code === "OTHER";
      });
      return index >= 0;
    },
  },
};
</script>

<style scoped></style>
