import AppraisalStore from "./modules/appraisalv1/AppraisalStore";
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";

const user = AuthService.user;

const caption = user
  ? user.captions
    ? user.captions.appraisal_caption
    : undefined
  : undefined;

export default {
  order: 2,
  name: "Appraisal",
  icon: "mdi-account-check",
  to: "/appraisal/dashboard",
  hasSubs: 2,
  subs: [
    {
      name: `${caption ? caption : "Appraisal"}`,
      icon: "mdi-text-box",
      to: "/templates/list",
      enable: true,
    },
    {
      name: "My Reviews",
      icon: "mdi-playlist-edit",
      to: "/appraisal/list",
      enable: true,
    },
    {
      name: "Supervisor Reviews",
      icon: "mdi-account-multiple",
      to: "/appraisal/supervisor-reviews/list",
      enable: true,
    },
    {
      name: "Second Supervisor Reviews",
      icon: "mdi-account-multiple",
      to: "/appraisal/second-supervisor-reviews/list",
      enable: true,
    },
    {
      name: "My Reviewees",
      icon: "mdi-format-list-checks",
      to: "/appraisal/review",
      enable: AppraisalStore.getters["appraisal/isReviewer"],
    },
    {
      name: "Committee",
      icon: "mdi-account-group",
      to: "/appraisal/results/committee",
      enable: true,
    },
    {
      name: "Ceo Reviews",
      icon: "mdi-format-list-checks",
      to: "/appraisal/results/ceo",
      enable: true,
    },
  ].filter((l) => {
    return l.enable;
  }),
};
