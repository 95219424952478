export default {
  order: 3,
  name: "Imprest",
  icon: "mdi-account-cash",
  to: "/imprest/dashboard",
  hasSubs: 2,
  subs: [
    {
      name: "My Approvals",
      icon: "mdi-playlist-edit",
      to: "/imprest/approvals",
      enable: true,
    },
    {
      name: "My Requests",
      icon: "mdi-account-cash",
      to: "/imprest/list",
      enable: true,
    },
  ],
};
