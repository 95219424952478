import call from "@kinetics254/cassandra-base/service/http";
import constants from "./trainingConstants";

export default {
  namespaced: true,
  state: {
    trainings: [],
    training: {},
    allTrainings: [],
    trainee: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    trainingGetters: (state) => (val) => state[val],
  },
  actions: {
    getTrainings: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.trainingDocuments)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "trainings", data: res.data.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getAllTrainings: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.allTrainings)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "allTrainings", data: res.data.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getTraining: ({ commit }, no) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.trainingDocument(no))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "training", data: res.data.data[0] });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    addTraining: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.trainingDocuments, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          if (res && data.sendForApproval) {
            dispatch("sendForApproval", { docNo: res.data.data[0].no });
          } else {
            dispatch("getTrainings");
            Event.$emit("closeDialog");
          }
          Event.$emit("ApiSuccess", res.data.message);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    updateTraining: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("patch", constants.updateTraining, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          if (res && data.sendForApproval) {
            dispatch("sendForApproval", { docNo: res.data.data[0].no });
          } else {
            // Event.$emit("closeDialog");
            dispatch("getTrainings");
            dispatch("getTraining", data.no);
          }
          Event.$emit("ApiSuccess", res.data.message);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    deleteTraining: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.deleteTraining, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("getTrainings");
          Event.$emit("closeDialog");
          Event.$emit("ApiSuccess", res.data.message);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    deleteTrainingLineItem: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      const no = data.documentNo;
      call("delete", constants.deleteLine, { data })
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("getTraining", no);
          Event.$emit("ApiSuccess", res.data.message);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    sendForApproval: ({ commit, dispatch }, no) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.sendForApproval, no)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("getTrainings");
          dispatch("getTraining", no.docNo);
          Event.$emit("ApiSuccess", res.data.message);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    cancelApproval: ({ commit, dispatch }, no) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.cancelApproval, no)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("getTrainings");
          dispatch("getTraining", no.docNo);
          Event.$emit("ApiSuccess", res.data.message);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    deleteTrainee: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      const no = data.documentNo;
      call("delete", constants.deleteTrainee, { data })
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("getTraining", no);
          Event.$emit("ApiSuccess", res.data.message);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    addTrainee: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      const no = data.documentNo;
      call("post", constants.addTrainee, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("getTraining", no);
          Event.$emit("ApiSuccess", res.data.message);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getTrainee: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.trainee)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "trainee", data: res.data.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    deleteAttachment: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.deleteAttachment, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("getTraining", data.no);
          Event.$emit("ApiSuccess", res.data.message);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
  },
};
