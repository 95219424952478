import call from "@kinetics254/cassandra-base/service/http";
import constants from "./handoverConstants";

export default {
  namespaced: true,
  state: {
    HandOverApprovals: [],
    application: {},
    acceptModal: {
      open: false,
      application_code: "",
      status: false,
    },
    handoverTasks: [],
    handoverReasons: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.stateField] = payload.data;
    },
    SET_ACCEPT_MODAL: (state, payload) => {
      // eslint-disable-next-line camelcase
      const { open, application_code, status } = payload;
      state.acceptModal = { ...state, open, application_code, status };
    },
  },
  getters: {
    handoverGetter: (state) => (value) => state[value],
  },
  actions: {
    getApplication: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.application(payload.application_code))
        .then((res) => {
          commit("MUTATE", {
            stateField: "application",
            data: res.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch(() => {
          Event.$emit("ApiError", "Unable to fetch leave application");
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    acceptRejectHandover: ({ dispatch, commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.patchHandover, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", res.data.message);
          Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getHandOverApprovals: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.HandOverApprovals).then((res) => {
        commit("loader/SET_LOADING", false, { root: true });
        commit("MUTATE", {
          stateField: "HandOverApprovals",
          data: res.data.data,
        });
      });
    },
    getHandoverTasks: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.handoverTasks)
        .then((res) => {
          commit("MUTATE", {
            stateField: "handoverTasks",
            data: res.data.data[0],
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch(() => {
          Event.$emit("ApiError", "Unable to fetch leave application");
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getHandoverReasons: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.handoverReasons)
        .then((res) => {
          commit("MUTATE", {
            stateField: "handoverReasons",
            data: res.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch(() => {
          Event.$emit("ApiError", "Unable to fetch leave application");
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
  },
};
