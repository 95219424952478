<template>
  <v-container fluid>
    <page-header
      title="Leave Planner Application Reviews"
      :path="$router.currentRoute.meta.breadcrumb"
    />
    <v-row>
      <v-col cols="12" md="4">
        <div>
          <v-text-field
            v-model="listFilter"
            prepend-inner-icon="filter_alt"
            solo
            dense
            label="filter"
          ></v-text-field>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div style="margin-left: 17%">
          <v-btn
            outlined
            class="mr-4"
            :disabled="!isCalender"
            color="primary"
            @click="isCalender = false"
          >
            <v-icon class="mr-2">view_day</v-icon>
            list
          </v-btn>
          <v-btn
            outlined
            class="mr-4"
            :disabled="isCalender"
            color="primary"
            @click="isCalender = true"
          >
            <v-icon class="mr-2">date_range</v-icon>
            Calendar
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <div style="height: 100%">
      <LeavePlanReviewerCalendar
        v-if="isCalender"
        :leavePlannerApprovals="leavePlannerApprovals"
      />
      <LeavePlanReviewerList
        v-else
        :leavePlannerApprovals="leavePlannerApprovals"
      />
    </div>
  </v-container>
</template>
<script>
import PageHeader from "../../components/planner/PageHeader.vue";
import LeavePlanReviewerCalendar from "../../components/planner/LeavePlanReviewerCalendar.vue";
import LeavePlanReviewerList from "../../components/planner/LeavePlanReviewerList.vue";

export default {
  name: "LeavePlanApplicationReviews",
  mixins: [],
  components: {
    LeavePlanReviewerCalendar,
    LeavePlanReviewerList,
    PageHeader,
  },
  data: function () {
    return {
      dateRange: [],
      actionsMenu: null,
      isApprover: this.$router.currentRoute.meta.isApprover,
      path: this.$router.currentRoute.meta.breadcrumb,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.getApprovalApplications();
    });
  },
  mounted() {},
  created() {},
  watch: {
    $route(to, from) {
      this.isApprover = to.meta.isApprover;
      this.path = to.meta.breadcrumb;
    },
  },
  computed: {
    leavePlannerApprovals: function () {
      return this.$store.getters["leavePlanner/leavePlannerGetter"](
        "leavePlannerApprovals"
      );
    },
    userData: function () {
      return this.$store.getters["auth/user"];
    },
    isCalender: {
      get() {
        return this.$store.getters["leavePlanner/isCalender"];
      },
      set(value) {
        this.$store.commit("leavePlanner/MUTATE", {
          state: "isCalender",
          data: value,
        });
      },
    },
    listFilter: {
      get() {
        return this.$store.state.leavePlanner.listFilterValue;
      },
      set(value) {
        this.$store.commit("leavePlanner/MUTATE", {
          state: "listFilterValue",
          data: value,
        });
      },
    },
    filter: {
      get() {
        return this.$store.state.leavePlanner.filterValue;
      },
      set(value) {
        this.$store.commit("leavePlanner/MUTATE", {
          state: "filterValue",
          data: value,
        });
      },
    },
  },
  methods: {
    getApprovalApplications: function () {
      this.$store.dispatch("leavePlanner/getApprovalApplications", {
        approverID: this.userData.employee,
        // status: "Open",
      });
    },
  },
};
</script>

<style scoped></style>
