import ApprovalList from "./components/ApprovalList";
import ApprovalCard from "./components/ApprovalCard";
import Approval from "./views/Approval";
import { Auth } from "@kinetics254/cassandra-base/modules/auth";
import AppDashboard from "@kinetics254/cassandra-base/app/layout/AppDashboard";

export default [
  {
    path: "/approvals",
    component: AppDashboard,
    children: [
      {
        path: "",
        redirect: "/approvals/list",
      },
      {
        path: "list",
        name: "ApprovalList",
        components: {
          view: ApprovalList,
        },
        meta: {
          middleware: Auth,
        },
      },
      {
        path: "card/:documentNo/:approverID",
        name: "ApprovalCard",
        components: {
          view: ApprovalCard,
        },
        meta: {
          middleware: Auth,
        },
      },
    ],
  },
];
