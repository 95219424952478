import AdvanceStore from "./AdvanceStore";
import AdvanceRoutes from "./AdvanceRoutes";
import AdvanceLinks from "./AdvanceLinks";

export default {
  install(Vue, options) {
    /* register store and routes */
    if (options.router) options.router.addRoute(...AdvanceRoutes);
    if (options.store) options.store.registerModule("Advance", AdvanceStore);

    options.store.commit("Dashboard/ADD_LINK", AdvanceLinks);
  },
};
