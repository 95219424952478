<template>
  <v-container fluid>
    <v-dialog v-model="addCommitteeMemberDialog" width="400px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Add Committee Member</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-autocomplete
                  v-model="FormData.employeeNo"
                  :items="leaveEmployees"
                  item-value="number"
                  :item-text="item => `${item.first_name} ${item.last_name}`"
                  return-object
                  label="Employee Number"
                  required
                ></v-autocomplete>
<!--                <v-text-field-->
<!--                  v-model="FormData.employeeName"-->
<!--                  label="Employ Name"-->
<!--                  required-->
<!--                ></v-text-field>-->
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="addCommitteeMemberDialog = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="addCommitteeMember"
            >Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "AddToCommitteDialog",
  props: {
    committeeCode: {
      type: String,
    }
  },
  data() {
    return {
      FormData: {
        reviewDocID: "",
        employeeNo: "",
        committeeCode: "",
        employeeName: "",
      },
    };
  },
  computed: {
    leaveEmployees: {
      get() {
        return this.$store.getters["Setup/setupGetter"]("leaveEmployees");
      },
    },
    addCommitteeMemberDialog: {
      get() {
        return this.$store.getters["Result/appraisalResultGetter"](
          "addCommitteeMemberDialog"
        );
      },
      set(val) {
        this.$store.commit("Result/MUTATE", {
          state: "addCommitteeMemberDialog",
          data: val,
        });
      },
    },
  },
  watch: {
    FormData: {
      deep: true,
      handler(val) {
        this.FormData.employeeName = `${val?.employeeNo?.first_name} ${val?.employeeNo?.middle_name}  ${val?.employeeNo?.last_name}`;
      },
    },
  },
  methods: {
    addCommitteeMember() {
      this.$refs.form.validate();
      if (this.valid) {
        this.FormData.reviewDocID = this.$route.params.code;
        this.FormData.committeeCode = this.committeeCode;
        this.FormData.employeeNo = this.FormData.employeeNo.number;

        const { employeeName, ...data } = this.FormData;

        this.$store.dispatch("Result/addCommitteeMember", data);

        this.$store.commit("Result/MUTATE", {
          state: "addCommitteeMemberDialog",
          data: false,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
