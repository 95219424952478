import call from "@kinetics254/cassandra-base/service/http";
import constants from "./ProfileConstants";

export default {
  namespaced: true,
  state: {
    employee: [],
  },
  mutations: {
    SET_EMPLOYEE: (state, payload) => {
      state.employee = payload;
    },
  },
  getters: {
    employee: (state) => state.employee,
  },
  actions: {
    getEmployee: ({ commit }, data) => {
      let value = "";
      for (let prop in data) {
        value = data[prop];
      }
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.leaveEmployee(value)).then((res) => {
        commit("loader/SET_LOADING", false, { root: true });
        commit("SET_EMPLOYEE", res.data.data);
      });
    },

    updateEmployee: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("patch", constants.updateEmployee(data.number), data)
        .then((res) => {
          Event.$emit("ApiSuccess", "Profile updated successfully");
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("getEmployee", { number: data.number });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
  },
};
