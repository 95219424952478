import trainingRoutes from "./trainingRoutes";
import trainingStore from "./trainingStore";
import trainingLinks from "./trainingLinks";

export default {
  install(Vue, options) {
    /* register store and routes */
    if (options.router) options.router.addRoute(...trainingRoutes);
    if (options.store) options.store.registerModule("Training", trainingStore);
    options.store.commit("Dashboard/ADD_LINK", trainingLinks);
  },
};
