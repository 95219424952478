import exitRoutes from "./exitRoutes";
import exitStore from "./exitStore";
import exitLinks from "./exitLinks";

export default {
  install(Vue, options) {
    if (options.router) options.router.addRoutes(exitRoutes);
    if (options.store) options.store.registerModule("Exit", exitStore);
    options.store.commit("Dashboard/ADD_LINK", exitLinks);
  },
};
