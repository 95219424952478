import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VDialog,{attrs:{"persistent":"","min-width":"400","max-width":"500"},model:{value:(_vm.rewardsDialog),callback:function ($$v) {_vm.rewardsDialog=$$v},expression:"rewardsDialog"}},[_c(VCard,{attrs:{"width":"100%"}},[_c(VToolbar,[_c(VToolbarTitle,[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.appraisalResultDoc.employeeName)+"'s recommendation")])])],1),_c(VCardText,[_c(VForm,[_c(VAutocomplete,{attrs:{"label":"Employee Rewards","item-text":"code","items":_vm.rewards,"multiple":"","search-input":_vm.search,"return-object":"","full-width":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"input":_vm.CheckIfOther},model:{value:(_vm.FormData.rewardId),callback:function ($$v) {_vm.$set(_vm.FormData, "rewardId", $$v)},expression:"FormData.rewardId"}}),(_vm.FormData.rewardId)?[(_vm.CheckIfOther)?_c(VTextField,{attrs:{"label":"Reward Value"},model:{value:(_vm.FormData.rewardValue),callback:function ($$v) {_vm.$set(_vm.FormData, "rewardValue", $$v)},expression:"FormData.rewardValue"}}):_vm._e()]:_vm._e()],2)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","variant":"contained"},nativeOn:{"click":function($event){_vm.rewardsDialog = false}}},[_c(VIcon,{staticClass:"mr-1",attrs:{"size":"30"}},[_vm._v("mdi-alpha-x")]),_vm._v(" Close")],1),_c(VBtn,{attrs:{"color":"primary","variant":"contained"},nativeOn:{"click":function($event){return _vm.rewardEmployee.apply(null, arguments)}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-gift-open")]),_vm._v(" reward")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }