<template>
  <v-container fluid>
    <v-card elevation="0" class="px-3 py-3">
      <v-card-title>
        <v-toolbar elevation="1">
          <v-btn @click="$router.back()" class="mr-3">
            <v-icon>mdi-arrow-left</v-icon>
            Back
          </v-btn>
          <v-toolbar-title>
            <span class="headline">Committee Report</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-card-title>
      <v-card-content>
        <v-data-table :headers="headers" :items="reviewDocumentKpis">
          <template v-slot:item.objectiveID="{ item }">
            <span> {{ getObjectiveDescription(item) }}</span>
          </template>
          <template v-slot:item.committeeRatingScore="{ item }">
            <span> {{ item.committeeRatingScore }} % </span>
          </template>
        </v-data-table>
      </v-card-content>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "CommitteeReport",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Result/getDocumentKpis", {
        reviewID: v.$route.params.code,
      });
      v.$store.dispatch("Result/getDocumentObjectives", {
        reviewID: v.$route.params.code,
      });
    });
  },
  computed: {
    headers: {
      get() {
        return [
          {
            text: "Objective",
            align: "start",
            sortable: true,
            value: "objectiveID",
          },
          {
            text: "KPI description",
            align: "start",
            sortable: true,
            value: "description",
          },
          {
            text: "Committee Score (%)",
            align: "start",
            sortable: true,
            value: "committeeRatingScore",
          },
        ];
      },
    },
    reviewDocumentKpis: {
      get() {
        return this.$store.getters["Result/appraisalResultGetter"](
          "appraisalDocumentKpis"
        );
      },
    },
    reviewObjectives: {
      get() {
        return this.$store.getters["Result/appraisalResultGetter"](
          "appraisalDocumentObjectives"
        );
      },
    },
  },
  methods: {
    getObjectiveDescription(item) {
      return this.reviewObjectives.find((it) => {
        return it.objectiveID === item.objectiveID;
      })?.description;
    },
  },
};
</script>

<style lang="scss" scoped></style>
