import AppDashboard from "@kinetics254/cassandra-base/app/layout/AppDashboard";
import Requisition from "./views/Requisition";
import RequisitionList from "./components/RequisitionList";
import RequisitionCard from "./components/RequisitionCard";
import ReqDashboard from "./components/ReqDashboard";
import reqreport from "./components/ReqDocument";
import { Auth } from "@kinetics254/cassandra-base/modules/auth";

export default [
  {
    path: "/requisition",
    component: AppDashboard,
    children: [
      {
        path: "",
        components: { view: Requisition },
        children: [
          {
            path: "",
            redirect: "/requisition/list",
            meta: {
              middleware: Auth,
            },
          },
          {
            path: "ReqDashboard",
            name: "ReqDashboard",
            component: ReqDashboard,
            meta: {
              middleware: Auth,
            },
          },
          {
            path: "list/:documentType?",
            name: "RequisitionList",
            component: RequisitionList,
            meta: {
              middleware: Auth,
            },
          },
          {
            path: "card/:code?",
            name: "RequisitionCard",
            component: RequisitionCard,
            meta: {
              middleware: Auth,
            },
          },
          {
            path: "reqReport/:headerNo?",
            name: "ReqDocument",
            component: reqreport,
          },
        ],
      },
    ],
  },
];
