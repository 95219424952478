<template>
  <v-layout align-left column wrap>
    <v-flex>
      <v-card flat>
        <v-card-title>
          Trainees
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            v-if="!isNew && formData.trainees.length === 0"
            @click="openDialog()"
          >
            <v-icon>mdi-plus</v-icon>
            add trainee
          </v-btn>
        </v-card-title>
        <section class="avatars-group pa-3 stacked">
          <div
            v-for="trainee in formData.trainees"
            :key="trainee.employee"
            class="avatars-group__item"
          >
            <v-menu
              :close-on-content-click="false"
              :nudge-width="100"
              open-on-hover
              left
              offset-x
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-on="on"
                  :color="userColor(trainee.name)"
                  v-bind="attrs"
                  dark
                >
                  <span class="white--text">{{ userName(trainee.name) }}</span>
                </v-avatar>
              </template>

              <v-card
                class="mx-auto"
                :height="isEdit && !isNew ? '170' : '130'"
                flat
              >
                <v-sheet
                  color="primary"
                  class="mx-auto px-3 d-flex"
                  height="40"
                  elevation="0"
                >
                  <v-avatar class="mt-3" color="white" size="50">
                    <v-avatar size="44" :color="userColor(trainee.name)">
                      <span class="white--text">
                        {{ userName(trainee.name) }}
                      </span>
                    </v-avatar>
                  </v-avatar>
                </v-sheet>
                <v-card-text
                  class="mt-1"
                  align="right"
                  :height="isEdit ? '170' : '130'"
                >
                  {{ trainee.employee }}
                  <br />
                  {{ trainee.name }}
                </v-card-text>
                <v-divider v-if="isEdit && !isNew" />
                <v-card-actions class="pt-3" v-if="isEdit && !isNew">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    small
                    depressed
                    @click="deleteTrainee(trainee)"
                  >
                    remove
                    <v-icon size="15" class="ml-1"> mdi-delete </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </div>

          <!-- add trainees -->
          <div class="avatars-group__item more">
            <v-avatar
              color="primary"
              size="40px"
              v-if="formData.trainees.length !== 0 && !isNew"
            >
              <v-btn icon @click="openDialog">
                <v-icon
                  :color="$vuetify.theme.dark ? 'grey darken-2' : 'white'"
                >
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-avatar>
          </div>
        </section>

        <!-- employees dialog -->
        <v-dialog
          v-model="dialog"
          persistent
          width="80%"
          :fullscreen="$vuetify.breakpoint.smAndDown"
        >
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                @click="dialog = false"
                class="deep-orange lighten-4 deep-orange--text text--darken-4"
              >
                close
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text
              class="overflow-y-auto overflow-x-hidden pt-5"
              style="max-height: 70vh"
            >
              <v-data-table
                dense
                :headers="headers"
                :items="employees"
                :search="search"
                :items-per-page="8"
                :footer-props="{
                  showFirstLastPage: true,
                  'items-per-page-text': 'Employees per page',
                  'items-per-page-options': [5, 8, 10],
                }"
                item-key="number"
              >
                <template v-slot:item.filtered="{ item }">
                  <v-layout>
                    <v-checkbox
                      small
                      class="shrink mr-2"
                      v-model="item.filtered"
                      :disabled="
                        item.number !== user.number &&
                        !canEdit &&
                        isEdit &&
                        !isSupervisor
                      "
                      @change="check($event, item)"
                    ></v-checkbox>
                  </v-layout>
                </template>
              </v-data-table>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" depressed v-if="!isEdit" @click="submit()">
                Submit
              </v-btn>
              <v-btn color="primary" depressed v-else @click="dialog = false">
                Done
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import helper from "@kinetics254/cassandra-base/utilities/helpers/helper";

export default {
  name: "Trainee",
  props: {
    formData: Object,
    isEdit: Boolean,
    isSupervisor: Boolean,
    canEdit: Boolean,
    user: Object,
    isNew: Boolean,
  },
  data() {
    return {
      dialog: false,
      search: "",
      chosenOnes: [],
    };
  },
  computed: {
    trainees() {
      return this.$store.getters["Setup/setupGetter"]("trainees");
    },
    employees() {
      return this.$store.getters["Setup/setupGetter"]("leaveEmployees").map(
        (item) => {
          const filtered = this.formData.trainees.some((itemInner) => {
            return itemInner.employee === item.number;
          });
          return {
            ...item,
            filtered,
            fullName:
              item.first_name + " " + item.middle_name + " " + item.last_name,
          };
        }
      );
    },
    headers() {
      return [
        {
          text: "Employee Number",
          value: "number",
          sortable: true,
          align: "start",
        },
        {
          text: "Name",
          value: "fullName",
          sortable: true,
          align: "start",
        },
        {
          text: "Email",
          value: "company_email",
          sortable: true,
          align: "start",
        },
        {
          text: "Select",
          value: "filtered",
          sortable: true,
          align: "start",
        },
      ];
    },
  },
  methods: {
    check(event, item) {
      if (this.isEdit) {
        const data = {
          documentNo: this.formData.no,
          employee: item.number,
        };
        if (!event) {
          this.deleteTrainee(data);
        } else {
          this.$store.dispatch("Training/addTrainee", data);
        }
      } else {
        if (event) {
          this.chosenOnes.push(item);
        } else {
          const data = this.chosenOnes.filter((employee) => {
            return employee.number === item.number;
          })[0];
          const index = Array.prototype.indexOf.call(this.chosenOnes, data);
          this.chosenOnes.splice(index, 1);
        }
      }
    },
    deleteTrainee(data) {
      this.$store.dispatch("Training/deleteTrainee", data);
    },
    openDialog() {
      this.dialog = true;
    },
    submit() {
      this.formData.trainees = this.chosenOnes.map((item) => {
        return {
          employee: item.number,
          name: item.first_name + " " + item.middle_name + " " + item.last_name,
        };
      });
      this.dialog = false;
    },
    allowMultiple(item) {
      return !this.canEdit;
    },
    userColor(name) {
      if (name) {
        return helper.stringToColour(name);
      }
    },
    userName(name) {
      if (name) {
        const words = name.split(" ");
        const letters = words.map((word) => word[0]);
        return letters.join("");
      }
    },
  },
};
</script>
