<template>
  <v-container>
    <v-card class="my-5 elevation-0" height="100%">
      <v-card-title>
        <v-btn text small @click="$router.back()">
          <v-icon left> mdi-arrow-left </v-icon>
          Back
        </v-btn>
        <v-btn small color="primary" @click="downloadLink(document)">
          <v-icon left color="white"> mdi-download </v-icon>
          Download
        </v-btn>
        <v-spacer />
        <v-btn class="white--text orange mx-2" @click="rejectReview">
          <v-icon left color="white"> mdi-close </v-icon>
          Disagree With Appraisal Review
        </v-btn>
        <v-btn class="white--text green" @click="acceptReview">
          <v-icon left color="white"> mdi-check </v-icon>
          Accept Appraisal Review
        </v-btn>
      </v-card-title>
      <v-divider />

      <v-row>
        <v-col cols="8" offset="2">
          <v-card-text class="elevation-1 my-1">
            <pdf
              v-for="i in getPages(document)"
              :key="i"
              :src="document"
              :page="i"
            />
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>

    <!--    <v-dialog v-model="dialog" width="50%">-->
    <!--      <v-card class="mx-auto">-->
    <!--        <v-card-title>Reject With A reason</v-card-title>-->
    <!--        <v-card-text>-->
    <!--          <v-textarea-->
    <!--            v-model="disagreementReason"-->
    <!--            label="Reason"-->
    <!--            outlined-->
    <!--            placeholder="Enter a reason"-->
    <!--          />-->
    <!--        </v-card-text>-->
    <!--        <v-card-actions>-->
    <!--          <v-btn color="error" text @click="dialog = false"> Cancel </v-btn>-->
    <!--          <v-spacer />-->
    <!--          <v-btn color="deep-orange white&#45;&#45;text" @click="rejectReview">-->
    <!--            Reject-->
    <!--          </v-btn>-->
    <!--        </v-card-actions>-->
    <!--      </v-card>-->
    <!--    </v-dialog>-->
  </v-container>
</template>

<script>
import pdf from "vue-pdf";
import FileMixin from "@kinetics254/cassandra-base/mixins/FileMixin";

export default {
  name: "supervisorReport",
  mixins: [FileMixin],
  components: { pdf },
  data: function () {
    return {
      numPages: undefined,
      disagreementReason: "",
      dialog: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("appraisal/getAppraisalReport", {
        reviewNo: atob(to.query.reviewNo),
      });
    });
  },
  mounted() {
    Event.$on("goToAppraisal", () => {
      this.$router.push({
        name: "Appraisal",
      });
    });
  },
  computed: {
    document() {
      return this.$store.getters["appraisal/appraisalGetter"](
        "appraisalReport"
      );
    },
    userData: function () {
      return this.$store.getters["auth/user"];
    },
  },
  methods: {
    getPages: function (document) {
      if (document.length !== 0) {
        document = pdf.createLoadingTask(document);
        document.promise.then((pdf) => {
          this.numPages = pdf._pdfInfo.numPages;
        });
        return this.numPages;
      }
    },
    rejectReview() {
      this.$confirm.show({
        title: "Disagree With Appraisal Review",
        text: "Are you sure you want to disagree with this appraisal review?",
        input: true,
        label: "Reject With A reason",
        onConfirm: (comment) => {
          this.$store.dispatch("appraisal/rejectReview", {
            reviewNo: atob(this.$route.query.reviewNo),
            rejectReason: comment,
            params: {
              provider: "GALAXY_CU",
              entity: "appraisalManagement_ContestResults",
            },
          });
        },
      });
    },
    acceptReview() {
      this.$confirm.show({
        title: "Agree With Appraisal Review",
        text: "Are you sure you want to agree with this appraisal review?",
        onConfirm: () => {
          this.$store.dispatch("appraisal/acceptReview", {
            reviewNo: atob(this.$route.query.reviewNo),
          });
        },
      });
    },
  },
};
</script>
