import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"fill-height"},[_c(VCol,[_c(VSheet,{attrs:{"height":"64"}},[_c(VToolbar,{attrs:{"flat":""}},[_c(VBtn,{staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.setToday}},[_vm._v(" Today ")]),_c(VBtn,{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c(VBtn,{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.$refs.calendar)?_c(VToolbarTitle,[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c(VSpacer),_c(VMenu,{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c(VIcon,{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c(VList,[_c(VListItem,{on:{"click":function($event){_vm.type = 'day'}}},[_c(VListItemTitle,[_vm._v("Day")])],1),_c(VListItem,{on:{"click":function($event){_vm.type = 'week'}}},[_c(VListItemTitle,[_vm._v("Week")])],1),_c(VListItem,{on:{"click":function($event){_vm.type = 'month'}}},[_c(VListItemTitle,[_vm._v("Month")])],1),_c(VListItem,{on:{"click":function($event){_vm.type = '4day'}}},[_c(VListItemTitle,[_vm._v("4 days")])],1)],1)],1)],1)],1),_c(VSheet,{attrs:{"height":"600"}},[_c(VCalendar,{ref:"calendar",attrs:{"color":"primary","events":_vm.events,"event-color":_vm.getEventColor,"type":_vm.type},on:{"click:event":_vm.showEvent,"click:more":_vm.viewDay,"click:date":_vm.viewDay,"change":_vm.updateRange},model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}}),_c(VMenu,{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c(VCard,{attrs:{"color":"grey lighten-4","min-width":"350px","flat":""}},[_c(VToolbar,{attrs:{"color":_vm.selectedEvent.color,"dark":""}},[_c(VToolbarTitle,{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.name)}}),_c(VSpacer),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"primary lighten-4 mx-1",attrs:{"label":""},on:{"click":function($event){return _vm.$router.push({
                      name: 'ViewLeavePlannerApplication',
                      params: {
                        no: _vm.selectedEvent.details.documentNo,
                      },
                    })}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"primary darken-4"}},[_vm._v("mdi-eye-arrow-right")])],1)]}}])},[_c('span',[_vm._v("view details")])])],1),_c(VCardText),_c(VCardActions,[_c(VBtn,{attrs:{"fab":"","text":"","color":"red red-darken"},on:{"click":function($event){_vm.selectedOpen = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }