<template>
  <v-row>
    <v-col cols="12" md="6" sm="12">
      <v-hover v-slot="{ hover }" open-delay="200">
        <v-card flat :elevation="hover ? 16 : 0" :class="{ 'on-hover': hover }">
          <v-card-text class="d-flex justify-start">
            <div>
              <vue-ellipse-progress
                :determinate="false"
                :size="160"
                :thickness="6"
                lineMode="normal"
                :legend="false"
                animation="rs 700 1000"
                fontSize="1.5rem"
                :loading="false"
                :no-data="false"
                :data="data"
                :gap="5"
              >
                <span slot="legend-caption" style="color: rgb(0, 68, 139)">
                  <span class="text-lg-h6 stats-info">
                    {{ numberPipe(total) }}
                  </span>
                </span>
              </vue-ellipse-progress>
            </div>
            <v-list dense max-width="60%">
              <!--BEGIN user requisition stats-->
              <v-list-item>
                <v-badge color="#239B56" left inline class="mr-n8 mt-n3">
                  <v-list-item-action></v-list-item-action>
                </v-badge>
                <v-list-item-content>
                  <v-list-item-title class="stats-info"
                    >User Requisition</v-list-item-title
                  >
                  <v-list-item-subtitle class="stats-info"
                    >Items: {{ User }}; Total:
                    {{ numberPipe(totalUserAmount) }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <!--END user requisition stats-->
              <!--BEGIN purchase requisition stats-->
              <v-list-item>
                <v-badge color="#E67E22" left inline class="mr-n8 mt-n3">
                  <v-list-item-action></v-list-item-action>
                </v-badge>
                <v-list-item-content>
                  <v-list-item-title class="stats-info"
                    >Purchase Requisition</v-list-item-title
                  >
                  <v-list-item-subtitle class="stats-info"
                    >Items: {{ Purchase }}; Total:
                    {{ numberPipe(totalPurchaseAmount) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <!--END purchase requisition stats-->
            </v-list>
          </v-card-text>
        </v-card>
      </v-hover>
    </v-col>
    <v-col cols="12" md="6" sm="12">
      <div>
        <v-layout row wrap>
          <v-flex
            v-for="(item, i) in storageStats"
            :key="i"
            s6
            sm4
            md3
            xl2
            class="req--custom--stats"
          >
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card
                flat
                style="background-color: #ffffff"
                :elevation="hover ? 16 : 0"
                :class="{ 'on-hover': hover }"
                class="mx-2 mt-3"
              >
                <v-list>
                  <v-list-item class="stats">
                    <v-list-item-content>
                      <v-list-item-title class="text-lg-h5 stats-info">{{
                        item.count
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="stats-info">{{
                        item.name
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-avatar :color="item.avatarColor" size="53">
                        <v-icon :color="item.iconColor">{{ item.icon }}</v-icon>
                      </v-avatar>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-hover>
          </v-flex>
        </v-layout>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import helper from "@kinetics254/cassandra-base/utilities/helpers/helper";
export default {
  name: "ReqStats",
  data: function () {
    return {};
  },
  computed: {
    data: function () {
      return [
        {
          progress: (this.totalUserAmount / this.total) * 100,
          color: "#239B56",
        },
        {
          progress: (this.totalPurchaseAmount / this.total) * 100,
          color: "#E67E22",
        },
      ];
    },
    storageStats: function () {
      const Statistics =
        this.$store.getters["Requisition/reqGetters"]("requisitions");
      return [
        {
          count: Statistics ? Statistics.length : [],
          name: "Total Requisitions",
          avatarColor: "teal accent-2",
          iconColor: "teal darken-4",
          icon: "all_inbox",
          background: "coral",
        },
        {
          count: Statistics
            ? Statistics.filter((i) => i.status === "Open").length
            : [],
          name: "Open",
          avatarColor: "orange accent-1",
          iconColor: "orange darken-4",
          icon: "open_in_new",
        },
        {
          count: Statistics
            ? Statistics.filter((i) => i.status === "Pending Approval").length
            : [],
          name: "Pending",
          avatarColor: "light-blue lighten-4",
          iconColor: "light-blue darken-4",
          icon: "pending",
        },
        {
          count: Statistics
            ? Statistics.filter((i) => i.status === "Released").length
            : [],
          name: "Approved",
          avatarColor: "green accent-2",
          iconColor: "green darken-4",
          icon: "fact_check",
        },
      ];
    },

    Stats() {
      return this.$store.getters["Requisition/reqGetters"]("requisitions");
    },
    Store() {
      const Statistics =
        this.$store.getters["Requisition/reqGetters"]("requisitions");
      return Statistics
        ? Statistics.filter((i) => i.documentType === "Store Requisition")
            .length
        : [];
    },
    User() {
      const Statistics =
        this.$store.getters["Requisition/reqGetters"]("requisitions");
      return Statistics
        ? Statistics.filter((i) => i.documentType === "User Requisition").length
        : [];
    },
    Purchase() {
      const Statistics =
        this.$store.getters["Requisition/reqGetters"]("requisitions");
      return Statistics
        ? Statistics.filter((i) => i.documentType === "Purchase Requisition")
            .length
        : [];
    },
    totalPurchaseAmount() {
      const Statistics =
        this.$store.getters["Requisition/reqGetters"]("requisitions");
      return Statistics
        ? Statistics.filter(
            (i) => i.documentType === "Purchase Requisition"
          ).reduce(function (accumulator, Statistics) {
            return accumulator + Statistics.amount;
          }, 0)
        : [];
    },
    totalStoreAmount() {
      const Statistics =
        this.$store.getters["Requisition/reqGetters"]("requisitions");
      return Statistics
        ? Statistics.filter(
            (i) => i.documentType === "Store Requisition"
          ).reduce(function (accumulator, Statistics) {
            return accumulator + Statistics.amount;
          }, 0)
        : [];
    },
    totalUserAmount() {
      const Statistics =
        this.$store.getters["Requisition/reqGetters"]("requisitions");
      return Statistics
        ? Statistics.filter(
            (i) => i.documentType === "User Requisition"
          ).reduce(function (accumulator, Statistics) {
            return accumulator + Statistics.amount;
          }, 0)
        : [];
    },
    total() {
      const total = this.totalPurchaseAmount + this.totalUserAmount;
      return total;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Requisition/getRequisitionStats");
    });
  },
  watch: {
    totalStoreAmount() {
      const Statistics =
        this.$store.getters["Requisition/reqGetters"]("requisitions");
      return Statistics
        ? Statistics.filter(
            (i) => i.documentType === "Store Requisition"
          ).reduce(function (accumulator, Statistics) {
            return accumulator + Statistics.amount;
          }, 0)
        : [];
    },
  },
  methods: {
    numberPipe: function (val) {
      return helper.shortNumberPipe(val);
    },
  },
};
</script>

<style>
/*@import "../styles/style.css";*/
@media (min-width: 960px) {
  .flex.md3.req--custom--stats {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
}
.stats {
  border-radius: 35px;
}
</style>

<style scoped>
@import "../styles/style.css";
</style>
