<template>
  <v-container class="fade-transition">
    <AppraisalHeader class="mt-n1" />
    <v-card flat class="mt-3">
      <v-card-text>
        <span class="headline text-h6">My Final Report </span>
        <v-row no-gutters>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="reports">
              <template v-slot:item.id="{ item }">
                <span class="supervisor-review-table">{{ item["code"] }}</span>
              </template>
              <template v-slot:item.stage="{ item }">
                <span class="supervisor-review-table">{{
                  item["stage"]
                }}</span>
                <span class="mx-2">
                  <v-chip
                    v-show="item.status === 'Active'"
                    small
                    color="green"
                    dark
                  >
                    <span class="supervisor-review-table">{{
                      item.stage
                    }}</span>
                  </v-chip>
                </span>
              </template>
              <template v-slot:item.employeeScore="{ item }">
                <span class="supervisor-review-table"
                  >{{ MyScore(item) }}%</span
                >
              </template>
              <template v-slot:item.supervisorScore="{ item }">
                <span class="supervisor-review-table"
                  >{{ SupervisorScore(item) }}%</span
                >
              </template>
              <template v-slot:item.actualScore="{ item }">
                <span class="supervisor-review-table"
                  >{{ item.actualScore }}%</span
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <!--END My reviews-->
    </v-card>
  </v-container>
</template>

<script>
import RouterMixin from "@kinetics254/cassandra-base/mixins/RouterMixin";
import AppraisalMixin from "../AppraisalMixin";
import AppraisalHeader from "./AppraisalHeader.vue";
import FileMixin from "@kinetics254/cassandra-base/mixins/FileMixin";
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";

export default {
  name: "FinalReport",
  mixins: [RouterMixin, AppraisalMixin, FileMixin],
  components: {
    AppraisalHeader,
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("appraisal/getPeriods");
      v.$store.dispatch("appraisal/getReport", v.$route.params.code);
      v.$store.dispatch("appraisal/getKpi");
      v.$store.dispatch("appraisal/getEmployeeApplications");
    });
  },
  data: function () {
    return {
      headers: [
        { text: "Code", align: "", sortable: true, value: "reviewID" },
        { text: "Stage", align: "", sortable: true, value: "stage" },
        {
          text: "self Score",
          align: "center",
          sortable: true,
          value: "selfRatingScore",
        },
        {
          text: "reviewer Score",
          align: "center",
          sortable: true,
          value: "reviewerRatingScore",
        },
        {
          text: "Committee Score",
          align: "center",
          sortable: true,
          value: "committeeRatingScore",
        },
        {
          text: "Final Score",
          align: "center",
          sortable: true,
          value: "actualScore",
        },
      ],
      selectedPeriod: "",
      period: "",
    };
  },
  computed: {
    document() {
      return this.$store.getters["appraisal/appraisalGetter"](
        "appraisalReport"
      );
    },
    reports() {
      if (this.period !== "") {
        return this.$store.getters["appraisal/reports"].filter(
          (a) => a.period === this.period
        );
      } else {
        return this.$store.getters["appraisal/reports"];
      }
    },
  },
  methods: {
    async downloadFile(item) {
      await this.$store.dispatch("appraisal/getAppraisalReport", {
        reviewNo: item,
      });
    },
  },
  watch: {
    document() {
      this.downloadLink(this.document);
    },
  },
};
</script>

<style scoped>
@import "../styles/appraisal.css";
</style>
