import { render, staticRenderFns } from "./MakeApplication.vue?vue&type=template&id=b408dafa&scoped=true"
import script from "./MakeApplication.vue?vue&type=script&lang=js"
export * from "./MakeApplication.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b408dafa",
  null
  
)

export default component.exports