import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.applications},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"small":"","dark":"","color":_vm.getStatusColor(item.status).color}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.reviewStageStatus",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"small":"","dark":"","color":_vm.getStatusColor(item.reviewStageStatus).color}},[_vm._v(" "+_vm._s(item.reviewStageStatus)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"mr-2 white--text",attrs:{"small":"","color":"blue","dark":""},on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v(" view ")]),(item.reviewStageStatus === 'Reviewed')?_c(VBtn,{staticClass:"mr-2 white--text",attrs:{"small":"","color":"secondary","dark":""},on:{"click":function($event){return _vm.committeeReport(item)}}},[_vm._v(" committee report ")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }