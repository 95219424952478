import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{staticClass:"mx-auto",attrs:{"elevation":"0"}},[_c(VCardTitle,[_c('div',{staticClass:"headline text-capitalize"},[_vm._v("Apraisal Results")]),_c(VSpacer)],1),_c(VToolbar,{attrs:{"color":"deep-purple-accent-4"},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c(VTabs,{attrs:{"fixed-tabs":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c(VTab,{key:item.tab},[_vm._v(_vm._s(item.tab))])}),1)]},proxy:true}]),model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}}),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{attrs:{"value":_vm.items[1]['Appealed']}},[_c('RejectedResultCard',{attrs:{"applications":_vm.rejectedApplications}})],1),_c(VTabItem,{attrs:{"value":_vm.items[1]['Committee Reviewed']}},[_c('RejectedResultCard',{attrs:{"applications":_vm.commiteeReviewdApplications}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }