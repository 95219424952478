<template>
  <v-data-table :headers="headers" :items="applications" class="elevation-0">
    <template v-slot:item.status="{ item }">
      <v-chip small dark :color="getStatusColor(item.status).color">
        {{ item.status }}
      </v-chip>
    </template>
    <template v-slot:item.reviewStageStatus="{ item }">
      <v-chip small dark :color="getStatusColor(item.reviewStageStatus).color">
        {{ item.reviewStageStatus }}
      </v-chip>
    </template>
    <!--eslint-disable-next-line -->
    <template v-slot:item.actions="{ item }">
      <v-btn
        small
        class="mr-2 white--text"
        color="blue"
        @click="viewItem(item)"
        dark
      >
        view
      </v-btn>
      <v-btn
        small
        class="mr-2 white--text"
        color="secondary"
        @click="committeeReport(item)"
        dark
        v-if="item.reviewStageStatus === 'Reviewed'"
      >
        committee report
      </v-btn>
    </template>
    <!--end of card actions -->
  </v-data-table>
</template>
<script>
import { helper } from "../../../../cassandra-base/utilities";

export default {
  name: "RejectedResultCard",

  props: ["applications"],

  computed: {
    headers() {
      return [
        {
          text: "Document No",
          value: "code",
          align: "start",
          sortable: true,
        },
        {
          text: "Employee Name",
          value: "employeeName",
        },
        {
          text: "Owner Response",
          value: "ownerResponse",
        },

        {
          text: "Status",
          value: "status",
        },
        {
          text: "Committee Report",
          value: "reviewStageStatus",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: true,
        },
      ];
    },
  },

  methods: {
    committeeReport(item) {
      this.$router.push({
        name: "committeeReport",
        params: {
          code: item.code,
        },
      });
    },
    viewItem(val) {
      this.$router.push({
        name: "ResultCard",
        params: { code: val.code, period: val.period },
      });
    },
    getStatusColor: function (status) {
      return helper.getColor(status);
    },
  },
};
</script>
