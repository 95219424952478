import moment from "moment";
export default {
  methods: {
    documentStatus(val) {
      const documentStatus = {
        Open: { primary: "amber", secondary: "amber lighten-5" },
        Closed: { primary: "teal", secondary: "teal lighten-5" },
        Reviewed: { primary: "red", secondary: "red lighten-5" },
        Submitted: { primary: "green", secondary: "green lighten-5" },
      };
      return documentStatus[val];
    },

    getColor(documentStatus) {
      let colorObject = {};
      switch (documentStatus) {
        case "Open":
          colorObject = {
            icon_color: "amber lighten-1",
            icon: "error_outline",
            color: "warning",
          };
          break;
        case "Reviewed":
          colorObject = {
            icon_color: "orange lighten-1",
            icon: "rate_review",
            color: "orange",
          };
          break;
        case "Submitted":
          colorObject = {
            icon_color: "green lighten-1",
            icon: "done",
            color: "success",
          };
          break;
        case "Closed":
          colorObject = {
            icon_color: "teal lighten-1",
            icon: "done",
            color: "teal accent-3",
          };
          break;
        default:
          colorObject = {
            icon_color: "blue lighten-1",
            icon: "new_releases",
            color: "info",
          };
          break;
      }
      return colorObject;
    },
    getScore(item) {
      if (item) {
        return item.toFixed(2);
      }
      return null;
    },
    MyScore(item) {
      if (item) {
        return item.scores ? item.scores.selfScoreAvg.toFixed(2) : 0;
      }
      return null;
    },
    SupervisorScore(item) {
      if (item) {
        return item.scores ? item.scores.reviewerScoreAvg.toFixed(2) : 0;
      }
      return null;
    },
    OverallScore(item) {
      if (item) {
        return item.scores ? item.scores.overallScore.toFixed(2) : 0;
      }
      return null;
    },
  },
  computed: {
    activeReview() {
      const reviews = this.$store.getters["appraisal/reviews"];
      return reviews ? reviews.filter((el) => el.status === "Active") : [];
    },
    ratingScale() {
      return this.$store.getters["appraisal/ratingScale"];
    },
    MyKpiScores() {
      return this.$store.getters["appraisal/kpi"];
    },
    KPIs: function () {
      return this.stats.kpis;
    },
    stats() {
      const stats = {
        objectives: 0,
        kpis: 0,
        activities: 0,
      };
      this.groups.forEach((grp) => {
        grp.reviewObjectives.forEach((ob) => {
          stats.objectives += 1;
          ob.reviewKPI.forEach((k) => {
            stats.kpis += 1;
          });
        });
      });
      return stats;
    },
    periods() {
      return this.$store.getters["appraisal/periods"];
    },
    currPeriod() {
      return this.periods.filter((p) => p.current).shift();
    },

    currentReview() {
      const reviews = this.$store.getters["appraisal/reviews"];
      return this.currPeriod
        ? reviews.filter((f) => f.period === this.currPeriod.code).shift()
        : null;
    },
    currPeriodStage() {
      if (this.periods) {
        var currentperiod = this.periods.filter((p) => p.current).shift();
        var currentStage = currentperiod.periodStages
          .filter((s) => s.current)
          .shift();
        return currentStage;
      } else {
        return null;
      }
    },
    daysRemaining() {
      if (this.currPeriodStage) {
        var remainingdays = moment(this.currPeriodStage.lastReviewDate).diff(
          moment(),
          "days"
        );
        return remainingdays < 1 ? 0 : remainingdays;
      } else {
        return 0;
      }
    },
    groups() {
      return this.currentReview ? this.currentReview.reviewGroup : [];
    },

    objectives() {
      let objectives = [];

      this.groups.forEach((group) => {
        objectives = [...objectives, ...group.reviewObjectives];
      });

      return objectives;
    },
    RatedKPIs() {
      var count = 0;
      this.MyKpiScores.forEach(function (e) {
        if (e.selfRatingScore !== "") {
          count++;
        }
      });
      return count;
    },
  },
};
