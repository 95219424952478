import call from "@kinetics254/cassandra-base/service/http";
import constants from "./LeavePlannerConstants";

export default {
  namespaced: true,
  state: {
    planners: [],
    plannerLines: [],
    leaveDaysBalances: [],
    planner: null,
    leaveTypes: [],
    leavePeriods: [],
    leavePlannerApprovals: [],
    isCalender: false,
    filterValue: "",
    listFilterValue: "",
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    leavePlannerGetter: (state) => (value) => state[value],
    isCalender: (state) => state.isCalender,
  },
  actions: {
    getLeaveTypes: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.leaveTypes(data))
        .then((res) => {
          commit("MUTATE", { state: "leaveTypes", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    getLeavePeriods: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.leavePeriods(data))
        .then((res) => {
          commit("MUTATE", { state: "leavePeriods", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    getPlanners: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.getPlanners(data))
        .then((res) => {
          commit("MUTATE", { state: "planners", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    getPlanner: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.getPlanner(data))
        .then((res) => {
          commit("MUTATE", { state: "planner", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    getPlannerLines: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.getPlannerLines(data))
        .then((res) => {
          commit("MUTATE", { state: "plannerLines", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    savePlanner: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.savePlanner, data)
        .then((res) => {
          commit("MUTATE", { state: "planner", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("plannerSaved", {
            hasError: false,
            planner: res.data.data,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("plannerSaved", {
            hasError: true,
          });
        });
    },

    savePlannerLine: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.savePlannerLine, data.lineItem)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("plannerLineSaved", {
            hasError: false,
            lineItem: res.data.data,
            position: data.position,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("plannerLineSaved", {
            hasError: true,
          });
        });
    },

    deletePlannerLine: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.deletePlannerLine, data.lineItem)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("plannerLineDeleted", {
            hasError: false,
            position: data.position,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("plannerLineDeleted", {
            hasError: true,
          });
        });
    },

    sendForApproval: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.sendForApproval, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", res.data.message);
          if (data.employeeNo) {
            dispatch("getPlanners", {
              employeeNo: data.employeeNo,
            });
          } else {
            dispatch("getPlanner", data.no);
          }
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    cancelApproval: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.cancelApproval, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", res.data.message);
          if (data.employeeNo) {
            dispatch("getPlanners", {
              employeeNo: data.employeeNo,
            });
          } else {
            dispatch("getPlanner", data.no);
          }
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    deletePlanner: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.deletePlanner, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", res.data.message);
          dispatch("getPlanners", {
            employeeNo: data.employeeNo,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    //approvals
    getApprovalApplications: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.leavePlannerApprovalEntries(payload))
        .then((res) => {
          commit("MUTATE", {
            state: "leavePlannerApprovals",
            data: res.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    approve: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.approve, payload)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("re-sync");
          Event.$emit("ApiSuccess", "Leave Plan approved successfully");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    reject: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.reject, payload)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("re-sync");
          Event.$emit("ApiSuccess", "Leave Plan rejected successfully");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("error", error.response.data.message);
        });
    },
    getLeaveDaysBalances: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.getLeaveDaysBalances(data))
        .then((res) => {
          commit("MUTATE", { state: "leaveDaysBalances", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
  },
};
