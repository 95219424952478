import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VDialog,{attrs:{"max-width":"400px","persistent":""},model:{value:(_vm.appraisalCommitteeDialog),callback:function ($$v) {_vm.appraisalCommitteeDialog=$$v},expression:"appraisalCommitteeDialog"}},[_c(VCard,{staticClass:"py-3 px-3"},[_c(VToolbar,{attrs:{"elevation":"0"}},[_c(VToolbarTitle,[_c('span',{staticClass:"headline"},[_vm._v("Assigne a Committee")])])],1),_c(VCardText,[_c(VForm,[_c(VAutocomplete,{attrs:{"items":_vm.appraisalCommittees,"label":"Appraisal Committee","item-text":"description","item-value":"code"},model:{value:(_vm.committee),callback:function ($$v) {_vm.committee=$$v},expression:"committee"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"mr-3",on:{"click":function($event){_vm.appraisalCommitteeDialog = false}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.assignCommittee}},[_vm._v("Assign")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }