<template>
  <v-card flat>
    <v-card-title class="header-title ml-3"> Approval Entries </v-card-title>
    <v-card-text>
      <v-timeline dense class="mx-10">
        <v-timeline-item
          v-for="(item, i) in approvalEntries"
          :key="i"
          :color="getColor(item.status).icon_color"
          small
          fill-dot
          v-show="item.status !== 'Canceled'"
          :icon="getColor(item.status).icon"
        >
          <v-row v-if="item.status !== 'Canceled'">
            <v-col cols="4">
              <div class="d-flex justify-start">
                <v-icon>schedule</v-icon>
                <strong class="view-page">{{
                  getEntryDateFormat(item.dateTimeSentForApproval)
                }}</strong>
              </div>
              <v-divider />
              <div class="d-flex justify-start">
                <div class="">
                  <h4 class="view-page">Status:</h4>
                </div>
                <v-chip
                  label
                  class="mx-1 my-1"
                  :color="getColor(item.status).icon_color"
                  x-small
                  ><span class="view-page">{{ item.status }}</span>
                </v-chip>
              </div>
            </v-col>
            <v-col cols="8">
              <v-row
                ><v-col
                  ><v-list-item>
                    <v-list-item-avatar
                      :color="getColor(item.status).icon_color"
                      style="color: rgb(0, 68, 139)"
                      size="50"
                    >
                      {{ getApproverValue(item.approver, "number") }}
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="view-page">
                        {{ getApproverValue(item.approver, "name") }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="">
                        {{ getApproverValue(item.approver, "email") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item></v-col
                >
                <v-col v-if="item.approvalComments.length">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="view-page font-weight-bold">
                        Comments
                      </v-list-item-title>
                      <v-card
                        flat
                        class="mt-1 text--secondary"
                        v-for="(comment, key) in item.approvalComments"
                        :key="key"
                      >
                        {{ comment }}
                      </v-card>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>
<script>
import helper from "@kinetics254/cassandra-base/utilities/helpers/helper";
export default {
  name: "ApprovalEntries",
  props: { approvalEntries: Array },

  methods: {
    getColor(status) {
      return helper.getColor(status);
    },
    getEntryDateFormat(date) {
      return helper.getEntryDateFormat(date);
    },
    getApproverValue: function (approver, label) {
      if (label === "name") {
        const names = [approver?.first_name ?? "", approver?.last_name ?? ""];
        return names.join(" ");
      } else if (label === "email") {
        return approver?.email ?? "N/A";
      } else if (label === "number") {
        return approver?.number ?? "N/A";
      }

      return "";
    },
  },
};
</script>
<style scoped>
@import url("../styles.css");
</style>
