import AppDashboard from "@kinetics254/cassandra-base/app/layout/AppDashboard";
import LeaveDashboard from "./views/LeaveDashboard";
import Auth from "@kinetics254/cassandra-base/modules/auth/middleware/Auth";
import LeaveModule from "./views/leave";
import MakeApplication from "./views/MakeApplication";
import PreviewApplication from "./views/PreviewApplication";
import LeaveAttachmentPreview from "./views/LeaveAttachmentPreview";
import LeavePlanner from "./views/planner/Index.vue";
import LeavePlannerMakeApplication from "./views/planner/MakeApplication.vue";
import LeavePlannerViewApplication from "./views/planner/ViewApplication.vue";
import LeavePlanReviews from "./views/planner/LeavePlanReviews.vue";

export default [
  {
    path: "/leave",
    component: AppDashboard,
    children: [
      {
        path: "",
        redirect: "/leave/application",
      },
      {
        path: "dashboard",
        components: {
          view: LeaveDashboard,
        },
        meta: {
          middleware: Auth,
          breadcrumb: [
            { text: "Dashboard", disabled: true, href: "/leave" },
            { text: "leave", disabled: false, href: "/leave/dashboard" },
          ],
        },
      },
      {
        path: "application",
        components: {
          view: LeaveModule,
        },
        meta: {
          middleware: Auth,
          breadcrumb: [
            { text: "leave", disabled: true, href: "/" },
            {
              text: "application",
              disabled: false,
              href: "/leave/application",
            },
          ],
          isApprover: false,
        },
      },
      {
        path: "make_application",
        components: {
          view: MakeApplication,
        },
        meta: {
          middleware: Auth,
          breadcrumb: [
            { text: "leave", disabled: true, href: "/" },
            {
              text: "application",
              disabled: false,
              href: "/leave/application",
            },
            {
              text: "make application",
              disabled: false,
              href: "/leave/application/make_application",
            },
          ],
          isApprover: false,
        },
      },
      {
        path: "view_application/:application_code",
        name: "view_application",
        components: {
          view: PreviewApplication,
        },
        meta: {
          middleware: Auth,
          breadcrumb: [
            { text: "leave", disabled: true, href: "/" },
            {
              text: "application",
              disabled: false,
              href: "/leave/application",
            },
            {
              text: "preview application",
              disabled: false,
              href: "view_application/:application_code",
            },
          ],
        },
      },
      {
        path: "view_application/:application_code/leave_attachment",
        name: "LeaveAttachmentPreview",
        components: {
          view: LeaveAttachmentPreview,
        },
        meta: {
          middleware: Auth,
          breadcrumb: [
            { text: "leave", disabled: true, href: "/" },
            {
              text: "application",
              disabled: false,
              href: "/leave/application",
            },
            {
              text: "leave attachment",
              disabled: false,
              href: "view_application/leaveattachment",
            },
          ],
        },
      },
      {
        path: "edit_application/:application_code",
        name: "edit_application",
        components: {
          view: MakeApplication,
        },
        meta: {
          middleware: Auth,
          breadcrumb: [
            { text: "leave", disabled: true, href: "/" },
            {
              text: "application",
              disabled: false,
              href: "/leave/application",
            },
            {
              text: "Edit application",
              disabled: false,
              href: "edit_application/:application_code",
            },
          ],
        },
      },
      {
        path: "approvals",
        components: {
          view: LeaveModule,
        },
        meta: {
          middleware: Auth,
          breadcrumb: [
            { text: "leave", disabled: true, href: "/" },
            { text: "approvals", disabled: false, href: "/leave/approvals" },
          ],
          isApprover: true,
        },
      },
      {
        path: "planner",
        components: {
          view: LeavePlanner,
        },
        meta: {
          middleware: Auth,
          breadcrumb: [
            { text: "leave", disabled: true, href: "/" },
            {
              text: "planner",
              disabled: false,
              href: "/leave/planner",
            },
          ],
          isApprover: false,
        },
      },
      {
        path: "planner/application",
        name: "MakeLeavePlannerApplication",
        components: {
          view: LeavePlannerMakeApplication,
        },
        meta: {
          middleware: Auth,
          breadcrumb: [
            { text: "leave", disabled: true, href: "/" },
            {
              text: "planner",
              disabled: false,
              href: "/leave/planner",
            },
            {
              text: "make application",
              disabled: false,
              href: "/leave/planner/application",
            },
          ],
          isApprover: false,
        },
      },
      {
        path: "planner/application/show/:no",
        name: "ViewLeavePlannerApplication",
        components: {
          view: LeavePlannerViewApplication,
        },
        meta: {
          middleware: Auth,
          breadcrumb: [
            { text: "leave", disabled: true, href: "/" },
            {
              text: "planner",
              disabled: false,
              href: "/leave/planner",
            },
            {
              text: "view application",
              disabled: false,
              href: "#",
            },
          ],
          isApprover: false,
        },
      },
      {
        path: "planner/application/reviews",
        name: "LeavePlannerReviews",
        components: {
          view: LeavePlanReviews,
        },
        meta: {
          middleware: Auth,
          breadcrumb: [
            { text: "leave", disabled: true, href: "/" },
            {
              text: "planner",
              disabled: false,
              href: "/leave/planner",
            },
            {
              text: "application-approvals",
              disabled: false,
              href: "planner/application/reviews",
            },
          ],
          isApprover: true,
        },
      },
    ],
  },
];
