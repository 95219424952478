<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="planners"
      :sort-desc="[false, true]"
      :search="listFilter"
      multi-sort
      class="elevation-1"
      ref="leave_planner_list"
      id="scollStyle"
    >
      <!--eslint-disable-next-line -->
      <template v-slot:item.status="{ item }">
        <v-chip label :color="getColor(item.status).color" small>
          {{ item.status }}
        </v-chip>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.employeeName="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex-inline justify-start">
              <v-avatar
                size="28"
                class="primary lighten-4 primary--text text--darken-4"
                style="cursor: pointer"
                v-bind="attrs"
                v-on="on"
              >
                {{ getInitials(item.employeeName).slice(0, 2) }}
              </v-avatar>
              {{ item.employeeName }}
            </div>
          </template>
          <span>{{ item.employeeName }}</span>
        </v-tooltip>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.leaveTypes="{ item }">
        <div>
          {{ item.leavePlannerLines.map((line) => line.leaveType).join(", ") }}
        </div>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.fromDate="{ item }">
        {{ getDateFormat(item.fromDate) }}
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.toDate="{ item }">
        {{ getDateFormat(item.toDate) }}
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-start">
          <template>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="primary lighten-4 mx-1"
                  label
                  v-bind="attrs"
                  v-on="on"
                  @click="reviewApplication(item)"
                >
                  <v-icon color="primary darken-4">mdi-page-next</v-icon>
                </v-chip>
              </template>
              <span>View Application</span>
            </v-tooltip>
          </template>
          <template v-if="item.approvalEntry.status === 'Open'">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="green lighten-4 mx-1"
                  label
                  v-bind="attrs"
                  v-on="on"
                  @click="approve(item.approvalEntry)"
                >
                  <v-icon color="primary darken-4">schedule_send</v-icon>
                </v-chip>
              </template>
              <span>Approve</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="red lighten-4 mx-1"
                  label
                  v-bind="attrs"
                  v-on="on"
                  @click="openRejectDialog(item.approvalEntry)"
                >
                  <v-icon color="primary darken-4">event_busy</v-icon>
                </v-chip>
              </template>
              <span>Reject</span>
            </v-tooltip>
          </template>
        </div>
      </template>
    </v-data-table>
    <!-- reject reason-->
    <v-dialog v-model="rejectDialog" persistent max-width="500">
      <v-card class="mx-auto" width="500">
        <v-card-title>
          <div class="text-body-1">Add reject reason</div>
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="rejectReason"
            label="Reject Reason"
            outlined
            rows="3"
            maxlength="250"
            dense
            clearable
            hint="250 characters max"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            class="text-capitalize"
            small
            @click="rejectDialog = false"
          >
            <v-icon>mdi-close</v-icon>
            close
          </v-btn>
          <v-spacer />
          <v-btn class="red white--text" @click="reject">
            <v-icon class="mx-2">thumb_down</v-icon>
            Reject
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
/* eslint-disable camelcase */
import helpers from "@/packages/cassandra-base/utilities/helpers/helper.js";
export default {
  name: "LeavePlanReviewerList",
  props: {
    leavePlannerApprovals: { type: Array, default: () => [] },
  },
  data() {
    return {
      rejectDialog: false,
      rejectReason: "",
      rejectingPayload: {},
    };
  },

  mounted() {
    Event.$on("re-sync", () => {
      this.$store.dispatch("leavePlanner/getApprovalApplications", {
        approverID: this.userData.employee,
        // status: "Open",
      });
    });
  },

  computed: {
    planners: function () {
      const leavePlannerApplications = this.leavePlannerApprovals;

      return (
        Array.isArray(leavePlannerApplications) &&
        leavePlannerApplications.sort((a, b) => {
          return new Date(b.createdDateTime) - new Date(a.createdDateTime);
        })
      );
    },

    headers: function () {
      return [
        {
          text: "#Code",
          align: "start",
          sortable: true,
          value: "no",
        },
        { text: "Employee", sortable: true, value: "employeeName" },
        { text: "Leave Types", sortable: true, value: "leaveTypes" },
        { text: "Period", sortable: true, value: "period" },
        { text: "Start Date", sortable: true, value: "fromDate" },
        { text: "End Date", sortable: true, value: "toDate" },
        { text: "Status", sortable: true, value: "status" },
        { text: "Actions", sortable: false, value: "actions" },
      ];
    },
    userData: function () {
      return this.$store.getters["auth/user"];
    },
    //get approvals (open)
    approvals() {
      return this.leavePlannerApprovals?.flatMap((obj) => obj.approvalEntry);
    },
    //list filter
    listFilter: {
      get() {
        return this.$store.state.leavePlanner.listFilterValue;
      },
      set(value) {
        this.$store.commit("leavePlanner/MUTATE", {
          state: "listFilterValue",
          data: value,
        });
      },
    },
  },

  methods: {
    getColor: function (status) {
      return helpers.getColor(status);
    },

    getInitials: function (names) {
      return helpers.getInitials(names);
    },

    getDateFormat: function (date) {
      return helpers.getDateFormat(date);
    },

    //aprrovals
    reviewApplication: function (item) {
      this.$router.push({
        name: "ViewLeavePlannerApplication",
        params: {
          no: item.no,
        },
      });
    },
    approve(approvalEntry) {
      this.$confirm.show({
        title: "Approve",
        text: "Are you sure you want to accept & approve this leave plan?",
        onConfirm: () => {
          this.$store.dispatch("leavePlanner/approve", {
            ...approvalEntry,
          });
        },
      });
    },
    openRejectDialog(approvalEntry) {
      this.rejectDialog = true;
      Object.assign(this.rejectingPayload, { ...approvalEntry });
    },
    reject() {
      this.$confirm.show({
        title: "Reject",
        text: "Are you sure you want to reject this leavePlan?",
        onConfirm: () => {
          this.$store.dispatch("leavePlanner/reject", {
            ...this.rejectingPayload,
            reason: this.rejectReason,
          });
          this.rejectDialog = false;
        },
      });
    },
    canReviewLeavePlanner(entry) {
      return Object.values(this.approvals).some(
        (approval) =>
          approval.documentNo === entry.no &&
          approval.approverID === this.userData.employee
      );
    },
  },
};
</script>

<style scoped></style>
