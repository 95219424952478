<script>
export default {
  name: "PageHeader",
  props: ["title", "path"],
};
</script>

<template>
  <v-app-bar
    class="leaveBar mb-2"
    dense
    style="background-color: #f0f3f4"
    :style="{ border: '0' }"
  >
    <div class="text-lg-h5" style="font-weight: 300">
      {{ title }}
    </div>
    <v-spacer></v-spacer>
    <v-breadcrumbs
      customDivider
      divider="/"
      large
      :items="path"
    ></v-breadcrumbs>
  </v-app-bar>
</template>

<style scoped></style>
