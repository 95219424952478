<template>
  <v-dialog persistent v-model="setDialog" width="50vw">
    <v-card>
      <v-list>
        <template v-for="(cashComment, i) in cashComments">
          <v-list-item :key="cashComment.entryNo">
            <v-list-item-content>
              <v-list-item-subtitle> Comment </v-list-item-subtitle>
              <div class="text-body-1 font-weight-bold">
                {{ cashComment.comment }}
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="i + 0.01" />
        </template>
      </v-list>
      <v-card-actions>
        <v-btn color="primary" block @click="setDialog = false">
          close cash comments
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ImprestCashComment",
  props: {
    cashComments: {
      type: Array,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      //
    };
  },
  mounted() {
    // console.log(this.cashComments);
  },
  computed: {
    setDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("setCommentDialog", value);
      },
    },
  },
};
</script>
<style scoped>
span {
  display: block;
}
</style>
