import call from "@kinetics254/cassandra-base/service/http";
import discConstants from "./discConstants";

export default {
  namespaced: true,
  state: {
    raisedDisciplinary: [],
    accusedDisciplinary: [],
    disciplinaryCategories: [],
    disciplinary: {},
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    discGetters: (state) => (val) => state[val],
  },
  actions: {
    getRaisedDisciplinary: ({ commit }, params) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", discConstants.disciplinary(params))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "raisedDisciplinary",
            data: res.data.data,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getAccusedDisciplinary: ({ commit }, params) => {
      commit("loader/SET_LOADING", true, { root: true });
      console.log("params", params);
      call("get", discConstants.disciplinary(params))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "accusedDisciplinary",
            data: res.data.data,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getDisciplinaryCategories: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", discConstants.disciplinaryCategories)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "disciplinaryCategories",
            data: res.data.data,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getDisciplinary: ({ commit }, params) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", discConstants.disciplinary(params))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "disciplinary",
            data: res.data.data[0],
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    updateDisciplinary: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", discConstants.updateDisciplinary, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Disciplinary updated successfully");
          Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    deleteDisciplinary: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", discConstants.deleteDisciplinary, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Disciplinary deleted successfully");
          dispatch("getRaisedDisciplinary", {
            raisedBy: data.raisedBy,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    addDisciplinary: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", discConstants.addDisciplinary, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Disciplinary added successfully");
          Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    deleteDisciplinaryLine: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", discConstants.deleteDisciplinaryLine, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Disciplinary line deleted successfully");
          dispatch("getDisciplinary", {
            no: data.documentNo,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    respondDisciplinary: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", discConstants.respondDisciplinary, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Disciplinary response saved successfully");
          Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
  },
};
