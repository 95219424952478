import call from "@kinetics254/cassandra-base/service/http";
import AdvanceConstants from "./AdvanceConstants";

export default {
  namespaced: true,
  state: {
    advance: [],
    loanType: [],
    periods: [],
    balance: "",
    currencies: [],
    approvalEntries: [],
  },
  mutations: {
    SET_ADVANCE: (state, payload) => {
      state.advance = payload;
    },

    SET_LOAN_TYPE: (state, payload) => {
      state.loanType = payload;
    },

    SET_PERIODS: (state, payload) => {
      state.periods = payload;
    },

    SET_BALANCE: (state, payload) => {
      state.balance = payload;
    },
    SET_APPROVAL_ENTRIES: (state, payload) => {
      state.approvalEntries = payload;
    },

    SET_CURRENCIES: (state, payload) => {
      state.currencies = payload;
    },
  },
  getters: {
    advance: (state) => state.advance,
    periods: (state) => state.periods,
    loanType: (state) => state.loanType,
    balance: (state) => state.balance,
    currencies: (state) => state.currencies,
    approvalEntries: (state) => state.approvalEntries,
  },

  actions: {
    getPeriods: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", AdvanceConstants.periods)
        .then((res) => {
          commit("SET_PERIODS", res.data.data);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    getSalaryAdvance: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", AdvanceConstants.advance())
        .then((res) => {
          commit("SET_ADVANCE", res.data.data);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    saveSalaryAdvance: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", AdvanceConstants.advance(), data)
        .then((res) => {
          dispatch("getSalaryAdvance");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    updateSalaryAdvance: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("patch", AdvanceConstants.advance(), data)
        .then((res) => {
          dispatch("getSalaryAdvance");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    deleteSalaryAdvance: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("delete", AdvanceConstants.advance(data))
        .then((res) => {
          dispatch("getSalaryAdvance");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    getLoanTypes: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", AdvanceConstants.loanType)
        .then((res) => {
          commit("SET_LOAN_TYPE", res.data.data);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    getBalance: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", AdvanceConstants.balance)
        .then((res) => {
          commit("SET_BALANCE", res.data.data);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    SendApprovalRequest: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", AdvanceConstants.SendApprovalRequest, data)
        .then((res) => {
          dispatch("getSalaryAdvance");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    CancelApprovalRequest: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", AdvanceConstants.CancelApprovalRequest, data)
        .then((res) => {
          dispatch("getSalaryAdvance");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getApprovalEntries: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", AdvanceConstants.approvalEntries(payload))
        .then((res) => {
          commit("SET_APPROVAL_ENTRIES", res.data.data);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
  },
};
