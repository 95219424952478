export default {
  approvalEntries: (params) =>
    `approvals/approvalEntries?${formatParams(params)}`,
  nestedApprovalEntries: (params) =>
    `approvals/nestedApprovalEntries?${formatParams(params)}`,
  getDocumentAttachments: (params) =>
    `approvals/documentAttachments?${formatParams(params)}`,
  approve: "approvals/approve",
  reject: "approvals/reject",
  delegate: "approvals/delegate",
};

function formatParams(params) {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
}
