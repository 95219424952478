export default {
  order: 10,
  name: "Disciplinary",
  icon: "gavel",
  to: "/disciplinary/raised",
  subs: [
    {
      name: "My Raised Requests",
      icon: "group",
      to: "/disciplinary/raised",
    },
    {
      name: "My Accused Requests",
      icon: "switch_account",
      to: "/disciplinary/accused",
    },
  ],
};
