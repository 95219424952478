import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{staticClass:"px-3 py-3",attrs:{"elevation":"0"}},[_c(VCardTitle,[_c(VToolbar,{attrs:{"elevation":"1"}},[_c(VBtn,{staticClass:"mr-3",on:{"click":function($event){return _vm.$router.back()}}},[_c(VIcon,[_vm._v("mdi-arrow-left")]),_vm._v(" Back ")],1),_c(VToolbarTitle,[_c('span',{staticClass:"headline"},[_vm._v("Committee Report")])]),_c(VSpacer)],1)],1),_c('v-card-content',[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.reviewDocumentKpis},scopedSlots:_vm._u([{key:"item.objectiveID",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getObjectiveDescription(item)))])]}},{key:"item.committeeRatingScore",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.committeeRatingScore)+" % ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }