import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto",attrs:{"rounded":"","flat":""}},[_c(VCardTitle,[_vm._v(" Warnings ")]),_c(VCardTitle,[_c(VTextField,{staticClass:"mx-2",staticStyle:{"width":"200px"},attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.searchAccused),callback:function ($$v) {_vm.searchAccused=$$v},expression:"searchAccused"}})],1),_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.accusedHeaders,"items":_vm.accusedItems,"search":_vm.searchAccused},scopedSlots:_vm._u([{key:"item.documentDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("date")(item.documentDate)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{class:("" + (_vm.getColor(item.status))),attrs:{"label":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.raisedBy",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex"},'div',attrs,false),on),[_c(VAvatar,{staticClass:"secondary mr-2",attrs:{"size":"35"}},[_vm._v(" "+_vm._s(_vm.getInititals(item.raisedByName))+" ")]),_c('div',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(item.raisedByName)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Employee: "+_vm._s(item.raisedBy))])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"teal lighten-4","label":"","small":""},on:{"click":function($event){return _vm.viewWaring(item)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"teal darken-4","small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("view")])]),_c('div',{staticClass:"d-flex justify-start"},[(item.status === 'Waiting Response')?_c(VBtn,{staticClass:"primary text-capitalize",attrs:{"elevation":"0"},on:{"click":function($event){return _vm.respondTo(item)}}},[_c(VIcon,{staticClass:"mx-2",attrs:{"color":"amber"}},[_vm._v("waving_hand")]),_vm._v(" Respond ")],1):_vm._e()],1)]}}])})],1),_c(VDialog,{attrs:{"max-width":"670px"},model:{value:(_vm.warningDialog),callback:function ($$v) {_vm.warningDialog=$$v},expression:"warningDialog"}},[(_vm.warning)?_c(VCard,{attrs:{"tile":""}},[_c(VCardTitle,[_vm._v("Warning Details ")]),_c(VCardText,{staticClass:"mt-3"},[_c('p',{},[_c('strong',[_vm._v("Raised By ")]),_vm._v(": "+_vm._s(_vm.warning.raisedByName)+" ")]),_vm._l((_vm.warning.disciplinaryLine),function(item,index){return _c('div',{key:index},[_c('h3',{},[_vm._v("category")]),_c('p',{},[_vm._v(_vm._s(item.categoryDescription))]),_c('h3',{},[_vm._v("Details")]),_c('p',{},[_vm._v(_vm._s(item.details))])])})],2),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"red"},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("Cancel")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }