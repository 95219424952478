import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VForm,{ref:"HandoverForm",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VAutocomplete,{staticClass:"mt-1 leaveInputs",attrs:{"items":_vm.handoverEmployees,"placeholder":"Hand Over To","persistent-placeholder":"","rules":_vm.handoverRequired ? _vm.rules : [],"label":"Hand Over To","clearable":"","filled":"","item-text":"first_name","item-value":"number","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c(VChip,_vm._b({attrs:{"input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){return _vm.removeHandoverTo(data.item.number)}}},'v-chip',data.attrs,false),[_c(VChip,{staticClass:"primary--text text--darken-4",attrs:{"left":"","x-small":"","color":"primary lighten-4"}},[_vm._v(" "+_vm._s(data.item.number)+" ")]),_c('span',[_vm._v(_vm._s(((data.item.firstName) + " " + (data.item.middleName))))])],1)]}},{key:"prepend-inner",fn:function(){return [_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" how_to_reg ")])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c(VListItem,_vm._g(_vm._b({attrs:{"ripple":""}},'v-list-item',attrs,false),on),[_c(VAvatar,{staticClass:"primary--text text--darken-4 mr-3",attrs:{"color":"primary lighten-4","size":"40"}},[_vm._v(" "+_vm._s(("" + (item.firstName[0] || "") + (item.lastName[0] || "")))+" ")]),_c(VListItemContent,[_c(VListItemTitle,[_c('span',[_vm._v(" "+_vm._s(item.firstName + " " + item.lastName)+" "),_c(VChip,{staticClass:"primary--text text--darken-4 mx-2",attrs:{"left":"","x-small":"","color":"primary lighten-4"}},[_vm._v(" "+_vm._s(item.number)+" ")])],1)]),_c(VListItemSubtitle,[_vm._v(_vm._s(item.email))])],1),_c(VListItemAction,[_c(VChip,{staticStyle:{"position":"absolute","right":"1em","top":"1em"},attrs:{"small":"","label":"","color":"primary lighten-4"}},[_vm._v(" "+_vm._s(item.job_title)+" ")])],1)],1)]}}]),model:{value:(_vm.formData.hand_over_to),callback:function ($$v) {_vm.$set(_vm.formData, "hand_over_to", $$v)},expression:"formData.hand_over_to"}}),_c(VTextField,{staticClass:"mt-1 leaveInputs text-capitalize",attrs:{"placeholder":"Reason For Leave Handover","clearable":"","filled":""},model:{value:(_vm.formData.handoverDescription),callback:function ($$v) {_vm.$set(_vm.formData, "handoverDescription", $$v)},expression:"formData.handoverDescription"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }