import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{ref:"leave_planner_list",staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.planners,"sort-desc":[false, true],"multi-sort":"","id":"scollStyle"},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"label":"","color":_vm.getColor(item.status).color,"small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.leaveTypes",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.leavePlannerLines.map(function (line) { return line.leaveType; }).join(", "))+" ")])]}},{key:"item.fromDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateFormat(item.fromDate))+" ")]}},{key:"item.toDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateFormat(item.toDate))+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("pluralize")(item.duration,"day", "days"))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-start"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"primary lighten-4 mx-1",attrs:{"label":""},on:{"click":function($event){return _vm.viewApplication(item)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"primary darken-4"}},[_vm._v("pageview")])],1)]}}],null,true)},[_c('span',[_vm._v("view application")])]),(item.status === 'Open')?[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"primary lighten-4 mx-1",attrs:{"label":""},on:{"click":function($event){return _vm.editApplication(item)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"primary darken-4"}},[_vm._v("mode_edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Application")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"primary lighten-4 mx-1",attrs:{"label":""},on:{"click":function($event){return _vm.sendForApproval(item)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"primary darken-4"}},[_vm._v("schedule_send")])],1)]}}],null,true)},[_c('span',[_vm._v("Send for approval")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"primary lighten-4 mx-1",attrs:{"label":""},on:{"click":function($event){return _vm.deleteApplication(item)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"primary darken-4"}},[_vm._v("event_busy")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Application")])])]:(item.status === 'Pending')?[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"primary lighten-4 mx-1",attrs:{"label":"","ripple":""},on:{"click":function($event){return _vm.cancelApproval(item)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"primary darken-4"}},[_vm._v("cancel_schedule_send")])],1)]}}],null,true)},[_c('span',[_vm._v("cancel approval")])])]:_vm._e()],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }