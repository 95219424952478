import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.documentKpis},scopedSlots:_vm._u([{key:"item.reviewID",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"supervisor-review-table"},[_vm._v(_vm._s(item["reviewID"]))])]}},{key:"item.stage",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"supervisor-review-table"},[_vm._v(_vm._s(item["currentStage"]))])]}},{key:"item.selfRatingScore",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"supervisor-review-table"},[_vm._v(_vm._s(_vm.MyScore(item))+"%")])]}},{key:"item.reviewerRatingScore",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"supervisor-review-table"},[_vm._v(_vm._s(_vm.SupervisorScore(item))+"%")])]}},{key:"item.overalScore",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"supervisor-review-table"},[_vm._v(_vm._s(_vm.OverallScore(item))+"%")])]}},{key:"item.documentStatus",fn:function(ref){
var item = ref.item;
return [_c(VChip,{directives:[{name:"show",rawName:"v-show",value:(item.documentStatus),expression:"item.documentStatus"}],attrs:{"small":"","color":_vm.getColor(item.documentStatus).color,"dark":""}},[_c('span',{staticClass:"supervisor-review-table"},[_vm._v(_vm._s(item["documentStatus"]))])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VBtnToggle,[(item.documentStatus === 'Reviewed')?_c(VBtn,{staticClass:"mx-2 white--text",attrs:{"small":"","color":"primary","tile":"","link":""},on:{"click":function($event){return _vm.downloadEmployeeReport(item.code)}}},[_c(VIcon,{attrs:{"left":"","color":"white"}},[_vm._v(" mdi-download ")]),_vm._v(" report ")],1):_vm._e(),_c(VBtn,{staticClass:"white--text",attrs:{"small":"","color":"primary","tile":""},on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v(" Add Score "),_c(VIcon,{attrs:{"right":"","color":"white"}},[_vm._v("mdi-arrow-right")])],1)],1)]}}])}),_c(VCardActions,[_c(VBtn,{staticClass:"white--text",attrs:{"small":"","color":"primary","tile":""},on:{"click":function($event){return _vm.submitReport(_vm.item)}}},[_vm._v(" Submit Report ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }