<template>
  <div class="supervisor-reviews">
    <v-card flat class="mt-3">
      <v-card-title>
        <span class="headline text-h6"> Second Supervisor Appraisals </span>
        <v-spacer />
        <!-- <v-btn
          v-if="supervisorReviews.length>0"
          small
          color="primary"
          class="mx-2 white--text"
          link
          :to="{ name: 'supervisorReport'}" >
          View Report
        </v-btn> -->
        <v-btn
          v-if="allEmployeesReviewed"
          small
          color="primary"
          class="mx-2 white--text"
          link
          @click="downloadFile"
        >
          <v-icon left color="white"> mdi-download </v-icon>
          Report
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4" offset="8">
            <v-select
              v-if="supervisorReviews.length > 0"
              v-model="selectedPeriod"
              prepend-inner-icon="filter_alt"
              color="primary"
              dense
              :items="periods"
              :item-text="(item) => item.code"
              :item-value="(item) => item.code"
              v-bind="attrs"
              v-on="on"
              label="Filter by period"
              @change="filterAppraisalList"
            ></v-select>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <AppraisalSupervisorReviewTable
                  :supervisorReviews="supervisorReviews"
                  :tab="'2nd'"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import RouterMixin from "@kinetics254/cassandra-base/mixins/RouterMixin";
import AppraisalMixin from "../AppraisalMixin";
import FileMixin from "@kinetics254/cassandra-base/mixins/FileMixin";
import AppraisalSupervisorReviewTable from "@/packages/cassandra-appraisal/modules/appraisalv1/components/AppraisalSupervisorReviewTable";

export default {
  name: "SecondSupervisorReviews",
  mixins: [RouterMixin, AppraisalMixin, FileMixin],
  components: { AppraisalSupervisorReviewTable },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("appraisal/getPeriods");
      v.$store.dispatch("appraisal/getPeriods");
      v.$store.dispatch("appraisal/getKpi");
      v.$store.dispatch("appraisal/getRatingScale");
      v.$store.commit("appraisal/SET_REVIEW", false);
      v.$store.dispatch("appraisal/getPeerReviews");
      v.$store.dispatch("appraisal/getSecondPeerReviews");
    });
  },
  data: () => ({
    selectedPeriod: "",
    period: "",
    tab: null,
  }),
  computed: {
    // supervisor reviews
    supervisorReviews() {
      const secondSupervisorReviews = this.$store.getters[
        "appraisal/appraisalGetter"
      ]("secondSupervisorReviews");
      if (this.period !== "") {
        return {
          secondSupervisorReviews: secondSupervisorReviews.filter(
            (a) => a.period === this.period
          ),
        };
      } else {
        return secondSupervisorReviews;
      }
    },
    // check if all employees have been reviewed
    allEmployeesReviewed() {
      if (this.supervisorReviews.length > 0) {
        var reviewedAppraisals = this.supervisorReviews.filter(
          (review) => review.documentStatus !== "Reviewed"
        );
        if (reviewedAppraisals.length > 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    userData: function () {
      return this.$store.getters["auth/user"];
    },
    document() {
      return this.$store.getters["appraisal/appraisalGetter"](
        "supervisorReport"
      );
    },
    employeeReport() {
      return this.$store.getters["appraisal/appraisalGetter"](
        "appraisalReport"
      );
    },
  },
  methods: {
    filterAppraisalList() {
      this.period = this.selectedPeriod;
    },
  },
  watch: {
    document() {
      this.downloadLink(this.document);
    },
    employeeReport() {
      this.downloadLink(this.employeeReport);
    },
  },
};
</script>

<style scoped>
@import "../styles/appraisal.css";
</style>
