export default {
  leaveEmployees: "hrmis/leave_employee",
  employee: (no) => `hrmis/employee?no=${no}`,
  getJobDescription: "cu/hrmis_cu/getJDreport",
  profilePicture: "cu/hrmis_cu/getEmployeePicture",
  jobs: "hrmis/job",
  TRAINING_PROGRAMMES: `training/training_programmes`,
  training_schedules: "training/trainee_schedules",
  trainees: `training/trainees`,
  taskSetup: "projects/setups/taskSetup",
  activitiesSetup: "projects/setups/activitiesSetup",
};
