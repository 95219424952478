<script>
import PageHeader from "../../components/planner/PageHeader.vue";
import moment from "moment/moment";

export default {
  name: "MakeApplication",
  components: {
    PageHeader,
  },

  data: function () {
    return {
      isPlannerValid: false,
      isPlannerLinesValid: false,
      step: 1,
      pannerValid: true,
      hasError: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      plannerForm: {
        no: null,
        employeeNo: null,
        period: null,
        fromDate: null,
        toDate: null,
        status: "Open",
        fromDateMenu: false,
        toDateMenu: false,
      },
      plannerLines: [
        {
          documentNo: null,
          lineNo: null,
          leaveType: null,
          fromDate: null,
          toDate: null,
          duration: 0,
          fromDateMenu: false,
          toDateMenu: false,
          edit: true,
        },
      ],
      uuid: 1000,
    };
  },

  mounted() {
    this.$store.dispatch("leavePlanner/getLeavePeriods");

    this.plannerForm.fromDate = this.getPlannerStartDate();

    this.fetchPlanners();

    this.fetchPlannerLines();

    if (this.$route.query.no) {
      this.$store.dispatch("leavePlanner/getPlanner", this.$route.query.no);
    }

    this.plannerForm.employeeNo = this.userData.employee;

    Event.$on("plannerSaved", (response) => {
      if (response.hasError) {
        this.isValid = false;
      } else {
        this.step++;
        this.plannerForm.no = response.planner.no;
        this.plannerLines.forEach((line, key) => {
          if (!line.documentNo) {
            this.plannerLines[key].documentNo = response.planner.no;
          }
        });

        if (!this.$route.query.no) {
          this.$router.replace({
            path: "/leave/planner/application",
            query: {
              no: this.plannerForm.no,
            },
          });
        }
      }
    });

    Event.$on("plannerLineSaved", (response) => {
      if (response.hasError) {
        this.isValid = false;
      } else if (response.lineItem) {
        this.plannerLines[response.position].lineNo = response.lineItem.lineNo;
        this.plannerLines[response.position].edit = false;
        this.plannerLines[response.position].duration =
          response.lineItem.duration;

        this.fetchPlannerLines();

        this.generateUuid();
      }
    });

    Event.$on("plannerLineDeleted", (response) => {
      if (response.hasError) {
        this.isValid = false;
      } else {
        this.removePlannerLine(response.position);
        this.fetchPlannerLines();
      }
    });
  },

  computed: {
    leaveTypes: function () {
      return this.$store.getters["leavePlanner/leavePlannerGetter"](
        "leaveTypes"
      );
    },

    leavePeriods: function () {
      return this.$store.getters["leavePlanner/leavePlannerGetter"](
        "leavePeriods"
      );
    },

    planner: function () {
      return this.$store.getters["leavePlanner/leavePlannerGetter"]("planner");
    },

    userData: function () {
      return this.$store.getters["auth/user"];
    },

    title: function () {
      return this.plannerForm.no
        ? `Leave Planner (#${this.plannerForm.no}) Form`
        : "Leave Planner Form";
    },

    pickedPlannerDates: function () {
      let planners =
        this.$store.getters["leavePlanner/leavePlannerGetter"]("planners");

      if (this.$route.query.no) {
        planners = planners.filter(
          (planner) => planner.no !== this.$route.query.no
        );
      }

      const dates = [];
      planners.forEach((line) => {
        dates.push(...this.getDatesInRange(line.fromDate, line.toDate));
      });

      return dates;
    },

    pickedEntryDates: function () {
      const lines =
        this.$store.getters["leavePlanner/leavePlannerGetter"]("plannerLines");

      const dates = [];
      lines.forEach((line) => {
        dates.push(...this.getDatesInRange(line.fromDate, line.toDate));
      });

      return dates;
    },

    totalDuration: function () {
      return this.plannerLines.reduce((total, line) => {
        return total + line.duration;
      }, 0);
    },
  },

  watch: {
    planner: function (newVal) {
      if (newVal) {
        this.plannerForm.no = newVal.no;
        this.plannerForm.employeeNo = newVal.employeeNo;
        this.plannerForm.period = newVal.period;
        this.plannerForm.fromDate = newVal.fromDate;
        this.plannerForm.toDate = newVal.toDate;
        this.plannerForm.status = newVal.status;

        if (newVal.leavePlannerLines.length) {
          const lines = [];
          newVal.leavePlannerLines.forEach((line, key) => {
            lines[key] = {
              documentNo: line.documentNo,
              lineNo: line.lineNo,
              leaveType: line.leaveType,
              fromDate: line.fromDate,
              toDate: line.toDate,
              duration: line.duration,
              fromDateMenu: false,
              toDateMenu: false,
              edit: false,
            };
          });

          this.plannerLines = lines;
        }
      }
    },

    "plannerForm.period": function (newVal) {
      if (newVal) {
        this.$store.dispatch("leavePlanner/getLeaveTypes", {
          employeeNo: this.userData.employee,
          period: newVal,
        });
      }
    },
  },

  methods: {
    submitPlanner: function () {
      this.$store.dispatch("leavePlanner/savePlanner", this.plannerForm);
    },

    submitPlannerLine: function (index) {
      this.$store.dispatch("leavePlanner/savePlannerLine", {
        position: index,
        lineItem: this.plannerLines[index],
      });
    },

    deletePlannerLine: function (index) {
      if (this.plannerLines[index].lineNo) {
        this.$store.dispatch("leavePlanner/deletePlannerLine", {
          position: index,
          lineItem: this.plannerLines[index],
        });
      } else {
        this.removePlannerLine(index);
      }
    },

    removePlannerLine: function (index) {
      this.plannerLines.splice(index, 1);
      this.generateUuid();
    },

    addPlannerLine: function () {
      this.plannerLines.push({
        documentNo: this.plannerForm.no,
        lineNo: null,
        leaveType: null,
        fromDate: this.getNextLineStartDate(),
        toDate: this.getNextLineStartDate(),
        duration: 0,
        fromDateMenu: false,
        toDateMenu: false,
        edit: true,
      });

      this.generateUuid();
    },

    goNext: function () {
      if (this.step === 1) {
        this.submitPlanner();
      } else {
        this.step++;
      }
    },

    goBack: function () {
      if (this.step === 1) {
        this.$router.push({ path: "/leave/planner" });
      } else {
        this.step--;
      }
    },

    finish: function () {
      this.$router.push({
        name: "ViewLeavePlannerApplication",
        params: {
          no: this.plannerForm.no,
        },
      });
    },

    generateUuid: function () {
      this.uuid++;
    },

    allowedEntryDates: function (val) {
      return !this.pickedEntryDates.includes(val);
    },

    allowedPlannerDates: function (val) {
      return !this.pickedPlannerDates.includes(val);
    },

    getDatesInRange: function (startDate, endDate) {
      startDate = new Date(startDate);
      endDate = new Date(endDate);

      var dates = [];
      var currentDate = startDate;

      while (currentDate <= endDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));

        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dates;
    },

    fetchPlanners: function () {
      this.$store.dispatch("leavePlanner/getPlanners", {
        employeeNo: this.userData.employee,
      });
    },

    fetchPlannerLines: function () {
      this.$store.dispatch("leavePlanner/getPlannerLines", {
        employeeNo: this.userData.employee,
      });
    },

    getLeaveTypeTotalDuration: function (code) {
      const lines = this.plannerLines.filter((line) => line.leaveType === code);

      return lines.reduce((total, line) => {
        return total + line.duration;
      }, 0);
    },

    updateAllLinesFromDate: function () {
      this.plannerForm.toDate = this.plannerForm.fromDate;

      for (let i = 0; i < this.plannerLines.length; i++) {
        this.plannerLines[i].fromDate = this.plannerForm.fromDate;
      }
    },

    updateAllLinesToDate: function () {
      for (let i = 0; i < this.plannerLines.length; i++) {
        this.plannerLines[i].toDate = this.plannerForm.toDate;
      }
    },

    updateLineToDate: function (key) {
      this.plannerLines[key].toDate = this.plannerLines[key].fromDate;
    },

    getPlannerStartDate: function () {
      return new Date().toISOString().split("T")[0];
    },

    getNextLineStartDate: function () {
      const currLineIndex = this.plannerLines.length - 1;
      if (currLineIndex < 0) {
        return null;
      }

      let currDate = new Date(this.plannerLines[currLineIndex].toDate);
      return this.getNextDate(currDate);
    },

    getNextDate: function (currDate) {
      let nextDate = new Date(currDate);
      nextDate.setDate(currDate.getDate() + 1);

      return nextDate.toISOString().split("T")[0];
    },
  },
};
</script>

<template>
  <div>
    <page-header :title="title" :path="$router.currentRoute.meta.breadcrumb" />

    <v-container class="mt-n3">
      <v-card class="mx-auto">
        <div class="d-flex justify-space-between">
          <div class="mx-2 my-2">
            <v-chip v-if="totalDuration" color="primary" label>
              Total planned: {{ totalDuration | pluralize("day", "days") }}
            </v-chip>
          </div>
          <v-spacer />
          <div class="mx-2 my-2">
            <v-btn
              color="red"
              text
              class="btnActions"
              style="background-color: #ffcdd2"
              link
              to="/leave/planner"
            >
              <v-icon>mdi-close</v-icon>
              close
            </v-btn>
          </div>
        </div>
        <v-divider></v-divider>
        <v-container class="container--width">
          <v-stepper
            class="formStepper"
            v-model="step"
            alt-labels
            elevation="0"
            vertical
          >
            <v-stepper-header>
              <v-stepper-step
                :complete="step > 1"
                :color="pannerValid ? 'green accent-2' : 'red accent-2'"
                :step="pannerValid ? '1' : '!'"
                class="formStep"
              >
                Details
              </v-stepper-step>

              <v-divider class="stepDivider"></v-divider>

              <v-stepper-step
                :complete="step > 2"
                color="green accent-2"
                step="2"
                class="formStep"
              >
                Leave Entries
              </v-stepper-step>

              <v-divider class="stepDivider"></v-divider>

              <v-stepper-step
                :complete="step > 3"
                color="green accent-2"
                step="3"
                class="formStep"
              >
                Summary
              </v-stepper-step>
            </v-stepper-header>
            <div>
              <v-stepper-items class="stepperItems">
                <v-form ref="applicationForm" v-model="isPlannerValid">
                  <v-stepper-content step="1">
                    <v-container>
                      <v-row>
                        <v-col cols="12" class="mb-n6">
                          <v-autocomplete
                            v-model="plannerForm.period"
                            :items="leavePeriods"
                            dense
                            outlined
                            :item-text="(item) => item.name"
                            :item-value="(item) => item.code"
                            :rules="[rules.required]"
                            :readonly="plannerForm.status !== 'Open'"
                          >
                            <template v-slot:label>
                              <label>
                                Leave Period
                                <span class="required">*</span>
                              </label>
                            </template>
                          </v-autocomplete>
                        </v-col>

                        <v-col cols="12" md="6" class="mb-n6">
                          <v-menu
                            v-model="plannerForm.fromDateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="plannerForm.fromDate"
                                label="From Date"
                                color="primary"
                                dense
                                outlined
                                :rules="[rules.required]"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="plannerForm.fromDate"
                              :allowed-dates="allowedPlannerDates"
                              @input="plannerForm.fromDateMenu = false"
                              @click:date="updateAllLinesFromDate()"
                              :min="getPlannerStartDate()"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col cols="12" md="6" class="mb-n6">
                          <v-menu
                            v-model="plannerForm.toDateMenu"
                            :allowed-dates="allowedPlannerDates"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="plannerForm.toDate"
                                label="To Date"
                                color="primary"
                                dense
                                outlined
                                :rules="[rules.required]"
                                readonly
                                :disabled="!plannerForm.fromDate"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="plannerForm.toDate"
                              :allowed-dates="allowedPlannerDates"
                              @input="plannerForm.toDateMenu = false"
                              :min="plannerForm.fromDate"
                              @click:date="updateAllLinesToDate()"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-stepper-content>
                </v-form>
                <v-form ref="applicationForm" v-model="isPlannerLinesValid">
                  <v-stepper-content step="2">
                    <v-container :key="`container-${uuid}`">
                      <v-card
                        v-for="(item, key) in this.plannerLines"
                        :key="`line-${key}`"
                        elevation="2"
                        outlined
                        shaped
                        tile
                        class="px-2"
                      >
                        <v-card-title>
                          <div>
                            Entry #{{ key + 1 }}
                            <span v-if="item.lineNo"
                              >(LineNo: {{ item.lineNo }})</span
                            >
                          </div>
                          <v-spacer />
                          <v-chip v-if="item.duration" color="primary" label>
                            Duration:
                            {{ item.duration | pluralize("day", "days") }}
                          </v-chip>
                        </v-card-title>
                        <v-row class="mb-2">
                          <v-col cols="12" class="mb-n6">
                            <v-autocomplete
                              v-model="plannerLines[key].leaveType"
                              :items="leaveTypes"
                              dense
                              outlined
                              :item-text="(type) => type.description"
                              :item-value="(type) => type.code"
                              :rules="[rules.required]"
                              :disabled="!plannerLines[key].edit"
                            >
                              <template v-slot:label>
                                <label>
                                  Leave Type
                                  <span class="required">*</span>
                                </label>
                              </template>
                            </v-autocomplete>
                          </v-col>

                          <v-col cols="12" md="6" class="mb-n6">
                            <v-menu
                              v-model="plannerLines[key].fromDateMenu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="plannerLines[key].fromDate"
                                  label="From Date"
                                  color="primary"
                                  dense
                                  outlined
                                  :rules="[rules.required]"
                                  readonly
                                  :disabled="!plannerLines[key].edit"
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="plannerLines[key].fromDate"
                                :allowed-dates="allowedEntryDates"
                                @input="plannerLines[key].fromDateMenu = false"
                                @click:date="updateLineToDate(key)"
                                :min="plannerForm.fromDate"
                                :max="plannerForm.toDate"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" md="6" class="mb-n6">
                            <v-menu
                              v-model="plannerLines[key].toDateMenu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="plannerLines[key].toDate"
                                  label="To Date"
                                  color="primary"
                                  dense
                                  outlined
                                  :rules="[rules.required]"
                                  readonly
                                  :disabled="
                                    !plannerLines[key].fromDate ||
                                    !plannerLines[key].edit
                                  "
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="plannerLines[key].toDate"
                                :allowed-dates="allowedEntryDates"
                                @input="plannerLines[key].toDateMenu = false"
                                :min="plannerLines[key].fromDate"
                                :max="plannerForm.toDate"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>

                        <v-card-actions>
                          <v-btn
                            v-if="item.lineNo"
                            color="red lighten-2"
                            outlined
                            @click="deletePlannerLine(key)"
                          >
                            Delete
                          </v-btn>
                          <template v-else>
                            <v-btn
                              v-if="plannerLines.length > 1"
                              color="red lighten-2"
                              outlined
                              @click="removePlannerLine(key)"
                            >
                              Cancel
                            </v-btn>
                          </template>

                          <v-spacer />
                          <v-btn
                            v-if="plannerLines[key].edit"
                            color="green lighten-2"
                            outlined
                            @click="submitPlannerLine(key)"
                          >
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>

                      <v-btn
                        v-if="!plannerLines.some((line) => line.edit)"
                        class="mt-2"
                        color="green lighten-2"
                        block
                        @click="addPlannerLine"
                      >
                        <v-icon left> mdi-pencil</v-icon>
                        Add New Entry
                      </v-btn>

                      <div class="mt-2 d-flex justify-content-center">
                        <v-chip-group active-class="primary--text" column dark>
                          <v-chip v-for="leave in leaveTypes" :key="leave.code">
                            {{ leave.leaveType }} :
                            {{
                              (leave.balance -
                                getLeaveTypeTotalDuration(leave.code))
                                | pluralize("day", "days")
                            }}
                            remaining
                          </v-chip>
                        </v-chip-group>
                      </div>
                    </v-container>
                  </v-stepper-content>
                </v-form>

                <div>
                  <v-stepper-content step="3">
                    <v-card class="mx-auto" tile>
                      <v-card-title> Details</v-card-title>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title
                            >Year {{ plannerForm.period }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >From {{ plannerForm.fromDate | formatDate }} to
                            {{ plannerForm.toDate | formatDate }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-card-title> Entries</v-card-title>
                      <v-list-item
                        two-line
                        v-for="(item, key) in plannerLines.filter(
                          (line) => line.lineNo
                        )"
                        :key="key"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.leaveType }} Leave
                            <v-chip color="primary" light small>
                              {{ item.duration | pluralize("day", "days") }}
                            </v-chip>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            From {{ item.fromDate | formatDate }} to
                            {{ item.toDate | formatDate }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-stepper-content>
                </div>
              </v-stepper-items>
            </div>
          </v-stepper>
        </v-container>
        <v-card-actions>
          <v-col cols="4"></v-col>
          <v-col cols="4">
            <v-row>
              <v-col cols="12" md="6">
                <v-btn elevation="4" block class="btnActions" @click="goBack">
                  <v-icon class="mx-2">arrow_back</v-icon>
                  Go back
                </v-btn>
              </v-col>
              <v-col cols="12" md="6">
                <div v-if="step !== 3">
                  <v-btn
                    color="primary"
                    elevation="4"
                    block
                    :disabled="
                      (step === 1 && !isPlannerValid) ||
                      (step === 2 && !isPlannerLinesValid)
                    "
                    class="btnActions"
                    @click="goNext"
                  >
                    continue
                    <v-icon class="mx-2">arrow_forward</v-icon>
                  </v-btn>
                </div>
                <div v-else>
                  <v-tooltip v-if="plannerLines.some((line) => line.edit)" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        block
                        class="btnActions"
                        style="color: #fff"
                        elevation="2"
                        :disabled="true"
                        @click="finish"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Finish
                        <v-icon class="mx-3">save</v-icon>
                      </v-btn>
                    </template>
                    <span>You have unsaved entries</span>
                  </v-tooltip>
                  <v-btn
                    v-else
                    color="primary"
                    block
                    class="btnActions"
                    style="color: #fff"
                    elevation="2"
                    @click="finish"
                  >
                    Finish
                    <v-icon class="mx-3">save</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4"></v-col>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<style scoped></style>
