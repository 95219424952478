import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
let subs = [
  {
    name: "User Requisition",
    icon: "recent_actors",
    to: "/requisition/list/VXNlciBSZXF1aXNpdGlvbg==",
  },
  {
    name: "Purchase Requisition",
    icon: "payments",
    to: "/requisition/list/UHVyY2hhc2UgUmVxdWlzaXRpb24=",
  },
  {
    name: "Store Requisition",
    icon: "archive",
    to: "/requisition/list/U3RvcmUgUmVxdWlzaXRpb24=",
  },
];

subs = subs.filter((d) =>
  AuthService.user && AuthService.user.captions.requisitions
    ? AuthService.user.captions.requisitions.includes(d.name)
    : subs
);

export default {
  order: 4,
  name: "Requisition",
  icon: "receipt_long",
  to: "/requisition/ReqDashboard",
  hasSubs: 3,
  subs: subs,
};
