<template>
  <v-container class="fade-transition">
    <!--BEGIN summary stats cards-->
    <v-row class="statistic-cards mt-n4">
      <!--BEGIN the pending balance card-->
      <v-col lg="3" cols="sm" class="pb-2">
        <v-hover v-slot="{ hover }" open-delay="100">
          <v-card
            @click="status = 'All'"
            :elevation="hover ? 5 : 0"
            :class="{ 'statistic-cards-hover': hover }"
            style="border-left: var(--v-primary-base) solid"
          >
            <v-row class="no-gutters">
              <div class="col-9">
                <v-row>
                  <div class="col-auto">
                    <div class="primary fill-height">&nbsp;</div>
                  </div>
                  <div class="col pa-3 py-4 primary--text">
                    <h5 class="text-truncate text-uppercase">ALL</h5>
                    <h1>{{ total }}</h1>
                  </div>
                </v-row>
              </div>
              <div class="col-3">
                <v-card-actions>
                  <v-avatar color="primary lighten-4" size="50"
                    ><v-icon color="primary">mdi-cash</v-icon></v-avatar
                  >
                </v-card-actions>
              </div>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
      <!--END the pending balance card-->

      <!--BEGIN the pending amount card-->
      <v-col lg="3" cols="sm" class="pb-2">
        <v-hover v-slot="{ hover }" open-delay="100">
          <v-card
            @click="(status = 'Open'), (imprestStatus = 'Pending')"
            :elevation="hover ? 5 : 0"
            :class="{ 'statistic-cards-hover': hover }"
            style="border-left: var(--v-primary-base) solid"
          >
            <v-row class="no-gutters">
              <div class="col-9">
                <v-row>
                  <div class="col-auto">
                    <div class="primary fill-height">&nbsp;</div>
                  </div>
                  <div class="col pa-3 py-4 primary--text">
                    <h5 class="text-truncate text-uppercase">PENDING</h5>
                    <h1>{{ countPendingRequests }}</h1>
                  </div>
                </v-row>
              </div>
              <div class="col-3">
                <v-card-actions>
                  <v-avatar color="primary lighten-4" size="50"
                    ><v-icon color="primary"> mdi-timelapse</v-icon>
                  </v-avatar>
                </v-card-actions>
              </div>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
      <!--END the pending requests card-->
      <!--BEGIN the Under Review card-->
      <v-col lg="3" cols="sm" class="pb-2">
        <v-hover v-slot="{ hover }" open-delay="100">
          <v-card
            @click="(status = 'Open'), (imprestStatus = 'Approved')"
            :elevation="hover ? 5 : 0"
            :class="{ 'statistic-cards-hover': hover }"
            style="border-left: var(--v-primary-base) solid"
          >
            <v-row class="no-gutters">
              <div class="col-9">
                <v-row>
                  <div class="col-auto">
                    <div class="primary fill-height">&nbsp;</div>
                  </div>
                  <div class="col pa-3 py-4 primary--text">
                    <h5 class="text-truncate text-uppercase">UNDER REVIEW</h5>
                    <h1>{{ countUnderReviewRequests }}</h1>
                  </div>
                </v-row>
              </div>
              <div class="col-3">
                <v-card-actions>
                  <v-avatar color="primary lighten-4" size="50"
                    ><v-icon color="primary"> mdi-star-circle</v-icon>
                  </v-avatar>
                </v-card-actions>
              </div>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
      <!--END the under review card-->
      <!--BEGIN the approved requests card-->
      <v-col lg="3" cols="sm" class="pb-2">
        <v-hover v-slot="{ hover }" open-delay="100">
          <v-card
            @click="(status = 'Open'), (imprestStatus = 'Issued')"
            :elevation="hover ? 5 : 0"
            :class="{ 'statistic-cards-hover': hover }"
            style="border-left: var(--v-primary-base) solid; cursor: pointer"
          >
            <v-row class="no-gutters">
              <div class="col-9">
                <v-row>
                  <div class="col-auto">
                    <div class="primary fill-height">&nbsp;</div>
                  </div>
                  <div class="col pa-3 py-4 primary--text">
                    <h5 class="text-truncate text-uppercase">ISSUED</h5>
                    <h1>{{ countIssuedRequest }}</h1>
                  </div>
                </v-row>
              </div>
              <div class="col-3">
                <v-card-actions>
                  <v-avatar color="primary lighten-4" size="50"
                    ><v-icon color="primary">mdi-checkbox-marked-circle</v-icon>
                  </v-avatar>
                </v-card-actions>
              </div>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
      <!--END the approved requests card-->
    </v-row>
    <!--END summary stats-->
    <v-row>
      <v-col cols="12">
        <v-card tile class="mt-3" elevation="0">
          <v-card flat>
            <!--BEGIN top bar-->
            <v-card-title class="top-bar mb-2">
              <h3 class="primary--text text-lg-h5 mr-2">Requests</h3>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                dense
              ></v-text-field>
              <v-menu
                v-model="filterDatePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    prepend-inner-icon="filter_alt"
                    color="primary"
                    dense
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2"
                    single-line
                    hide-details
                    label="Filter by date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filterDate"
                  @input="
                    (filterDatePicker = false),
                      (startDate = filterDate[0]),
                      (endDate = filterDate[1])
                  "
                  @click="(status = 'Open'), (imprestStatus = 'Issued')"
                  range
                ></v-date-picker>
              </v-menu>
              <v-autocomplete
                v-model="donorFilter"
                :items="dimension1"
                :item-text="(item) => item.code + ' - ' + item.name"
                :item-value="(item) => item.code"
                v-if="donors.length > 0"
                dense
                single-line
                hide-details
                prepend-inner-icon="filter_alt"
                label="Filter By Donor"
              />
              <v-spacer />
              <v-btn :to="{ name: 'ImprestCard' }" color="primary">
                <v-icon left>mdi-plus</v-icon>
                New Request
              </v-btn>
            </v-card-title>
            <!--END top bar-->
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="donorFilter ? donorImprests : imprests"
                :search="search"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :items-per-page="5"
                fixed-header
                class="table-font table-striped"
              >
                <!--BEGIN date slot-->
                <!--eslint-disable-next-line -->
                <template v-slot:item.postingDate="{ item }">
                  <span class="table-font">{{
                    item.postingDate | formatDate
                  }}</span>
                </template>
                <!--END date slot-->
                <!--BEGIN description slot-->
                <!--eslint-disable-next-line -->
                <template v-slot:item.description="{ item }">
                  <span class="table-font">{{
                    readMore(item.description, 15)
                  }}</span>
                </template>
                <!--END date slot-->
                <!--BEGIN amount slot-->
                <!--eslint-disable-next-line -->
                <template v-slot:item.amountToPayHeader="{ item }">
                  <span class="table-font" v-if="item.currencyCode"
                    >{{
                      item.currencyCode.length > 0
                        ? `(${item.currencyCode})`
                        : "(KES)"
                    }}{{ item.amountToPayHeader | currencyFormat }}</span
                  >
                  <span v-else
                    >(KES) {{ item.amountToPayHeader | currencyFormat }}</span
                  >
                </template>
                <!--END amount slot-->
                <!--BEGIN status slot-->
                <!--eslint-disable-next-line -->
                <template v-slot:item.imprestStatus="{ item }">
                  <v-icon
                    small
                    left
                    :color="
                      item.imprestStatus === 'Approved' &&
                      item.status === 'Open'
                        ? getColor('Review').color
                        : getColor(item.imprestStatus).color
                    "
                    >mdi-radiobox-marked</v-icon
                  >
                  <span small lowercase text-color="white">
                    <span
                      v-if="
                        item.imprestStatus === 'Approved' &&
                        item.status === 'Open'
                      "
                      class="text-capitalize"
                      >under-review</span
                    >
                    <span
                      v-else-if="
                        item.imprestStatus === 'Approved' &&
                        item.status === 'Released'
                      "
                      class="text-lowercase"
                      >approved</span
                    >
                    <span v-else>{{ item.imprestStatus | capitalize }}</span>
                  </span>
                </template>
                <!--END status slot-->
                <!---BEGIN actions slot-->
                <!--eslint-disable-next-line -->
                <template v-slot:item.action="{ item }">
                  <div class="d-flex justify-start">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          color="success"
                          v-bind="attrs"
                          v-on="on"
                          label
                          small
                          :to="{
                            name: 'ImprestCard',
                            params: { code: item.no },
                          }"
                          class="mx-1 text--white"
                        >
                          <v-icon small left color="white">mdi-eye</v-icon>
                          view
                        </v-chip>
                      </template>
                      <span>view Imprest Details</span>
                    </v-tooltip>
                    <!--BEGIN account for imprest button-->
                    <div class="d-flex justify-start">
                      <v-tooltip bottom>
                        <template v-slot:activator="{}">
                          <v-chip
                            color="primary"
                            label
                            small
                            v-show="item.imprestStatus === 'Issued'"
                            :to="{
                              name: 'ImprestAccounting',
                              params: { code: item.no },
                            }"
                            class="mx-1 text--white"
                          >
                            <v-icon small left color="white">mdi-cash</v-icon>
                            account
                          </v-chip>
                        </template>
                        <span>Account for imprest</span>
                      </v-tooltip>
                    </div>
                    <!--END account for imprest button-->
                    <div class="d-flex justify-start">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            color="secondary darken-1"
                            label
                            small
                            v-on="on"
                            v-bind="attrs"
                            :to="{
                              name: 'ImprestDocument',
                              params: { docNo: item.no },
                            }"
                            class="mx-1 text--white"
                          >
                            <v-icon small left color="white">mdi-file</v-icon>
                            <span style="color: #ffffff">Imprest Report</span>
                          </v-chip>
                        </template>
                        <span>view Imprest Document report</span>
                      </v-tooltip>
                    </div>
                    <!--  -->
                    <div class="d-flex justify-start">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            color="orange darken-1"
                            label
                            small
                            v-on="on"
                            v-bind="attrs"
                            @click="viewComments(item)"
                            class="mx-1 text--white"
                          >
                            <v-icon small left color="white"
                              >mdi-history
                            </v-icon>
                            <span style="color: #ffffff">View Comment</span>
                          </v-chip>
                        </template>
                        <span>View Impress Cash Comment History</span>
                      </v-tooltip>
                    </div>
                  </div>
                </template>
                <!--END actions slot-->
              </v-data-table>
            </v-card-text>
            <!-- END the table of requests-->
          </v-card>
        </v-card>
      </v-col>
    </v-row>
    <!--  -->
    <ImprestCashComment
      :cashComments="selectedComments ? selectedComments : []"
      :dialog="viewComment"
      @setCommentDialog="setCommentDialog"
    />
    <!--  -->
  </v-container>
</template>
<script>
import helper from "@kinetics254/cassandra-base/utilities/helpers/helper";
import ImprestMixin from "../ImprestMixin.js";
import ImprestCashComment from "./ImprestCashComment.vue";
export default {
  name: "ImprestList",
  mixins: [ImprestMixin],
  components: { ImprestCashComment },
  data: function () {
    return {
      viewComment: false,
      sortBy: "no",
      sortDesc: true,
      loaded: false,
      search: "",
      filterDate: [],
      filterDatePicker: "",
      tab: null,
      eve: null,
      status: "All",
      imprestStatus: "",
      donorFilter: null,
      headers: [
        {
          text: "#No",
          align: "center",
          sortable: true,
          value: "no",
        },
        { text: "Requested At", align: "", value: "postingDate" },
        { text: "Description", align: "", value: "description" },
        { text: "Amount", align: "center", value: "amountToPayHeader" },
        {
          text: "Status",
          align: "start",
          sortable: true,
          value: "imprestStatus",
        },
        { text: "Action", align: "start", value: "action" },
      ],
      selectedComments: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("imprest/setBreadCrumbs", to);
      v.$store.dispatch("imprest/getImprests");
      v.$store.dispatch("imprest/getDimensionValues");
    });
  },
  computed: {
    imprests() {
      if (this.filterDate.length !== 0) {
        return this.status === "All"
          ? this.$store.getters["imprest/imprests"].filter(
              (a) =>
                new Date(a.postingDate) >= this.startDate &&
                new Date(a.postingDate) <= this.endDate
            )
          : this.$store.getters["imprest/imprests"].filter(
              (a) =>
                new Date(a.postingDate) >= this.startDate &&
                new Date(a.postingDate) <= this.endDate &&
                a.imprestStatus === this.imprestStatus &&
                a.status === this.status
            );
      }
      return this.status === "All"
        ? this.$store.getters["imprest/imprests"]
        : this.$store.getters["imprest/imprests"].filter(
            (el) =>
              el.imprestStatus === this.imprestStatus &&
              el.status === this.status
          );
    },
    donorImprests() {
      return this.imprests.filter(
        (el) => el.shortcutDimension1Code === this.donorFilter
      );
    },
    dateRangeText() {
      return this.filterDate.join(" To ");
    },
    total() {
      let total = 0;
      this.imprests.forEach((i) => {
        total += Number(i.amountToPayHeader);
      });

      return helper.shortNumberPipe(total);
    },
    /**
     * total issued/APPROVED REQUESTS
     * @returns {number}
     */
    countIssuedRequest: function () {
      return helper.countByStatus(this.imprests, "imprestStatus", "Issued");
    },
    /**
     * Pending requests counter
     * @returns {number}
     */
    countPendingRequests: function () {
      return helper.countByStatus(this.imprests, "imprestStatus", "Pending");
    },
    /**
     * total under review requests
     * @returns {number}
     */
    countUnderReviewRequests: function () {
      return helper.countByStatus(
        this.imprests,
        "imprestStatus",
        "Approved",
        "status",
        "Open"
      );
    },
    dimensionValues() {
      return this.$store.getters["imprest/imprestGetters"]("dimensions");
    },
    donors() {
      return this.dimensionValues
        ? this.dimensionValues.filter((el) => el.dimensionCode === "DONOR")
        : [];
    },
  },
  methods: {
    /*
    Opening history dialog
    */
    viewComments(item) {
      this.selectedComments = [...item.cashComments];
      this.viewComment = !this.viewComment;
    },
    /**
     * Call the imprest/getImprests action to remove an item
     */
    deleteImprest: function (data) {
      this.$confirm.show({
        title: ` imprest ID ${data.no}`,
        text: "Are you sure you want to remove this imprest request?",
        onConfirm: () => {
          this.$store.dispatch("imprest/deleteImprest", data);
        },
        onCancel: () => {
          this.$store.dispatch("imprest/getImprests");
        },
      });
    },
    /**
     * Add read more to long texts
     * @param text
     * @param length
     * @returns {string|*}
     */
    readMore(text, length) {
      return helper.addReadMore(text, length);
    },
    getColor(val) {
      return helper.getColor(val);
    },
    setCommentDialog(val) {
      this.viewComment = val;
    },
  },
  watch: {
    filterDate() {
      if (this.filterDate.length === 1) {
        this.startDate = new Date(this.filterDate[0]);
        this.endDate = new Date(this.filterDate[0]);
      }
      if (this.filterDate.length === 2) {
        this.startDate = new Date(this.filterDate[0]);
        this.endDate = new Date(this.filterDate[1]);
      }
    },
  },
};
</script>
<style scoped>
@import url("../styles/style.css");

* {
  font-family: Poppins, sans-serif !important;
}

.top-bar {
  background-color: #e8eaf6 !important;
  cursor: pointer;
  border-bottom: 1px solid #e8eaf6;
}

.request-header {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 175.6%;
  text-align: justify;
  color: var(--v-primary-base);
  text-transform: uppercase;
}

.statistic-cards-hover {
  box-shadow: 0 40px 60px -20px rgba(12, 5, 62, 0.15);
  z-index: 100;
  cursor: pointer;
  font-size: 14px;
  background-color: #e8eaf6;
}
</style>
