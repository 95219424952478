<template>
  <v-card class="mx-auto" rounded flat>
    <v-card-title> Warnings </v-card-title>
    <v-card-title>
      <v-text-field
        v-model="searchAccused"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        class="mx-2"
        style="width: 200px"
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="accusedHeaders"
        :items="accusedItems"
        :search="searchAccused"
      >
        <!--eslint-disable-next-line -->
        <template v-slot:item.documentDate="{ item }">
          <span>{{ item.documentDate | date }}</span>
        </template>
        <!--eslint-disable-next-line -->
        <template v-slot:item.status="{ item }">
          <v-chip :class="`${getColor(item.status)}`" label>
            {{ item.status }}
          </v-chip>
        </template>
        <!--eslint-disable-next-line -->
        <template v-slot:item.raisedBy="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex" v-on="on" v-bind="attrs">
                <v-avatar class="secondary mr-2" size="35">
                  {{ getInititals(item.raisedByName) }}
                </v-avatar>
                <div class="mt-2">
                  {{ item.raisedByName }}
                </div>
              </div>
            </template>
            <span>Employee: {{ item.raisedBy }}</span>
          </v-tooltip>
        </template>
        <!--eslint-disable-next-line -->
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="mx-1"
                color="teal lighten-4"
                label
                small
                @click="viewWaring(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="teal darken-4" small>mdi-eye</v-icon>
              </v-chip>
            </template>
            <span>view</span>
          </v-tooltip>
          <div class="d-flex justify-start">
            <v-btn
              class="primary text-capitalize"
              elevation="0"
              v-if="item.status === 'Waiting Response'"
              @click="respondTo(item)"
            >
              <v-icon color="amber" class="mx-2">waving_hand</v-icon>
              Respond
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="warningDialog" max-width="670px">
      <v-card v-if="warning" tile>
        <v-card-title>Warning Details </v-card-title>

        <v-card-text class="mt-3">
          <p class="">
            <strong>Raised By </strong>: {{ warning.raisedByName }}
          </p>

          <div
            v-for="(item, index) in warning.disciplinaryLine"
            :key="index"
            class=""
          >
            <h3 class="">category</h3>
            <p class="">{{ item.categoryDescription }}</p>
            <h3 class="">Details</h3>
            <p class="">{{ item.details }}</p>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="red" @click="closeDialog()">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
import { helper } from "@kinetics254/cassandra-base/utilities";

export default {
  name: "WarningList",
  data() {
    return {
      search: "",
      searchAccused: "",
      tabs: 0,
      warning: null,
      warningDialog: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Disc/getAccusedDisciplinary", {
        accused: v.user.employee,
        documentType: "Warning",
      });
    });
  },
  computed: {
    user() {
      return AuthService.user;
    },
    accusedItems() {
      return this.$store.getters["Disc/discGetters"]("accusedDisciplinary");
    },
    raisedHeaders() {
      return [
        {
          text: "No.",
          value: "no",
          align: "left",
        },
        { text: "Raised On", value: "documentDate" },
        { text: "Accused Employee", value: "accused" },
        { text: "status", value: "status" },
        { text: "Action Description", value: "actionDescription" },
        { text: "Disciplinary stage", value: "stage" },
        { text: "actions", value: "action" },
      ];
    },
    accusedHeaders() {
      return [
        {
          text: "No.",
          value: "no",
          align: "left",
        },
        { text: "Raised On", value: "documentDate" },
        { text: "Raised By", value: "raisedBy" },
        { text: "status", value: "status" },
        { text: "action Description", value: "actionDescription" },
        { text: "stage", value: "stage" },
        { text: "actions", value: "action" },
      ];
    },
  },
  methods: {
    raiseDisciplinary() {
      this.$router.push({ name: "DisciplinaryCard" });
    },
    getInititals(name) {
      return name
        .split(" ")
        .map((n) => n[0])
        .join("");
    },
    getColor(status) {
      return helper.getColor(status).color;
    },
    viewDisciplinary(item) {
      this.$router.push({
        name: "DisciplinaryCard",
        params: { no: btoa(item.no) },
      });
    },
    respondTo(item) {
      this.$router.push({
        name: "DisciplinaryCard",
        params: { no: btoa(item.no) },
        query: {
          stage: btoa(item.stage),
        },
      });
    },

    viewWaring(item) {
      this.warningDialog = true;
      this.warning = item;
    },

    closeDialog() {
      this.warningDialog = false;
    },
  },
};
</script>
