import call from "@kinetics254/cassandra-base/service/http";
import ReqConstants from "./ReqConstants.js";

export default {
  namespaced: true,
  state: {
    requisitions: [],
    requisition: {},
    billingItems: [],
    vendors: [],
    locations: [],
    users: [],
    dimensions: [],
    RequisitionDocument: "",
    unitsOfMeasure: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    reqGetters: (state) => (val) => state[val],
  },
  actions: {
    /**
     * Get all requisitions
     * @param commit
     */
    getRequisitions: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", ReqConstants.requisition)
        .then((res) => {
          commit("MUTATE", { state: "requisitions", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /**
     * This method retrieves the details of a single requisition
     * @param commit
     * @param payload
     */
    showRequisition: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", ReqConstants.singleRequisition(payload))
        .then((res) => {
          commit("MUTATE", {
            state: "requisition",
            data: res.data.data.shift(),
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /**
     * Add new request
     * @param {Object} context
     * @param commit
     * @param payload
     */
    saveRequisition: ({ commit, dispatch }, payload) => {
      const updatedLines = payload.requisitionLines.map((line) => {
        line.documentType = payload.documentType;
        return line;
      });
      payload.requisitionLines = updatedLines;
      commit("loader/SET_LOADING", true, { root: true });
      call("post", ReqConstants.requisition, payload)
        .then((res) => {
          Event.$emit("ApiSuccess", res.data.message);
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("back");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    /**
     * Update request
     * @param commit
     * @param dispatch
     * @param payload
     */
    updateRequisition: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", `${ReqConstants.requisition}/update`, payload)
        .then((res) => {
          Event.$emit("ApiSuccess", res.data.message);
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("showRequisition", payload.no);
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    /** save changes and send request for approval */
    saveAndSendForpproval: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call(
        "post",
        payload.method === "post"
          ? ReqConstants.requisition
          : `${ReqConstants.requisition}/update`,
        payload
      )
        .then((res) => {
          Event.$emit(
            "ApiSuccess",
            payload.method === "post"
              ? "You have successfully created a new requisition request"
              : `You have successfully Updated ${res.data.data[0].no} Requisition Record`
          );
          dispatch("sendApprovalRequest", { headerNo: res.data.data[0].no });
          Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /**
     * This function sends requisition approval request to the server
     * @param commit
     * @param dispatch
     * @param payload
     */
    sendApprovalRequest: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", ReqConstants.requisitionApprovalRequest, payload)
        .then((res) => {
          Event.$emit(
            "ApiSuccess",
            "Requisition successfully sent for approval"
          );
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("showRequisition", payload.headerNo);
          dispatch("getRequisitions");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit(
            "ApiError",
            `Unable to send Requisition Record For Approval: ${error.response.data.message}`
          );
        });
    },
    /**
     * Send requisition cancel request to the server
     * @param commit
     * @param dispatch
     * @param payload
     */
    sendCancelRequest: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", ReqConstants.requisitionCancelRequest, payload)
        .then((res) => {
          Event.$emit("ApiSuccess", res.data.message);
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("showRequisition", payload.headerNo);
          dispatch("getRequisitions");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    /**
     * Get dimension values
     * @param commit
     */
    getDimensionValues: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", ReqConstants.dimension)
        .then((res) => {
          commit("MUTATE", { state: "dimensions", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /**
     * Get Billing Items
     * @param {Object} context
     * @param commit
     */
    getBillingItems: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", ReqConstants.billingItems)
        .then((res) => {
          commit("MUTATE", { state: "billingItems", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /**
     * Dashboard stats
     * @param commit
     */
    getRequisitionStats: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", ReqConstants.requisitions)
        .then((res) => {
          commit("MUTATE", { state: "requisitions", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /**
     * Get purchase requisition vendors
     * @param commit
     */
    getVendors: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", ReqConstants.vendors)
        .then((res) => {
          commit("MUTATE", { state: "vendors", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /**
     * Get purchase requisition locations
     * @param commit
     */
    getLocations: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", ReqConstants.locations)
        .then((res) => {
          commit("MUTATE", { state: "locations", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /**
     * Get requisition users
     * @param commit
     */
    getUsers: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", ReqConstants.users)
        .then((res) => {
          commit("MUTATE", { state: "users", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /**
     * Delete requisition record
     * @param commit
     * @param dispatch
     * @param data
     */
    deleteRequisition: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("delete", ReqConstants.requisition, data)
        .then(() => {
          Event.$emit("ApiSuccess", "Requisition successfully deleted");
          dispatch("getRequisitions");
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    deleteBillingItem: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("delete", ReqConstants.billingItem, { data })
        .then(() => {
          Event.$emit("ApiSuccess", "Item successfully removed");
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getRequisitionDocument: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", ReqConstants.RequisitionDocument, payload)
        .then((res) => {
          commit("MUTATE", {
            state: "RequisitionDocument",
            data: process.env.VUE_APP_FILE_API + res.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /**
     * Get units of measure
     * @param commit
     */
    getUnitsOfMeasure: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", ReqConstants.unitsOfMeasure)
        .then((res) => {
          commit("MUTATE", { state: "unitsOfMeasure", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    CheckBudget: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", ReqConstants.CheckBudget, data)
        .then((res) => {
          Event.$emit("ApiSuccess", res.data.data[0]);
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("showRequisition", data.documentNo);
        })
        .catch((error) => {
          Event.$emit("ApiInfo", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    ReopenDocument: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", ReqConstants.ReopenDocument, data)
        .then((res) => {
          Event.$emit("ApiSuccess", res.data.data[0]);
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("showRequisition", data.documentNo);
        })
        .catch((error) => {
          Event.$emit("ApiInfo", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
  },
};
