<template>
  <v-card class="mx-auto" flat>
    <v-card-title>
      My Approval List
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        dense
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-data-table :headers="headers" :items="approvals" class="elevation-0">
        <template v-slot:item.recordToApprove="{ item }">
          {{ item.recordToApprove }}
        </template>
        <template v-slot:item.dueDate="{ item }">
          <span>{{ getDate(item.dueDate) }}</span>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip :color="getStatusColor(item.status)">{{
            item.status
          }}</v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            class="primary lighten-4 primary--text text--darken-4"
            elevation="0"
            @click="viewApproval(item)"
          >
            <v-icon class="mx-2">mdi-eye</v-icon>
            View
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
import { helper } from "@kinetics254/cassandra-base/utilities";
export default {
  name: "ApprovalList",
  data() {
    return {
      search: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Approvals/getApprovalEntries", {
        approverID: v.userData.employee,
        status: "Open",
      });
    });
  },
  computed: {
    userData() {
      return AuthService.user;
    },
    approvals() {
      const entries =
        this.$store.getters["Approvals/approvalGetters"]("approvalEntries");
      return (
        Array.isArray(entries.data) &&
        entries.data.sort((a, b) => {
          return new Date(b.dueDate) - new Date(a.dueDate);
        })
      );
    },
    headers() {
      return [
        {
          text: "No.",
          align: "start",
          sortable: true,
          value: "entryNo",
        },
        {
          text: "Document To Approve",
          align: "start",
          sortable: true,
          value: "approveForType",
        },
        {
          text: "Document Number",
          align: "start",
          sortable: true,
          value: "documentNo",
        },
        {
          text: "Status",
          align: "start",
          sortable: true,
          value: "status",
        },
        {
          text: "dueDate",
          align: "start",
          sortable: true,
          value: "dueDate",
        },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
  },
  methods: {
    getDate(date) {
      return helper.getDateFormat(date);
    },
    getStatusColor(status) {
      return helper.getColor(status).color;
    },
    viewApproval(item) {
      const RecHeader = item.recordToApprove.split(":")[0];
      if (RecHeader !== "Emp. Perf. Application Header") {
        this.$router.push({
          name: "ApprovalCard",
          params: {
            documentNo: btoa(item.documentNo),
            approverID: btoa(item.approverID),
          },
        });
      } else {
        this.$router.push({
          name: "AppraisalApprovalCard",
          params: {
            documentNo: btoa(item.documentNo),
          },
        });
      }
    },
  },
};
</script>
