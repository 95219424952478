import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=096fd5c1&scoped=true"
import script from "./Index.vue?vue&type=script&lang=js"
export * from "./Index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "096fd5c1",
  null
  
)

export default component.exports