<script>
import PageHeader from "../../components/planner/PageHeader.vue";
import LeavePlannerCalendar from "../../components/planner/LeavePlannerCalendar.vue";
import LeavePlannerList from "../../components/planner/LeavePlannerList.vue";
import LeaveDaysBalances from "../../components/planner/LeaveDaysBalances.vue";

export default {
  name: "Index",
  components: {
    LeaveDaysBalances,
    LeavePlannerList,
    LeavePlannerCalendar,
    PageHeader,
  },

  data: function () {
    return {
      selectedStatus: null,
      statuses: [
        {
          value: "Open",
          text: "Open",
        },
        {
          value: "Pending",
          text: "Pending Approval",
        },
        {
          value: "Released",
          text: "Released",
        },
        {
          value: "",
          text: "View All",
        },
      ],
      isList: true,
    };
  },

  mounted() {
    this.fetchPlanners();

    this.$store.dispatch("leavePlanner/getLeaveDaysBalances", {
      employeeNo: this.userData.employee,
    });

    this.$store.dispatch("leavePlanner/getPlannerLines", {
      employeeNo: this.userData.employee,
    });
  },

  computed: {
    userData: function () {
      return this.$store.getters["auth/user"];
    },

    plannerLines: function () {
      return this.$store.getters["leavePlanner/leavePlannerGetter"](
        "plannerLines"
      );
    },

    filters: function () {
      let list = {
        employeeNo: this.userData.employee,
      };

      if (this.selectedStatus) {
        list.status = this.selectedStatus;
      }

      return list;
    },
  },

  watch: {
    selectedStatus: function () {
      this.fetchPlanners();
    },
  },

  methods: {
    fetchPlanners: function () {
      this.$store.dispatch("leavePlanner/getPlanners", this.filters);
    },
  },
};
</script>

<template>
  <v-container fluid grid-list-xl>
    <page-header
      title="Leave Planner"
      :path="$router.currentRoute.meta.breadcrumb"
    />

    <div class="mb-4">
      <leave-days-balances />
    </div>

    <v-row>
      <v-col cols="12" md="2">
        <div>
          <v-overflow-btn
            v-model="selectedStatus"
            :items="statuses"
            label="Filter by"
            filled
            dense
          ></v-overflow-btn>
        </div>
      </v-col>
      <v-col cols="12" md="5">
        <div style="margin-left: 12%">
          <v-btn
            outlined
            class="mr-4"
            :disabled="isList"
            color="primary"
            @click="isList = true"
          >
            <v-icon class="mr-2">view_day</v-icon>
            list
          </v-btn>
          <v-btn
            outlined
            class="mr-4"
            :disabled="!isList"
            color="primary"
            @click="isList = false"
          >
            <v-icon class="mr-2">date_range</v-icon>
            Calendar
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" md="5">
        <div style="margin-left: 2%">
          <v-btn color="primary" link to="/leave/planner/application">
            <v-icon>add</v-icon>
            Make Planner
          </v-btn>
          <v-btn
            style="margin-left: 4px"
            color="accent"
            link
            @click="$router.push({ name: 'LeavePlannerReviews' })"
          >
            <v-icon>view_day</v-icon>
            My Approvals
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" v-if="userData">
        <leave-planner-list
          v-if="isList"
          :employee-no="userData.employee"
          :status="selectedStatus"
        />
        <leave-planner-calendar
          v-else
          :employee-no="userData.employee"
          :planner-lines="plannerLines"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped></style>
