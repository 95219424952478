import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"75%","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"mx-auto",attrs:{"flat":""}},[_c(VCardTitle,[_vm._v(" Add Score "),_c(VSpacer),(_vm.routeParams)?_c('span',{staticClass:"ml-0 text-body-1"},[_vm._v(" Application: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.routeParams))])]):_vm._e(),_c(VSpacer),(_vm.routeParams)?_c('span',{staticClass:"text-body-1"},[_vm._v(" Status:")]):_vm._e(),_c(VBtn,{staticClass:"mx-2 text-capitalize deep-orange lighten-4 deep-orange--text text--darken-4",attrs:{"elevation":"0","small":""},on:{"click":function($event){return _vm.GoBack()}}},[_vm._v(" close "),_c(VIcon,{staticClass:"mx-2"},[_vm._v("mdi-close")])],1)],1),_c(VDivider),_c(VCardTitle,[_c(VSpacer)],1),_c(VCardText,[_c(VTextField,{attrs:{"placeholder":"Add score"},model:{value:(_vm.formData.committeeRatingScore),callback:function ($$v) {_vm.$set(_vm.formData, "committeeRatingScore", _vm._n($$v))},expression:"formData.committeeRatingScore"}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"check1"}},[_vm._v("Add score")])],1),_c(VDivider),_c(VCardActions,[_c(VBtn,{staticClass:"mr-2",attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.addScore({
            isSendForApproval: false,
          })}}},[_vm._v(" Add Score ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }