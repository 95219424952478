import { mapState } from "vuex";
import colors from "../modules/leave/colors";
export default {
  data: () => ({
    colors: colors,
  }),
  computed: {
    userData: function () {
      return this.$store.getters["auth/user"];
    },
    ...mapState({
      listFilterValue: function (state) {
        return state.leave.listFilterValue;
      },
    }),
  },
  methods: {
    viewApplication: function (application) {
      this.$router.push({
        name: "view_application",
        params: { application_code: application.application_code },
      });
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
  },
};
