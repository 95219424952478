<template>
  <v-container>
    <PettyCashBreadcrumbs />

    <v-container>
      <v-card elevation="0" tile class="mx-auto overflow-y-auto">
        <v-app-bar
          class="mt-3 justify-space-between"
          elevation="0"
          dense
          color="#ffffff"
        >
          <v-btn
            color="red darken-1"
            text
            elevation="0"
            class="pettycash__buttons mr-2"
            link
            to="dashboard"
          >
            <v-icon class="mx-1">mdi-arrow-left-thin</v-icon>
            back
          </v-btn>

          <v-toolbar flat dense>
            <v-spacer />
            <v-btn
              @click="submit"
              class="pettycash__buttons"
              elevation="0"
              color="primary"
            >
              <v-icon small class="mx-2"> mdi-content-save </v-icon>
              <span>Save Changes</span>
            </v-btn>
          </v-toolbar>
        </v-app-bar>

        <v-divider></v-divider>
        <v-card-text>
          <div class="mx-3">
            <v-form v-model="isValid" ref="editProfileForm">
              <v-list v-if="form">
                <v-list-item dense>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                            dense
                            label="First Name"
                            v-model="form.first_name"
                            outlined
                            prepend-inner-icon="mdi-account"
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            dense
                            label="Middle Name"
                            v-model="form.middle_name"
                            outlined
                            prepend-inner-icon="mdi-account"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            dense
                            label="Last Name"
                            v-model="form.last_name"
                            outlined
                            prepend-inner-icon="mdi-account"
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-autocomplete
                            v-model="form.gender"
                            label="Gender"
                            dense
                            outlined
                            prepend-inner-icon="mdi-gender-male-female"
                            :items="['Male', 'Female', 'Other']"
                            :item-text="(item) => item"
                            :item-value="(item) => item"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            dense
                            label="National ID"
                            v-model="form.national_id"
                            :v-model="form.last_name"
                            outlined
                            prepend-inner-icon="mdi-card-account-details"
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-text-field
                            dense
                            label="Email Address"
                            v-model="form.email"
                            outlined
                            prepend-inner-icon="mdi-email"
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            dense
                            label="Phone Number"
                            v-model="form.phoneNo"
                            outlined
                            prepend-inner-icon="mdi-phone"
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-menu
                            v-model="startDateCalendarButtonIcon"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="form.birth_date"
                                label="Birth Date"
                                prepend-inner-icon="mdi-calendar"
                                color="primary"
                                dense
                                outlined
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>

                            <v-date-picker
                              v-model="form.birth_date"
                              @input="startDateCalendarButtonIcon = false"
                              :max="maxDate"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-text-field
                            dense
                            label="HELB No"
                            v-model="form.helb_no"
                            outlined
                            prepend-inner-icon="mdi-tag"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            dense
                            label="NHIF No"
                            v-model="form.nhif_no"
                            outlined
                            prepend-inner-icon="mdi-tag"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            dense
                            label="NSSF No"
                            v-model="form.nssf_no"
                            outlined
                            prepend-inner-icon="mdi-tag"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-text-field
                            dense
                            label="PIN No"
                            v-model="form.pin_no"
                            outlined
                            prepend-inner-icon="mdi-tag"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            dense
                            label="COOP No"
                            v-model="form.coop_no"
                            outlined
                            prepend-inner-icon="mdi-tag"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            dense
                            label="Address 1"
                            v-model="form.address"
                            outlined
                            prepend-inner-icon="mdi-map-marker"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-text-field
                            dense
                            label="Address 2"
                            v-model="form.address2"
                            outlined
                            prepend-inner-icon="mdi-map-marker-outline"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            dense
                            label="City"
                            v-model="form.city"
                            outlined
                            prepend-inner-icon="mdi-city"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            dense
                            label="Post Code"
                            v-model="form.post_code"
                            outlined
                            prepend-inner-icon="mdi-post"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </v-container>
</template>
<script>
import PettyCashBreadcrumbs from "../components/ProfileBreadCrumbs.vue";
export default {
  name: "EditProfile",
  components: { PettyCashBreadcrumbs },

  data: function () {
    return {
      form: null,
      maxDate: new Date().toISOString(),
      isValid: true,
      rules: {
        required: (value) => !!value || "Required.",
      },
      startDateCalendarButtonIcon: false,
    };
  },

  computed: {
    employee: function () {
      return this.$store.getters["profile/employee"][0];
    },
  },

  watch: {
    employee: {
      handler: function () {
        if (this.employee) {
          this.form = {
            number: this.employee.number,
            first_name: this.employee.first_name,
            middle_name: this.employee.middle_name,
            last_name: this.employee.last_name,
            email: this.employee.email,
            phoneNo: this.employee.phoneNo,
            birth_date: this.employee.birth_date,
            gender: this.employee.gender,
            job_title: this.employee.job_title,
            national_id: this.employee.national_id,
            address: this.employee.address,
            address2: this.employee.address2,
            city: this.employee.city,
            post_code: this.employee.post_code,
            helb_no: this.employee.helb_no,
            nhif_no: this.employee.nhif_no,
            nssf_no: this.employee.nssf_no,
            pin_no: this.employee.pin_no,
            coop_no: this.employee.coop_no,
          };
        }
      },
      immediate: true,
    },
  },

  methods: {
    submit: function () {
      if (!this.isValid) {
        this.$refs.editProfileForm.validate();
      } else {
        this.form.search_name = [
          this.form.first_name,
          this.form.middle_name,
          this.form.last_name,
        ].join(" ");
        this.form.companyEmail = this.form.email;
        this.$store.dispatch("profile/updateEmployee", this.form);
      }
    },
  },
};
</script>
