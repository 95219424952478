export default {
  exit: "exit",
  showExit: (no) => `exit/show?no=${no}`,
  updateExit: "exit/update",
  deleteExit: "exit/delete",
  sendForApproval: "exit/send-for-approval",
  cancelApproval: "exit/cancel-approval",
  exitCodes: "exit/exit-reason-codes",
  employeeItems: "exit/employee-items",
  employeeChecklist: "exit/employee-checklist",
};
