export default {
  order: 2,
  name: "Leave",
  icon: "date_range",
  to: "/leave/dashboard",
  subs: [
    {
      name: "Application",
      icon: "pending_actions",
      to: "/leave/application",
    },
    {
      name: "Leave Approval",
      icon: "event_available",
      to: "/leave/approvals",
    },
    // {
    //   name: "Handover Requests",
    //   icon: "file_copy",
    //   to: "/handover/handOverRequests",
    // },
  ],
};
