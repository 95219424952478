import { ImprestRoutes, ImprestStore, ImprestLinks } from "./modules/imprest";

export default {
  install(Vue, options) {
    /* register store and routes */
    if (options.router) options.router.addRoute(...ImprestRoutes);
    if (options.store) options.store.registerModule("imprest", ImprestStore);

    options.store.commit("Dashboard/ADD_LINK", ImprestLinks);
  },
};
