<template>
  <v-container>
    <v-card class="mx-auto" elevation="0">
      <v-card-title>
        <div class="headline text-capitalize">Apraisal Results</div>
        <v-spacer />
      </v-card-title>

      <v-toolbar v-model="tab" color="deep-purple-accent-4">
        <template v-slot:extension>
          <v-tabs v-model="tab" fixed-tabs>
            <v-tab v-for="item in items" :key="item.tab">{{ item.tab }}</v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-tabs-items v-model="tab">
        <!-- <v-tab-item :value="items[0]['Accepted']">
          <AcceptedResultCard :applications="acceptedApplications" />
        </v-tab-item> -->
        <v-tab-item :value="items[1]['Appealed']">
          <RejectedResultCard :applications="rejectedApplications" />
        </v-tab-item>
        <v-tab-item :value="items[1]['Committee Reviewed']">
          <RejectedResultCard :applications="commiteeReviewdApplications" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>
<script>
// import AcceptedResultCard from "./AcceptedResultCard.vue";
import RejectedResultCard from "./RejectedResultCard.vue";
import { helper } from "../../../../cassandra-base/utilities";
export default {
  name: "ResultList",
  components: { /*AcceptedResultCard*/ RejectedResultCard },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Result/getRejectedAppraisalResults", "Rejected");
      v.$store.dispatch(
        "Result/getCommitteeReviewedResults",
        "Comittee_Reviewed"
      );
      Event.$on("openDialog", (document) => {
        v.viewItem(document);
      });
    });
  },
  data: function () {
    return {
      tab: null,
      items: [
        // { tab: "Accepted", content: "AcceptedResultCard" },
        { tab: "Appealed", content: "RejectedResultCard" },
        { tab: "Committee Reviewed", content: "RejectedResultCard" },
      ],
    };
  },
  computed: {
    authUser() {
      return this.$store.getters["auth/user"];
    },

    acceptedApplications() {
      return this.$store.getters["Result/appraisalResultGetter"](
        "accepted_results"
      );
    },

    rejectedApplications() {
      return this.$store.getters["Result/appraisalResultGetter"](
        "rejected_results"
      );
    },

    commiteeReviewdApplications() {
      return this.$store.getters["Result/appraisalResultGetter"](
        "commitee_reviewed"
      );
    },
  },
  methods: {},
};
</script>
