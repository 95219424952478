<template>
  <v-container>
    <v-card class="mx-auto" elevation="0">
      <v-card-title>
        <div class="headline text-capitalize">My KPI Application</div>
        <v-spacer />
        <v-btn class="primary" @click="createKPIDocument">
          <v-icon>mdi-plus</v-icon>
          Add KPIs
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="applications"
        class="elevation-0"
      >
        <template v-slot:item.name="{ item }">
          <v-avatar color="primary" size="36">
            <span class="white--text text-caption">{{
              getUserIntials(item.name)
            }}</span>
          </v-avatar>
          {{ item.name }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip small dark :color="getStatusColor(item.status).color">
            {{ item.status }}
          </v-chip>
        </template>
        <!--eslint-disable-next-line -->
        <template v-slot:item.actions="{ item }">
          <v-btn
            small
            class="mr-2 white--text"
            color="blue"
            @click="viewItem(item)"
            dark
          >
            view
          </v-btn>

          <v-btn
            small
            color="red"
            class="white--text"
            v-if="item.status === 'Open'"
            @click="deleteItem(item)"
          >
            delete
          </v-btn>
        </template>
        <!--end of card actions -->
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import { helper } from "../../../../cassandra-base/utilities";
export default {
  name: "TemplateList",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Template/getApplications");
      Event.$on("openDialog", (document) => {
        v.viewItem(document);
      });
    });
  },
  data: function () {
    return {};
  },
  computed: {
    authUser() {
      return this.$store.getters["auth/user"];
    },
    headers() {
      return [
        {
          text: "Document No",
          value: "no",
          align: "start",
          sortable: true,
        },
        {
          text: "Employee Name",
          value: "name",
        },

        {
          text: "Status",
          value: "status",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: true,
        },
      ];
    },

    applications() {
      return this.$store.getters["Template/appTempGetters"]("applications");
    },
  },
  methods: {
    addTemplate() {
      this.$router.push({ name: "TemplateCard" });
    },
    getStatusColor: function (status) {
      return helper.getColor(status);
    },
    getUserIntials: function (val) {
      var matches = val.match(/\b(\w)/g);
      var acronym = matches.join("");
      return acronym;
    },
    viewItem(val) {
      this.$router.push({ name: "TemplateCard", params: { no: val.no } });
    },
    deleteItem(val) {
      this.$store.dispatch("Template/deleteApplication", val);
    },
    createKPIDocument() {
      const employeeNo = this.authUser.profile.number;
      this.$store.dispatch("Template/createDocumentHeader", {
        employeeNo: employeeNo,
      });
    },
  },
};
</script>
