<template>
  <v-container fluid>
    <v-card class="py-2 px-2">
      <v-toolbar elevation="1" class="mb-6">
        <v-toolbar-title>
          <v-row>
            <v-col>
              <span> Committee: </span>
            </v-col>
            <v-col v-if="selectCommitteeActive">
              <v-select
                single-line
                v-model="selectedCommittee"
                :items="getCommitteeAssigned"
                label="Select a Committee"
                @change="setCommitteeSelected"
              ></v-select>
            </v-col>
            <v-col v-else>
              <span
                color="primary"
                class="font-weight-bold"
                v-if="appraisalCommitteDocuments"
                >{{ getCommitteeAssigned }}</span
              >
            </v-col>
            <v-col>
              <v-btn icon class="ml-3" @click="setSelectCommittee">
                <v-icon class="mr-2">mdi-filter-variant</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="search"
          label="Search by employee name, number or job title"
          single-line
          hide-details
          v-model="searchText"
          @input="searchList"
        ></v-text-field>
      </v-toolbar>
      <v-row flex>
        <v-col cols="12">
          <v-data-table :headers="headers" :items="documentListShowing">
            <template v-slot:item.action="{ item }">
              <v-btn
                title="View report for this appraisal document"
                icon
                v-if="item.reviewStageStatus !== 'Open'"
                @click="downloadEmployeeReport(item.code)"
                color="info"
                class="mr-3 my-3"
                fab
                x-large
                dark
              >
                <v-icon small>mdi-poll</v-icon>
                <!-- <span>report</span> -->
              </v-btn>
              <v-btn
                title="Review appraisal document"
                icon
                v-if="checkIfDocumentShouldBeReviewed(item)"
                :to="{
                  name: 'Appraisal Card',
                  params: { code: item.code },
                }"
                color="primary"
                fab
                x-large
                dark
              >
                <v-icon small>mdi-view-dashboard-outline</v-icon>
                <!-- <span>review appraisal</span> -->
              </v-btn>
              <v-btn
                icon
                title="Recommend to CEO"
                v-if="checkIfDocumentShouldBeRecommended(item)"
                :to="{
                  name: 'ResultCard',
                  params: { code: item.code, period: item.period },
                }"
                color="error"
                fab
                x-large
                dark
              >
                <v-icon small>mdi-scale-unbalanced</v-icon>
                <!-- <span>recommend to CEO</span> -->
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
export default {
  name: "CommitteAppraisalVue",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Result/getReviewDocumentStages");
      v.$store.dispatch("Result/getAssignedCommitteeAppraisaldocuments", {
        employeeNo: v.userData?.profile?.number,
      });
    });
  },
  data() {
    return {
      searchText: null,
      selectedCommittee: null,
      selectCommitteeActive: false,
      appraisalCommitteDocumentsSearchList: [],
      appraisalCommitteeFilterResultArray: [],
    };
  },
  computed: {
    documentListShowing: {
      get() {
        if (this.searchText && this.searchText !== "") {
          return this.appraisalCommitteDocumentsSearchList;
        } else if (
          !Array.isArray(this.getCommitteeAssigned) &&
          this.appraisalCommitteeFilterResultArray.length
        ) {
          return this.appraisalCommitteeFilterResultArray;
        } else {
          return this.appraisalCommitteDocuments;
        }
      },
    },
    appraisalCommitteDocuments: {
      get() {
        return this.$store.getters["Result/appraisalResultGetter"](
          "assignedCommitteeAppraisalDocuments"
        );
      },
      set(value) {
        this.$store.commit("Result/MUTATE", {
          state: "assignedCommitteeAppraisalDocuments",
          data: value,
        });
      },
    },
    getCommitteeAssigned: {
      get() {
        let committees = [];
        this.appraisalCommitteDocuments.forEach((review) => {
          if (committees.indexOf(review.assignedCommittee) === -1) {
            committees.push(review.assignedCommittee);
          }
        });
        if (this.selectCommitteeActive) {
          return committees;
        }
        if (this.selectedCommittee) {
          let index = committees.findIndex(
            (committee) => committee == this.selectedCommittee
          );
          return index >= 0 ? committees[index] : committees[0];
        }
        return committees[0];
      },
    },
    userData() {
      return AuthService.user;
    },
    headers: {
      get() {
        return [
          { text: "Employee Name", value: "employeeName" },
          { text: "Disagreement reason", value: "disagreementReason" },
          { text: "Job Title", value: "employeeJobTitle" },
          { text: "Overal score", value: "overallScore" },
          { text: "Owner response", value: "ownerResponse" },
          {
            text: "Perfomance recommendation",
            value: "perfomanceRecommendations",
          },
          { text: "Action", value: "action" },
        ];
      },
    },
    reviewDocumentStages() {
      return this.$store.getters["Result/appraisalResultGetter"](
        "reviewDocumentStages"
      );
    },
  },
  watch: {
    getCommitteeAssigned(value) {
      if (!Array.isArray(value)) {
        this.appraisalCommitteeFilterResultArray =
          this.appraisalCommitteDocuments.filter((document) => {
            return document.assignedCommittee === this.getCommitteeAssigned;
          });
      }
    },
  },
  methods: {
    checkIfDocumentShouldBeReviewed(item) {
      const stage = this.reviewDocumentStages
        .filter((f) => f.code === item.currentReviewStage)
        .shift();

      return (
        stage &&
        stage.isCommitteeStage &&
        stage.assignFinalScore &&
        !stage.assignCommittee &&
        !stage.recommend &&
        !stage.actionRecommendations
      );
    },
    checkIfDocumentShouldBeRecommended(item) {
      const stage = this.reviewDocumentStages
        .filter((f) => f.code === item.currentReviewStage)
        .shift();

      return (
        stage &&
        !stage.isCommitteeStage &&
        !stage.assignFinalScore &&
        !stage.assignCommittee &&
        stage.recommend &&
        !stage.actionRecommendations
      );
    },
    setCommitteeSelected() {
      this.selectCommitteeActive = false;
      this.appraisalCommitteeFilterResultArray =
        this.appraisalCommitteDocuments.filter((document) => {
          return document.assignedCommittee === this.getCommitteeAssigned;
        });
    },
    setSelectCommittee() {
      this.selectCommitteeActive = !this.selectCommitteeActive;
      if (this.selectCommitteeActive) {
        if (!this.getCommitteeAssigned?.length) {
          Event.$emit(
            "ApiError",
            "You are not assigned to any committee currently!"
          );
          this.selectCommitteeActive = false;
        }
        if (this.getCommitteeAssigned?.length < 2) {
          Event.$emit(
            "ApiError",
            "You have a single committee currently assigned"
          );
          this.selectCommitteeActive = false;
        }
      }
    },
    viewDocument(item) {
      this.$router.push({
        name: "CommitteeReview",
        params: {
          code: item.code,
        },
      });
    },
    async downloadEmployeeReport(item) {
      await this.$store.dispatch("appraisal/getAppraisalReport", {
        reviewNo: item,
      });
    },
    searchList() {
      this.appraisalCommitteDocumentsSearchList =
        this.appraisalCommitteDocuments.filter((review) => {
          return (
            review.employeeName
              .toLowerCase()
              .includes(this.searchText.toLowerCase()) ||
            review.employeeNo
              .toLowerCase()
              .includes(this.searchText.toLowerCase()) ||
            review.employeeJobTitle
              .toLowerCase()
              .includes(this.searchText.toLowerCase())
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
