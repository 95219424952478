import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"header-title ml-3"},[_vm._v(" Approval Entries ")]),_c(VCardText,[_c(VTimeline,{staticClass:"mx-10",attrs:{"dense":""}},_vm._l((_vm.approvalEntries),function(item,i){return _c(VTimelineItem,{directives:[{name:"show",rawName:"v-show",value:(item.status !== 'Canceled'),expression:"item.status !== 'Canceled'"}],key:i,attrs:{"color":_vm.getColor(item.status).icon_color,"small":"","fill-dot":"","icon":_vm.getColor(item.status).icon}},[(item.status !== 'Canceled')?_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex justify-start"},[_c(VIcon,[_vm._v("schedule")]),_c('strong',{staticClass:"view-page"},[_vm._v(_vm._s(_vm.getEntryDateFormat(item.dateTimeSentForApproval)))])],1),_c(VDivider),_c('div',{staticClass:"d-flex justify-start"},[_c('div',{},[_c('h1',{staticClass:"view-page"},[_vm._v("Status:")])]),_c(VChip,{staticClass:"mx-1 my-1",attrs:{"label":"","color":_vm.getColor(item.status).icon_color,"x-small":""}},[_c('span',{staticClass:"view-page"},[_vm._v(_vm._s(item.status))])])],1)],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VListItem,[_c(VListItemAvatar,{staticStyle:{"color":"rgb(0, 68, 139)"},attrs:{"color":_vm.getColor(item.status).icon_color,"size":"50"}},[_vm._v(" "+_vm._s(_vm.getApproverValue(item.approver, "number"))+" ")]),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"view-page"},[_vm._v(" "+_vm._s(_vm.getApproverValue(item.approver, "name"))+" ")]),_c(VListItemSubtitle,{},[_vm._v(" "+_vm._s(_vm.getApproverValue(item.approver, "email"))+" ")])],1)],1)],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"view-page font-weight-bold"},[_vm._v(" Comment ")]),_c(VCard,{staticClass:"mt-1 text--secondary",attrs:{"flat":""}},[_vm._v(" "+_vm._s(_vm.getComment(item.approvalComments))+" ")])],1)],1)],1)],1):_vm._e()],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }