import Auth from "@kinetics254/cassandra-base/modules/auth/middleware/Auth";
const AppDashboard = () =>
  import("@kinetics254/cassandra-base/app/layout/AppDashboard");
import ResultList from "./components/ResultList.vue";
import ResultCard from "./components/ResultCard.vue";
import CommitteeAppraisal from "./components/CommitteeAppraisalDocuments.vue";
import CommitteeDocumentReview from "./components/CommitteeDocumentReview.vue";
import CommitteeAddScore from "./components/CommitteeAddScore.vue";
import CommitteeReport from "./components/CommitteeReport.vue";

export default [
  {
    path: "/appraisal/results",
    component: AppDashboard,
    redirect: { name: "ResultList" },
    children: [
      {
        path: "ceo",
        name: "ResultList",
        components: {
          view: ResultList,
        },
        meta: { middleware: [Auth] },
      },
      {
        path: "committee",
        name: "committee",
        components: {
          view: CommitteeAppraisal,
        },
        meta: { middleware: [Auth] },
      },
      {
        path: "commmittee/report/:code",
        name: "committeeReport",
        components: {
          view: CommitteeReport,
        },
        meta: { middleware: [Auth] },
      },
      {
        path: "card/:period/:code",
        name: "ResultCard",
        components: {
          view: ResultCard,
        },
        meta: { middleware: [Auth] },
      },
      {
        path: "review/:code",
        name: "CommitteeReview",
        components: {
          view: CommitteeDocumentReview,
        },
        meta: { middleware: [Auth] },
      },
      {
        path: "review/:code/:kpi",
        name: "CommitteeAddScore",
        components: {
          view: CommitteeAddScore,
        },
        meta: { middleware: [Auth] },
      },
    ],
  },
];
