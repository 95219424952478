import Auth from "@kinetics254/cassandra-base/modules/auth/middleware/Auth";
const AppDashboard = () =>
  import("@kinetics254/cassandra-base/app/layout/AppDashboard");

import Disciplinary from "./views/Disciplinary";
import DisciplinaryCard from "./components/DisciplinaryCard";

import RaisedList from "./components/RaisedList";
import AccusedList from "./components/AccusedList";
import WarningList from "./components/WarningList.vue";

export default [
  {
    path: "/disciplinary",
    component: AppDashboard,
    children: [
      {
        path: "",
        components: {
          view: Disciplinary,
        },
        children: [
          {
            path: "raised",
            name: "Raised List",
            components: {
              view: RaisedList,
            },
            meta: {
              middleware: [Auth],
            },
          },
          {
            path: "accused",
            name: "Accused List",
            components: {
              view: AccusedList,
            },
            meta: {
              middleware: [Auth],
            },
          },
          {
            path: "warning",
            name: "Warning List",
            components: {
              view: WarningList,
            },
            meta: {
              middleware: [Auth],
            },
          },
          {
            path: "card/:no?",
            name: "DisciplinaryCard",
            components: {
              view: Disciplinary,
              action: DisciplinaryCard,
            },
            meta: {
              middleware: [Auth],
            },
          },
        ],
      },
    ],
  },
];
