<template>
  <v-container fluid>
    <v-dialog v-model="appraisalCommitteeDialog" max-width="400px" persistent>
      <v-card class="py-3 px-3">
        <v-toolbar elevation="0">
          <v-toolbar-title>
            <span class="headline">Assigne a Committee</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-autocomplete
              v-model="committee"
              :items="appraisalCommittees"
              label="Appraisal Committee"
              item-text="description"
              item-value="code"
            ></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="appraisalCommitteeDialog = false" class="mr-3"
            >Cancel</v-btn
          >
          <v-btn color="primary" @click="assignCommittee">Assign</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
export default {
  name: "AppraisalCommittee",
  data() {
    return {
      committee: "",
    };
  },
  computed: {
    userData: {
      get() {
        return AuthService.user;
      },
    },
    appraisalCommitteeDialog: {
      get() {
        return this.$store.getters["Result/appraisalResultGetter"](
          "appraisalCommitteeDialog"
        );
      },
      set(val) {
        this.$store.commit("Result/MUTATE", {
          state: "appraisalCommitteeDialog",
          data: val,
        });
      },
    },
    appraisalCommittees: {
      get() {
        return this.$store.getters["Result/appraisalResultGetter"](
          "appraisalCommittees"
        );
      },
    },
  },
  methods: {
    assignCommittee() {
      this.$store.dispatch("Result/assignAppraisalDocumentACommittee", {
        code: this.$route.params.code,
        assignedCommittee: this.committee,
        currEmployee: this.userData?.profile?.number,
      });
      this.committee = "";
      this.$store.commit("Result/MUTATE", {
        state: "appraisalCommitteeDialog",
        data: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
