<script>
import StatsView from "@kinetics254/cassandra-dashboards/Stats/StatsView.vue";

export default {
  name: "LeaveDaysBalances",
  components: { StatsView },
  computed: {
    balances: function () {
      return this.$store.getters["leavePlanner/leavePlannerGetter"](
        "leaveDaysBalances"
      );
    },
  },
};
</script>

<template>
  <div>
    <div class="text-lg-h5 mb-2 font-weight-medium">Days Left</div>

    <StatsView :data="balances">
      <template #stats>
        <v-layout row wrap>
          <v-flex
            v-for="(item, i) in balances"
            :key="i"
            xs6
            sm4
            xl2
          >
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card
                :elevation="hover ? 16 : 0"
                :class="{ 'on-hover': hover }"
                flat
                style="background-color: #ffffff"
              >
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-lg-h3">{{
                        item.balance
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        item.leaveType
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-avatar color="light-blue lighten-4" size="70">
                        <v-icon large color="light-blue darken-4"
                          >ti-calendar</v-icon
                        >
                      </v-avatar>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-hover>
          </v-flex>
        </v-layout>
      </template>
    </StatsView>
  </div>
</template>

<style scoped></style>
