export default {
  results: (response) => `hrmis/appraisal/v4/result/index/${response}`,
  getAppraisalDocument: (params) =>
    `hrmis/appraisal/v4/result/getDocument?docNo=${params.docNo}&period=${params.period}`,
  rewardEmployee: `hrmis/appraisal/v4/result/reward/employee`,
  appraisalRewards: `hrmis/appraisal/v4/result/appraisal/rewards`,
  codeUnitActions: (params) => {
    return `hrmis/appraisal/v4/result/cu/${params.provider}/${params.entity}`;
  },
  getAppraisalCommittees: `hrmis/appraisal/v4/result/appraisal/committees`,
  assigneAppraisalCommittee: `hrmis/appraisal/v4/result/appraisal/assigne/committee`,
  getCommitteeAssignedDocuments: (params) =>
    `hrmis/appraisal/v4/result/appraisal/committe/documents?employeeNo=${params.employeeNo}`,
  getAppraisalDocumentCommitteMembers: (params) =>
    `hrmis/appraisal/v4/result/appraisal/document/committee/members?code=${params.code}`,
  getAppraisalDocumentRewards: (params) =>
    `hrmis/appraisal/v4/result/appraisal/document/rewards?reviewId=${params.reviewId}`,
  addCommitteeMember: `hrmis/appraisal/v4/result/appraisal/document/addCommitteeMember`,
  deleteCommitteeMember: `hrmis/appraisal/v4/result/appraisal/document/delete/committeeMember`,
  getDocumentKpis: (params) =>
    `hrmis/appraisal/v4/result/getDocumentKpis?reviewID=${params.reviewID}`,
  getDocumentObjectives: (params) =>
    `hrmis/appraisal/v4/result/getDocumentObjectives?reviewID=${params.reviewID}`,
  documentKpiAddScore: `hrmis/appraisal/v4/result/documentKpiAddScore`,
  employeeApplications: "hrmis/appraisal/v4/result/employeeApplications",
  perfomanceFeedback: "hrmis/appraisal/v4/result/appraisal/perfomanceFeedback",
  getReviewDocumentStages:
    "hrmis/appraisal/v4/result/appraisal/review/document/stages",
  isCommitteeMember: "hrmis/appraisal/v4/result/appraisal/review/is/committee",
};
