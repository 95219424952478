import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fade-transition"},[_c('AppraisalHeader',{staticClass:"mt-n1"}),_c(VCard,{staticClass:"mt-3",attrs:{"flat":""}},[_c(VCardText,[_c('span',{staticClass:"headline text-h6"},[_vm._v("My Final Report ")]),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.reports},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"supervisor-review-table"},[_vm._v(_vm._s(item["code"]))])]}},{key:"item.stage",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"supervisor-review-table"},[_vm._v(_vm._s(item["stage"]))]),_c('span',{staticClass:"mx-2"},[_c(VChip,{directives:[{name:"show",rawName:"v-show",value:(item.status === 'Active'),expression:"item.status === 'Active'"}],attrs:{"small":"","color":"green","dark":""}},[_c('span',{staticClass:"supervisor-review-table"},[_vm._v(_vm._s(item.stage))])])],1)]}},{key:"item.employeeScore",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"supervisor-review-table"},[_vm._v(_vm._s(_vm.MyScore(item))+"%")])]}},{key:"item.supervisorScore",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"supervisor-review-table"},[_vm._v(_vm._s(_vm.SupervisorScore(item))+"%")])]}},{key:"item.actualScore",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"supervisor-review-table"},[_vm._v(_vm._s(item.actualScore)+"%")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }